import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { FormTextField } from '../../../Common/FormTextField';
import { MultiFormTextField } from '../../../Common/MultiFormTextField';
import {
  EmployeePortalinvoiceDailog,
  SendEmailGrid,
} from './CreateInvoice.Styled';
import FormDropDown from '../../../Common/FormDropDown';

const SendEmailForm = (props) => {
  const {
    control,
    errors,
    handleSubmit,
    onSubmit,
    onCancel,
    fields,
    append,
    remove,
  } = props;

  const FromEmailOptions = [
    { value: 'hr@hyniva.com', label: 'hr@hyniva.com' },
    { value: 'ravi@hyniva.com', label: 'ravi@hyniva.com' },
    { value: 'vamsi@hyniva.com', label: 'vamsi@hyniva.com' },
  ];

  return (
    <>
      <SendEmailGrid container>
        <FormDropDown
          label="From"
          name="from"
          type="select"
          control={control}
          options={FromEmailOptions}
        />
      </SendEmailGrid>
      <Grid container>
        <MultiFormTextField
          label="To"
          fields={fields}
          append={append}
          remove={remove}
          control={control}
          errors={errors}
        />
      </Grid>

      <SendEmailGrid container>
        <FormTextField
          label="Note"
          name="note"
          multiline
          rows={4}
          control={control}
          errors={!!errors?.note}
          helperText={errors?.note?.note}
        />
      </SendEmailGrid>
      <EmployeePortalinvoiceDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Send
        </Button>
      </EmployeePortalinvoiceDailog>
    </>
  );
};

export default SendEmailForm;
