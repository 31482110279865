import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import HynivaLogo from '../../Assets/Images/HynivaLogo.png';
import {
  HynivaLogoStyle,
  LoginPageBox,
  LoginPageHeading,
  LoginPageMainGrid,
} from '../Login/Login.styled';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordFormField from './ForgotPasswordFormField';
import { ForgotPasswordGrid } from './ForgotPassword.Styled';
import { useRemoveSideNav } from '../../Common/CommonUtils';

const schema = yup.object().shape({
  email: yup.string()
    .email('Must be a valid email')
    .matches(/^[^\s@]+@hyniva\.com$/, 'Must be a valid email with the domain hyniva.com')
    .max(255)
    .required('Email is required'),
});

const ForgotPassword = () => {
  useRemoveSideNav();
  const navigate = useNavigate();
  const email = '';
  const password = '';

  const defaultValue = {
    email: email,
    password: password,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors, isDirty } = formState;

  const handleClick = () => {
    navigate('/');
  };

  return (
    <>
      <Grid>
        <LoginPageMainGrid>
          <LoginPageBox>
            <ForgotPasswordGrid>
              <Grid>
                <HynivaLogoStyle src={HynivaLogo} />
                <LoginPageHeading variant="h4">
                  Forgot Password
                </LoginPageHeading>
              </Grid>
              <ForgotPasswordFormField
                control={control}
                register={register}
                errors={errors}
                handleClick={handleClick}
                handleSubmit={handleSubmit}
              />
            </ForgotPasswordGrid>
          </LoginPageBox>
        </LoginPageMainGrid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
