import React from "react";
import { Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField, Typography } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { DateFormHelperText } from "./FormInputDatePicker.styled";
import { TimePickerTypography } from "./Common.styled";

const FormTimePicker = ({
  name,
  control,
  label,
  format,
  disabled,
  helperText,
  minTime,
  register,
  maxTime,
  showClockView,
  readOnly,
  errors,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        register={register}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const dateValue = value ? new Date(`1970-01-01T${value}`) : null;

          return (
            <TimePicker
              label={
                <TimePickerTypography errors={!!error}>
                  {label}
                </TimePickerTypography>
              }
              value={dateValue}
              minTime={minTime}
              maxTime={maxTime}
              onChange={(newValue) => {
                onChange(
                  newValue ? newValue.toTimeString().split(" ")[0] : null
                );
              }}
              disabled={disabled}
              readOnly={readOnly}
              disableFocus={readOnly}
              inputFormat={format}
              views={["hours", "minutes", "seconds"]}
              viewRenderers={
                showClockView
                  ? {
                      hours: (props) => renderTimeViewClock(props),
                      minutes: (props) => renderTimeViewClock(props),
                      seconds: (props) => renderTimeViewClock(props),
                    }
                  : null
              }
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  error={!!error}
                  helperText={error ? helperText : ""}
                  {...params}
                />
              )}
            />
          );
        }}
      />
      {errors && <DateFormHelperText>{helperText}</DateFormHelperText>}
    </LocalizationProvider>
  );
};

export default FormTimePicker;
