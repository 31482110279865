import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const FormSnackBar = (props) => {
  const { snackBarLabel, setSnackBarLabel, setOpenSnackBar, openSnackBar,variant } =
    props;
  const handleClose = () => {
    setOpenSnackBar(false);
    setSnackBarLabel('');
  };
  return (
    <>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity="success">
          <Typography variant={variant ? variant : "h4"}>{snackBarLabel}</Typography>
        </Alert>
      </Snackbar>
    </>
  );
};

export default FormSnackBar;
