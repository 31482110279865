import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { FormTextField } from "../../Common/FormTextField";
import {
  ForgotPasswordButtonStyle,
  LoginFormStyling,
  LoginPageButtonGrid,
} from "./Login.styled";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { renderButtonSpinner } from "../../Common/CommonUtils";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Microsoft from "../../Assets/Images/Microsoft.png";

const LoginPageFormField = (props) => {
  const {
    control,
    errors,
    register,
    handleDashBoard,
    handleForgotPassword,
    loadingButton,
    watch,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = (event) => {
    const checked = event.target.checked;
    setRememberMe(checked);
  };

  return (
    <LoginFormStyling>
      <Grid spacing={3}>
        <FormTextField
          label="Email Address"
          name="email"
          type="email"
          control={control}
          errors={!!errors?.email}
          helperText={errors?.email?.message}
          register={register("email")}
        />
        <FormTextField
          fullWidth
          label="Password"
          name="password"
          type={showPassword ? "text" : "password"}
          control={control}
          errors={!!errors?.password}
          helperText={errors?.password?.message}
          register={register("password")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Grid>
      {renderButtonSpinner(
        loadingButton,
        "Login",
        handleDashBoard,
        "contained"
      )}
      <Button
        fullWidth
        size="large"
        variant="outlined"
        sx={{ mt: 2, display: "flex", alignItems: "center" }}
        // onClick={handleMicrosoftLogin}
      >
        <img
          src={Microsoft}
          alt="Microsoft Logo"
          style={{ width: 30, height: 26, marginRight: 8 }}
        />{" "}
        Microsoft
      </Button>
      <LoginPageButtonGrid>
      {/* <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={handleRememberMeChange}
              color="primary"
            />
          }
          label="Remember Me"
          
        /> */}
        <ForgotPasswordButtonStyle
        fullWidth
          size="medium"
          // sx={{ mt: 3 }}
          onClick={handleForgotPassword}
        >
          Forgot Password?
        </ForgotPasswordButtonStyle>
      </LoginPageButtonGrid>
     
    </LoginFormStyling>
  );
};

export default LoginPageFormField;
