export const getLeaveRequestDataGridUtils = (leavesData) => {
  if (!leavesData || leavesData.length === 0) {
    return [];
  }
  return leavesData.map((item, index) => ({
    ...item,
    id: index,
    leaveId: item.leaveId,
    leaveName: item.leaveName,
    employeeId: item.employeeId,
    appliedDate: item.appliedDate,
    fromDate: item.fromDate,
    toDate: item.toDate,
    totalDays: item.totalDays,
    reason: item.reason,
    commment: item.commment,
    actionTakenBy: item.actionTakenBy,
    halfDay: item.halfDay,
    status: item.status,
  }));
};
