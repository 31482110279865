import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Typography from '@mui/material/Typography';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

export const StyledInvoiceCard = styled(Card)`
  margin: 8px 24px 8px 0px;
`;

export const StyledGridContent = styled(Grid)`
  margin: 8px;
  display: flex;
  align-items: center;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const TextTypography = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const RoundedEditIcon = styled(EditRoundedIcon)`
  cursor: pointer;
  width: 1.3rem;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const IconVisibile = styled(VisibilityIcon)`
  cursor: pointer;
  width: 1.3rem;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
