import Grid from '@mui/material/Grid';
import FormDropDown from '../../../../../Common/FormDropDown';
import MultiSelectFormDropdown from '../../../../../Common/MultiSelectFormDropdown';
import { FormTextField } from '../../../../../Common/FormTextField';
import {
  dropDownOptions,
  usePermissionCustomHook,
} from '../../../../../Common/CommonUtils';
import { useSelector } from 'react-redux';

const AssignmentInformationForm = (props) => {
  const {
    control,
    register,
    handleChange,
    editable,
    isBillable,
  } = props;

  const metaData = useSelector((state) => state?.metaData);

  const { managers, departments, designations, branches, projects } =
    metaData || {};


  const assignedProjectOptionsLists = projects?.map((item) => item.name);

  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');
  const isReadOnly = isManager || isDeveloper;

  const departmentOptions = dropDownOptions(departments);
  const designationOptions = dropDownOptions(designations);
  const branchesOptions = dropDownOptions(branches);

  const managerOptionsList = managers?.map((item) => ({
    label: item.name,
    value: item.employeeId,
  }));

  return (
    <Grid container spacing={2} sx={{ paddingY: 2 }}>
      <Grid item xs={4}>
        <FormDropDown
          label="Designation"
          name="designation"
          control={control}
          options={designationOptions}
          register={register('designation', {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          label="Department"
          name="department"
          control={control}
          options={departmentOptions}
          register={register('department', {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          label="Branch Office"
          name="branchOffice"
          control={control}
          options={branchesOptions}
          register={register('branchOffice', {
            onChange: handleChange,
          })}
          readOnly={!editable || isReadOnly}
        />
      </Grid>
      {/* <Grid item xs={4}>
        <FormDropDown
          label="Core Technology"
          name="coreTechnology"
          control={control}
          options={coreTechnologyOptions}
          register={register('coreTechnology', {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid> */}
      {/* <Grid item xs={4}>
        <MultiSelectFormDropdown
          label="Framework"
          name="framework"
          control={control}
          options={frameWorkOptionsLists}
          register={register('framework', {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid> */}
      <Grid item xs={4}>
        <MultiSelectFormDropdown
          label="Assigned Projects"
          name="assignedProjects"
          control={control}
          options={assignedProjectOptionsLists}
          register={register('assignedProjects', {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={4}>
        <FormDropDown
          name="managerId"
          label="Reporting Manager"
          control={control}
          options={managerOptionsList}
          register={register('managerId', {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextField
          name="onSiteStatus"
          label="Onsite Status"
          type="text"
          control={control}
          register={register('onSiteStatus', {
            onChange: handleChange,
          })}
          readOnly
        />
      </Grid>
      <Grid item xs={4}>
        {isBillable && (
          <FormTextField
            name="billableResource"
            label="Billable"
            type="text"
            control={control}
            register={register('billableResource', {
              onChange: handleChange,
            })}
            readOnly
          />
        )}
      </Grid>
    </Grid>
  );
};

export default AssignmentInformationForm;
