import axios from "axios";
import { BASE_URL } from "../../../Common/Constant";

export const sendInstructionApi = async (email) => {
  try {
    const response = await axios.post(`${BASE_URL}otp/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const VerifyOtpApi = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/otp/validate`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ResetPasswordApi = async (authorization, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}password/reset`,
      payload,
      authorization
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
