import { styled } from "@mui/system";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";

export const ListStyled = styled(List)({
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
});

export const StyledDivider = styled(Divider)({
  height: "2px",
  bgcolor: "black",
  margin: "4px 0",
});

export const PdfList = styled(Typography)`
  // color: #0042ff;
  @media screen and (max-width: 950px) {
    font-size: 10px;
  } 
  @media screen and (max-width: 750px) {
    font-size: 10px;
  } 
  @media screen and (max-width: 600px) {
    font-size: 13px;
  } 
`;