import { api } from "../../../Common/AxoisConfig";

export const getMySkills = async (payload) => {
  const mySkills = await api("NODE_API_URL").post("skill/employee", payload);
  return mySkills?.data;
};

export const postEmployeeSkillsData = async (payload) => {
  const employeeSkillsData = await api("NODE_API_URL").post(
    "skill/allSkill",
    payload
  );
  return employeeSkillsData?.data;
};

export const postTeamSkillsData = async (payload) => {
  const teamSkillsData = await api("NODE_API_URL").post(
    "skill/allSkill",
    payload
  );
  return teamSkillsData?.data;
};

export const getFrameWorks = async (technologyName) => {
  const frameworkData = await api("NODE_API_URL").get(
    `metadata/type?type=${technologyName}&status=active`
  );
  return frameworkData?.data?.data;
};

export const getViewSkills = async (userId, skillId) => {
  const viewSkills = await api("NODE_API_URL").get(
    `skill?userId=${userId}&skillId=${skillId}`
  );
  return viewSkills?.data;
};

export const putApproveOrRejectSkill = async (userId, skillId, payload) => {
  await api("NODE_API_URL").put(
    `skill?userId=${userId}&skillId=${skillId}`,
    payload
  );
};

export const postAddMySkill = async (payload) => {
  await api("NODE_API_URL").post("skill", payload);
};

export const postRequestSkill = async (payload) => {
  await api("NODE_API_URL").post("skill", payload);
};

export const putUpdateMySkills = async (employeeId) => {
  await api("NODE_API_URL").put(
    `skill/finalSubmission?employeeId=${employeeId}`,
    {}
  );
};
