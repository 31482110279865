import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeePortalinvoiceDailog } from '../../../../../Common/EmployeePortalDialog.styled';
import { FormTextField } from '../../../../../Common/FormTextField';
import { GridForm } from './PerformanceDetails.styled';

const schema = yup.object().shape({
  parameters: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Parameters must contain only letters')
    .required('Parameters is required'),
});

const PerformanceAddRowForm = (props) => {
  const { onSubmit, onCancel, snackbarOpen, onCloseSnackbar } = props;

  const performanceListForm = useSelector(
    (state) => state?.performanceListEditForm
  );

  const { parameters } = performanceListForm;

  const defaultValues = {
    parameters: parameters,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;

  return (
    <>
      <GridForm container data-testid="performanceFormContainer">
        <FormTextField
          label="Parameters"
          name="parameters"
          type="text"
          control={control}
          errors={!!errors?.parameters}
          helperText={errors?.parameters?.message}
        />
      </GridForm>

      <EmployeePortalinvoiceDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        >
          Save
        </Button>
      </EmployeePortalinvoiceDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Performance Details Created Successfully
        </Alert>
      </Snackbar>
    </>
  );
};
export default PerformanceAddRowForm;
