import React, { useState } from 'react';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {
  ClosestyledIcon,
  FileContainer,
  StyledInput,
  StyledLabel,
  UploadButton,
  UploadTypography,
} from './EducationAndCertification.Styled';

const UploadDocument = (props) => {
  const { selectedFiles, setSelectedFiles } = props;

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveFile = (event, fileToRemove) => {
    event.preventDefault();
    event.stopPropagation();
    const filteredFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(filteredFiles);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <StyledLabel>
      <StyledInput
        type="file"
        style={{ display: 'none' }}
        accept=".pdf"
        onChange={handleFileChange}
      />
      <UploadButton
        variant="text"
        component="div"
        onDrop={handleDrop}
        onDragOver={(event) => event.preventDefault()}
      >
        <CloudUploadOutlinedIcon />
        <UploadTypography variant="body1">
          {selectedFiles.length === 0 ? 'Upload Document' : ''}
          {selectedFiles.map((file, index) => (
            <FileContainer key={index}>
              <span>{file.name}</span>
              <ClosestyledIcon
                onClick={(event) => handleRemoveFile(event, file)}
              />
            </FileContainer>
          ))}
        </UploadTypography>
      </UploadButton>
    </StyledLabel>
  );
};

export default UploadDocument;
