import React, { useEffect, useState } from "react";
import {
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../Common/CommonUtils";
import Grid from "@mui/material/Grid";
import LeaveChart from "./Components/LeaveChart";
import { DashboardContainer } from "./Components/Dashboard.styled";
import TimeCheckIn from "../Attendance/TimeCheckIn";
import HolidayList from "./Components/HolidayList";
import { Typography } from "@mui/material";
import { getAttendanceSummary } from "../Attendance/services/Services";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const { setOpenNotification, setNotificationMessage } = useNotification();
  const dispatch = useDispatch();
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const [checkInCheckOutTimeDetails, setCheckInCheckOutTimeDetails] =
    useState(null);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;

  useEffect(() => {
    fetchAttendance();
  }, []);
  const fetchAttendance = async () => {
    try {
      const attendanceSummary = await getAttendanceSummary(
        employeeId,
        monthYear
      );
      setCheckInCheckOutTimeDetails(attendanceSummary);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.message || "Failed to fetch attendance."
      );
    }
  };
  useSideNav();

  const isHR = usePermissionCustomHook("HR");
  return (
    <>
      {!isHR ? (
        <DashboardContainer container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4} order={{xs:1 , sm:1 , md:2, lg:1}}>
            <TimeCheckIn
              fetchAttendance={fetchAttendance}
              checkInCheckOutTimeDetails={checkInCheckOutTimeDetails}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3} order={{xs:3 , sm:2 , md:3 , lg:3}}>
            <HolidayList />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5} order={{xs:2 , sm:3 , md:1 , lg:2}}>
            <LeaveChart />
          </Grid>
          
        </DashboardContainer>
      ) : (
        <Typography>Dashboard module</Typography>
      )}
    </>
  );
};

export default Dashboard;
