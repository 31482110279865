export const getLeaveDataGridUtils = (leaveData) => {
    if (!leaveData || leaveData.length === 0) {
      return [];
    }
    return leaveData.map((item, index) => ({
      ...item,
      id: index,
      applied_date_time: item.applied_date_time,
      leave_type_id: item.leave_type_id,
      request_type: item.request_type,
      from_date: item.from_date,
      to_date: item.to_date,
      total_days_leave_applied: item.total_days_leave_applied,
      reason: item.reason,
      commment: item.commment,
      request_status: item.request_status,
      action_by: item.action_by,
    }));
  };