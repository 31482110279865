import React from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextField } from '../../../Common/FormTextField';
import {
  EmployeePortalDialogTextBoxGrid,
  EmployeePortalOtpDailog,
} from '../../../Common/EmployeePortalDialog.styled';

const schema = yup.object().shape({
  otp: yup.string().required('You must enter an OTP'),
});

const VerifyOtpForm = (props) => {
  const {
    onSubmit,
    onCancel,
    onResend,
    resendButtonDisabled,
    minutes,
    seconds,
  } = props;
  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;
  const isOtpFieldDirty = dirtyFields.otp;

  return (
    <>
      <EmployeePortalDialogTextBoxGrid>
        <FormTextField
          label="OTP"
          name="otp"
          type="otp"
          control={control}
          errors={!!errors?.otp}
          helperText={errors?.otp?.message}
          register={register('otp')}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
          }}
        />
      </EmployeePortalDialogTextBoxGrid>
      <EmployeePortalOtpDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onResend}
          disabled={resendButtonDisabled}
        >
          {resendButtonDisabled
            ? `Resend OTP: ${String(minutes).padStart(2, '0')} : ${String(
                seconds
              ).padStart(2, '0')}`
            : 'Resend OTP'}
        </Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </EmployeePortalOtpDailog>
    </>
  );
};

export default VerifyOtpForm;
