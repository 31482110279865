import { useEffect, useRef } from 'react';
 
const useDebounceEffect = (callback, delay, dependencies) => {
  const debounceTimeoutRef = useRef(null);
 
  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
 
    return () => clearTimeout(debounceTimeoutRef.current);
  }, [...dependencies, delay]);
};
 
export default useDebounceEffect;