import React from "react";
import {
  HeadTypo,
  ButtonGrid,
  LogHeader,
  HeadButton,
  DownloadAttendence,
  MonthFetchAttendence,
} from "./LogAttendance.styled";
import PropTypes from "prop-types";
import { usePermissionCustomHook } from "../../../Common/CommonUtils";
import DownloadDropdown from "../../../Common/DownloadDropdown";
import { EmployeeDownloadIconGrid } from "../../Employees/Employee.Styled";
import { downloadOptions } from "../../../Common/Constant";
const getLastThreeMonths = () => {
  const today = new Date();
  return Array.from({ length: 3 }, (_, i) => {
    const monthDate = new Date(today.getFullYear(), today.getMonth() - i);
    const monthName = monthDate.toLocaleString("default", { month: "long" });
    return { name: monthName, date: monthDate };
  });
};
const LogAttendence = ({ currentMonth, onSelectMonth }) => {
  const today = new Date();
  const isHR = usePermissionCustomHook("HR");
  const isManager = usePermissionCustomHook("Manager");
  const monthYearString = currentMonth.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
  const lastThreeMonths = getLastThreeMonths();

  const handleDownloadChange = (option) => {
    console.log("handleDownloadChange", option.value);
  };
  return (
    <LogHeader>
      <HeadTypo>Logs Attendance - {monthYearString}</HeadTypo>
      <MonthFetchAttendence>
        <EmployeeDownloadIconGrid>
          {(isHR || isManager) && (
            <DownloadAttendence>
              <DownloadDropdown
                options={downloadOptions}
                handleChange={handleDownloadChange}
              />
            </DownloadAttendence>
          )}
        </EmployeeDownloadIconGrid>
        <ButtonGrid>
          {lastThreeMonths.map(({ name, date }) => {
            const isDisabled = today < date;
            const isSelected = currentMonth.getMonth() === date.getMonth();
            return (
              <HeadButton
                key={name}
                selected={isSelected}
                onClick={() => !isDisabled && onSelectMonth(name)}
                disabled={isDisabled}
                firstButton={name === lastThreeMonths[0].name}
              >
                {name}
              </HeadButton>
            );
          })}
        </ButtonGrid>
      </MonthFetchAttendence>
    </LogHeader>
  );
};

LogAttendence.propTypes = {
  currentMonth: PropTypes.instanceOf(Date).isRequired,
  onSelectMonth: PropTypes.func.isRequired,
};
export default LogAttendence;
