import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TimeCheckIn from "./TimeCheckIn";
import { getAttendanceSummary } from "./services/Services";
import {
  useNotification,
  useSideNav,
  usePermissionCustomHook,
} from "../../Common/CommonUtils";
import { useDispatch, useSelector } from "react-redux";
import LogAttendanceDataGrid from "./Components/LogAttendanceDatagrid";
import HrViewAttendanceDatagrid from "./Components/HrViewAttendance/HrViewAttendanceDatagrid";
import is from "date-fns/esm/locale/is/index.js";
const Attendance = () => {
  useSideNav();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const dispatch = useDispatch();
  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const designation = useSelector((state) => state.user?.data?.designation);
  const role = useSelector((state) => state.user?.data?.role);
  const [checkInCheckOutTimeDetails, setCheckInCheckOutTimeDetails] =
    useState(null);
  const date = new Date();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const monthYear = `${month}-${year}`;
  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");

  useEffect(() => {
    fetchAttendance();
  }, []);
  const fetchAttendance = async () => {
    try {
      const attendanceSummary = await getAttendanceSummary(
        employeeId,
        monthYear
      );
      setCheckInCheckOutTimeDetails(attendanceSummary);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(
        error.response?.data?.message || "Failed to fetch attendance."
      );
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {!(designation === "HR Admin" && role === "HR") && (
          <Grid item xs={12} sm = {7} md={5}>
            <TimeCheckIn
              fetchAttendance={fetchAttendance}
              checkInCheckOutTimeDetails={checkInCheckOutTimeDetails}
              linkAttendance
            />
          </Grid>
        )}
      </Grid>
      {designation === "HR Admin" && role === "HR" && (
        <HrViewAttendanceDatagrid />
      )}
      {(isDeveloper || isManager) && <LogAttendanceDataGrid />}

      <NotificationPopup />
    </>
  );
};

export default Attendance;
