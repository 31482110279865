import React, { useEffect } from 'react';
import EmployeeDetailsTabs from './EmployeeDetailsTabs';
import {
  ViewDetailsPageAvatar,
  ViewDetailsPageGrid,
} from './ViewDetailsPage.styled';
import EmployeeName from './EmployeeName';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader, useAuthentication, useNotification, usePermissionCustomHook, useSideNav } from '../../../Common/CommonUtils';
import { setIsLoading } from '../../../Common/Loader/store/loaderSlice';
import { getAsset, getDesignationByRole, getEmployee } from '../Services/service';
import {
  resetPersonalInformationForm,
  setPersonalInformationForm,
} from '../store/personalInformation/personalInformationSlice';
import { setIsViewDetailsPage } from '../store/EmployeeSlice';
import { setDesignations } from '../../../Common/store/metaDataSlice/metaDataSlice';
import { setAssetInfoForm } from '../store/assetsInformationSlice/assetInfoSlice';

const ViewDetailsPage = () => {
  useSideNav();
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const employeeId = useSelector((state) => state?.employeeInfo?.employeeId);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const designationRole = useSelector((state) => state.user?.data?.role);
  const isHR = usePermissionCustomHook("HR");

  const employeeRole = useSelector(
    (state) => state?.employeeInfo?.employeeRole
  );
  const isLoading = useSelector((state) => state?.loader?.isLoading);

  useEffect(() => {
    fetchEmployee();
  }, []);

  useEffect(() => {
    fetchDesignationByRole();
  }, []);

  const fetchEmployee = async () => {
    try {
      dispatch(setIsLoading(true));
      const response = await getEmployee(loginUserId, employeeId);
      const asset = await getAsset(loginUserId,employeeId);
      dispatch(setPersonalInformationForm(response?.data));
      dispatch(setAssetInfoForm(asset?.data));
      dispatch(setIsViewDetailsPage(true));
      dispatch(setIsLoading(false));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      dispatch(setIsViewDetailsPage(true));
      dispatch(setIsLoading(false));
      dispatch(resetPersonalInformationForm(false));
    }
  };

  const fetchDesignationByRole = async () => {
    const designationByRole = isHR ? employeeRole : designationRole
    try {
      const designations = await getDesignationByRole(designationByRole);
      dispatch(setDesignations(designations));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  if (isLoading) {
    return showLoader();
  }

  return (
    <>
      <ViewDetailsPageGrid>
        <ViewDetailsPageAvatar />
        <EmployeeName />
      </ViewDetailsPageGrid>
      <EmployeeDetailsTabs />
      <NotificationPopup />
    </>
  );
};

export default ViewDetailsPage;
