import DataGridComponent from "../../Common/DataGridComponent";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CompaniesSearch } from "./EmployeeSearch";
import EmployeeBranchDropdown from "./EmployeeBranchDropdown";
import { useEffect, useState } from "react";
import ClearFilterButton from "./ClearFilterButton";
import EmployeeDesignationDropdown from "./EmployeeDesignationDropdown";
import {
  useAuthentication,
  usePermissionCustomHook,
  useSideNav,
} from "../../Common/CommonUtils";
import DownloadIcon from "@mui/icons-material/Download";
import {
  DataMainGrid,
  EmployeeDownloadIconGrid,
  EmployeeSearchGrid,
  NameValue,
  StyledCard,
  StyledCardContent,
  StyledContainerGrid,
  StyledGridContainer,
} from "./Employee.Styled";
import { useNavigate } from "react-router-dom";
import CreateEmployee from "./CreateEmployee";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees, getEmployee } from "./Services/service";
import { intialPageSize } from "../../Common/Constant";
import { getEmployeeListData } from "./EmployeeDataGridUtils";
import {
  setEmployeeId,
  setEmployeeRole,
} from "./store/employeeIdSlice/employeeIdSlice";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import useDebounceEffect from "../../Common/CustomHooks/useDebounceEffect";
import { FormInputSearch } from "../../Common/FormInputSearch";

const Employee = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useSideNav();

  const [anchorEl, setAnchorEl] = useState(null);
  const [branchSelection, setBranchSelection] = useState([]);
  const [designationSelection, setDesignationSelection] = useState([]);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [employeeDetails, setEmployeeDeatils] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const { page } = employeeDetails;

  const [openCreateEmployeeDialog, setOpenCreateEmployeeDialog] =
    useState(false);

  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const isParticularEmployee = isDeveloper || isManager;

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const accessToken = useSelector((state) => state.user?.accessToken);

  const defaultValue = {
    designation: [],
    branch: [],
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;

  useEffect(() => {
    setIsFilterSelected(
      branchSelection.length > 0 || designationSelection.length > 0
    );
  }, [branchSelection, designationSelection]);

  const handleViewDetails = async (params) => {
    const { row } = params;
    navigate("./viewDetailsPage");
    dispatch(setEmployeeId(row.employeeId));
    dispatch(setEmployeeRole(row.role));
  };

  const columnsData = [
    {
      field: "employeeId",
      headerName: "Employee ID",
      width: 160,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => {
        const fullName = `${params.row.firstName} ${params.row.lastName}`;
        return <NameValue>{fullName}</NameValue>;
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      width: 200,
    },
    {
      field: "department",
      headerName: "Department",
      width: 200,
    },
    {
      field: "branchOffice",
      headerName: "Branch",
      width: 200,
    },

    {
      width: 200,

      renderCell: (params) => {
        return (
          <>
            <Button
              variant="outlined"
              onClick={() => handleViewDetails(params)}
            >
              View Details
            </Button>
          </>
        );
      },
    },
  ];

  const clearSelection = () => {
    reset(defaultValue);
    setBranchSelection([]);
    setDesignationSelection([]);
  };

  const handleCloseCreateEmployeeDialog = () => {
    setOpenCreateEmployeeDialog(false);
  };

  useDebounceEffect(
    () => {
      fetchEmployeeData();
    },
    500,
    [searchQuery]
  );

  const fetchEmployeeData = async () => {
    try {
      const payload = {
        pageNo: page,
        pageSize: intialPageSize,
        userId: loginUserId,
        searchQuery: searchQuery,
        // designation: designation,
        // branchOffice: branchOffice,
      };
      setEmployeeDeatils({ ...employeeDetails, isLoading: true });

      const getEmploeeDetails = isParticularEmployee
        ? getEmployee(loginUserId, loginUserId)
        : getEmployees(payload);

      const employeeDetailsList = await getEmploeeDetails;

      const employeeDetailsData = isParticularEmployee
        ? [employeeDetailsList?.data]
        : employeeDetailsList?.data;

      const formattedData = getEmployeeListData(employeeDetailsData);
      setEmployeeDeatils((prev) => ({
        ...prev,
        rows: formattedData,
        isLoading: false,
      }));
    } catch (error) {
      setEmployeeDeatils({ ...employeeDetails, isLoading: false });
    }
  };

  const handleDownload = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadFormatSelect = (selectedItem) => {
    setAnchorEl(null);
  };

  const handleDesignation = (e) => {
    setDesignationSelection(e.target.value);
  };

  const handleBranch = (e) => {
    setBranchSelection(e.target.value);
  };

  return (
    <>
      <StyledGridContainer
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h3" style={{ marginLeft: "16px" }}>
          Employees
        </Typography>
        {isHR && (
          <StyledContainerGrid item>
            <Button
              endIcon={
                <SvgIcon fontSize="small">
                  <AddIcon />
                </SvgIcon>
              }
              variant="contained"
              onClick={() => setOpenCreateEmployeeDialog(true)}
            >
              Add Employee
            </Button>
          </StyledContainerGrid>
        )}
        {openCreateEmployeeDialog && (
          <CreateEmployee
            onClose={handleCloseCreateEmployeeDialog}
            onOpen={openCreateEmployeeDialog}
            fetchEmployeeData={fetchEmployeeData}
          />
        )}
      </StyledGridContainer>
      <StyledCard
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
        }}
      >
        <StyledCardContent container alignItems="center">
          <Grid item xs={2}>
            <EmployeeDesignationDropdown
              control={control}
              register={register}
              handleDesignation={handleDesignation}
            />
          </Grid>
          <Grid item xs={2}>
            <EmployeeBranchDropdown
              control={control}
              register={register}
              handleBranch={handleBranch}
            />
          </Grid>
          <Grid item xs={2.8}>
            {isFilterSelected && (
              <ClearFilterButton clearSelection={clearSelection} />
            )}
          </Grid>
          <EmployeeDownloadIconGrid item xs={1}>
            {isHR && (
              <IconButton onClick={handleDownload}>
                <DownloadIcon />
              </IconButton>
            )}
          </EmployeeDownloadIconGrid>
          <EmployeeSearchGrid item xs={2}>
            <FormInputSearch
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Search Employee"
            />
          </EmployeeSearchGrid>
        </StyledCardContent>
      </StyledCard>
      <DataMainGrid>
        <Card
          style={{
            // padding: '8px 16px',
            boxShadow:
              "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
          }}
        >
          <DataGridComponent
            // autoHeight
            columnsData={columnsData}
            rowData={employeeDetails?.rows}
            // setData={setData}
            // data={data}
            pageSizeValue={employeeDetails?.rows?.length}
            isLoading={employeeDetails.isLoading}
            // loading={employeesData}
            // onRowClick= {}
            // pageValue={UsersPageValue}
            // fetchData={fetchUserData}
            // loading={isLoading}
            rowHeight={40}
          />
        </Card>
      </DataMainGrid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => handleDownloadFormatSelect("excel")}>
          Excel
        </MenuItem>
        <MenuItem onClick={() => handleDownloadFormatSelect("pdf")}>
          PDF
        </MenuItem>
      </Menu>
    </>
  );
};
export default Employee;
