import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import {
  Accordionstyling,
  AddButtonGrid,
} from './EducationAndCertification.Styled';
import EducationDataGrid from './EducationDataGrid';
import AddEducationDetails from './AddEducationDetails';
import { useDispatch } from 'react-redux';
import {
  resetEducationForm,
  setEducationForm,
  setIsNewEducationForm,
} from './store/educationFormSlice/educationFormSlice';
import DeleteConfirmationDialog from '../../../../../Common/DeleteConfirmationDialog';

const EducationAccordion = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const dispatch = useDispatch();

  const handleAddtButton = () => {
    setSnackbarOpen(false);
    setIsDialogOpen(true);
    dispatch(resetEducationForm());
    dispatch(setIsNewEducationForm(true));
  };

  const handleEducationEditButton = (params) => {
    setSnackbarOpen(false);
    setIsDialogOpen(true);
    const { row } = params;
    dispatch(setEducationForm(row));
  };

  const handleEducationDeleteButton = (params) => {
    setSelectedRow(params.row);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  return (
    <Grid data-testid='education-grid'>
      <Accordionstyling data-testid='education-accordion'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          data-testid='education-accordion-summary'
        >
          <Typography variant="h6">Education</Typography>
        </AccordionSummary>
        <AddButtonGrid container data-testid='education-accordion-add-button-grid'>
          <Button variant="contained" onClick={() => handleAddtButton()}>
            Add
          </Button>
        </AddButtonGrid>

        <AccordionDetails data-testid='education-accordion-details'>
          <EducationDataGrid
            handleDeleteButton={handleEducationDeleteButton}
            handleEditButton={handleEducationEditButton}
          />
        </AccordionDetails>
      </Accordionstyling>
      <AddEducationDetails
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        handleClose={handleCloseDeleteConfirmation}
        handleDelete={handleConfirmDelete}
        message="Are you sure you want to delete this education record?"
      />
    </Grid>
  );
};

export default EducationAccordion;
