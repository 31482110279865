import React from "react";
import { useSideNav } from "../../Common/CommonUtils";

const UserMangement = () => {
  useSideNav();
  return (
    <div>
      <p>User Management</p>
    </div>
  );
};

export default UserMangement;
