import { api } from "../../../Common/AxoisConfig";

export const postCheckIn = async (userId) => {
  await api("FAST_API_URL").patch(`attendance/check-in/${userId}`);
};

export const postCheckOut = async (userId) => {
  await api("FAST_API_URL").patch(`attendance/check-out/${userId}`);
};

export const getAttendanceSummary = async (employeeId, monthYear) => {
  const attendanceSummary = await api("FAST_API_URL").get(
    `attendance/summary?month_year=${monthYear}&user_id=${employeeId}`
  );
  return attendanceSummary?.data?.data;
};

export const postAttendance = async (userId, payload) => {
  const editAttendance = await api("FAST_API_URL").post(
    `attendance/${userId}`,
    payload
  );
  return editAttendance?.data;
};

export const getAttendance = async () => {
  const Attendance = await api("NODE_API_URL").get(
    `metadata/type?type=Attendance&status=active`
  );
  return Attendance?.data;
};
export const getAttendanceRequests = async (userId) => {
  const Attendance = await api("FAST_API_URL").get(
    `attendance/requests?user_id=${userId}`
  );
  return Attendance?.data;
};
export const postApproveReject = async (userId, payload) => {
  await api("FAST_API_URL").patch(`notifications/${userId}`, payload);
};
