import { createSlice } from '@reduxjs/toolkit';
import {
  _setCourse,
  _setDegree,
  _setEducationForm,
  _setIsNewEducationForm,
  _setUniversityInstutute,
  _setYearOfPassing,
} from './educationFormAction';
import initialState from './educationFormIntialState';

export const EducationSlice = createSlice({
  name: 'ep/EducationForm',
  initialState,
  reducers: {
    setEducationForm: _setEducationForm,
    setIsNewEducationForm: _setIsNewEducationForm,
    setDegree: _setDegree,
    setCourse: _setCourse,
    setUniversityInstutute: _setUniversityInstutute,
    setYearOfPassing: _setYearOfPassing,
    resetEducationForm: () => initialState,
  },
});

export const {
  setEducationForm,
  setIsNewEducationForm,
  setDegree,
  setCourse,
  setUniversityInstutute,
  setYearOfPassing,
  resetEducationForm,
} = EducationSlice.actions;

export default EducationSlice.reducer;
