import React from 'react';
import { Controller } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { FieldSelect } from './Dropdown.styled';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectFormDropdown = ({
  label,
  options,
  name,
  control,
  disabled,
  m,
  width,
  readOnly,
}) => {
  return (
    <FormControl sx={{ m: m, width: width }}>
      <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FieldSelect
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={value}
            onChange={onChange}
            disabled={disabled}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            inputProps={{ readOnly: readOnly }}
            disableFocus={readOnly}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox checked={value.indexOf(option) > -1} />
                <ListItemText primary={option} />
              </MenuItem>
            ))}
          </FieldSelect>
        )}
      />
    </FormControl>
  );
};

export default MultiSelectFormDropdown;
