import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Dialog,
  Typography,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { styled } from "@mui/material/styles";

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 0;
`;

export const StyledIconButton = styled(IconButton)`
  position: absolute;
  font-weight: 500;
  right: 8px;
  top: 8px;
  color: ${({ theme }) => theme.palette.grey[600]};
`;

export const StyledDialogContent = styled(DialogContent)`
  text-align: center;
  padding: ${({ padding }) => padding || "20px"};
`;

export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-around;
  padding: ${({ padding }) => padding || "16px"};
`;

export const StyledButton = styled(Button)`
  width: ${({ width }) => width || "100px"};
  height: ${({ height }) => height || "40px"};
  font-weight: bold;
  border: 1px solid #284495;
  margin-bottom: 6px;

  &.MuiButton-outlined {
    color: #01008a;
    background-color: white;
  }

  &.MuiButton-contained {
    color: white; // Explicitly set text color to white for contained buttons
    background-color: #284495;
  }
`;

export const WarningIconStyled = styled(ErrorOutlineIcon)`
  font-size: 1.5rem;
  color: red;
`;

export const StyledDilog = styled(Dialog)`
  & .MuiDialog-paper {
    margin: auto;
    max-width: ${({ maxWidth }) => maxWidth || "330px"};
    width: 100%;
    height: ${({ height }) => height || "auto"};
    padding: 5px;
    border-radius: 10px;
  }
`;

export const StyledTypo = styled(Typography)`
  font-size: ${({ fontSize }) => fontSize || "20px"};
  font-weight: ${({ fontWeight }) =>
    fontWeight || "500"}; // Dynamic font weight
  color: ${({ color }) => color || "#333"};
`;

export const StyledPara = styled(Typography)`
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  margin-top: 10px;
  line-height: 1.4;
  color: ${({ color }) => color || "#666"};
`;
