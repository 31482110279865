import React from 'react';
import EmployeePortalDialog from '../../../../../Common/EmployeePortalDialog';
import AddCertificationForm from './AddCertificationForm';
import { useSelector } from 'react-redux';

const AddCerificationDetails = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen } = props;

  const handleCloseDialog = () => {
    onClose();
  };

  const certicationForm = useSelector((state) => state?.certicationForm);

  const {
    isNewCertificateForm,
    certificationAuthority,
    certificationId,
    certifiedDate,
    serialNo,
    technologyName,
    validlastDate,
    id,
  } = certicationForm;

  const handleCertificationDetailsSubmit = async () => {
    const payload = {
      certificationAuthority,
      certificationId,
      certifiedDate,
      serialNo,
      technologyName,
      validlastDate,
      id,
    };

    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, [1000]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Certification'}
        customWidth={'450px'}
      >
        <AddCertificationForm
          onSubmit={handleCertificationDetailsSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default AddCerificationDetails;
