import { createSlice } from '@reduxjs/toolkit';
import initialState from './performanceIntialState';
import { _setPerformanceEditForm } from './performanceAction';

export const PerformanceEditListSlice = createSlice({
  name: 'ep/PerformanceEditForm',
  initialState,
  reducers: {
    setPerformanceEditForm: _setPerformanceEditForm,

    resetPerformanceEditForm: () => initialState,
  },
});

export const { setPerformanceEditForm, resetPerformanceEditForm } =
  PerformanceEditListSlice.actions;

export default PerformanceEditListSlice.reducer;
