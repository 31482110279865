import { DataGrid } from '@mui/x-data-grid';
import { getErrorMessage } from './CommonUtils';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const DataGridComponent = (props) => {
  const {
    columnsData,
    rowData,
    pageSizeValue,
    onRowClick,
    isLoading,
    height,
    width,
    noRowsOverlay,
    editable,
    hideFooter,
    dataTestId,
    pageSize,
  } = props;

  const errorMessage = getErrorMessage();
const pageSizeNumber = pageSize ? pageSize : 10 ;

  return (
    <Box sx={{ height: height, width: width }}>
      <DataGrid
        columns={columnsData}
        limit={pageSizeValue}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSizeNumber ,
            },
          },
        }}
        pageSizeOptions={[5]}
        rows={rowData}
        rowHeight={70}
        onRowClick={onRowClick}
        localeText={errorMessage}
        loading={isLoading}
        noRowsOverlay={null}
        editable={editable}
        hideFooter={hideFooter}
        data-testid={dataTestId}
      />
    </Box>
  );
};

DataGridComponent.propTypes = {
  columnsData: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
  pageSizeValue: PropTypes.number.isRequired,
  onRowClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DataGridComponent.defaultProps = {
  height: 520,
  width: '100%',
};

export default DataGridComponent;
