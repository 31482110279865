import { useState } from "react";
import EmployeePortalDialog from "../../Common/EmployeePortalDialog";
import { useNotification } from "../../Common/CommonUtils";
import MySkillForm from "./MySkillForm";
import { useSelector } from "react-redux";

const MySkillDialog = (props) => {
  const { onClose, onOpen, fetchMySkills } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const mySkillsForm = useSelector((state) => state?.mySkillsForm?.data);

  const isMySkillsForm = useSelector(
    (state) => state?.mySkillsForm?.isMySkillsForm
  );

  const handleCloseDialog = () => {
    onClose();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={
          isMySkillsForm
            ? "Add My Skill"
            : mySkillsForm?.status === "draft"
            ? "Edit My Skill"
            : "View Skill"
        }
        customWidth={"800px"}
      >
        <MySkillForm
          onCancel={handleCloseDialog}
          onClose={onClose}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
          fetchMySkills={fetchMySkills}
          onOpen={onOpen}
        />
      </EmployeePortalDialog>
      <NotificationPopup />
    </>
  );
};

export default MySkillDialog;
