import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import HynivaLogo from "../../Assets/Images/HynivaLogo.png";
import {
  HynivaLogoStyle,
  LoginGrid,
  LoginPageBox,
  LoginPageHeading,
  LoginPageMainGrid,
} from "./Login.styled";
import LoginPageFormField from "./LoginPageFormField";
import { useDispatch, useSelector } from "react-redux";
import { setPasswordValue, setUserNameValue } from "./Store/loginPageSlice";
import { setIsSideNav } from "../SideNavbar/Store/slideNavbarSlice";
import {
  useAuthentication,
  useNotification,
  useRemoveSideNav,
} from "../../Common/CommonUtils";
import {
  getBranch,
  getCoreTechnology,
  getDepartments,
  getDesignationByRole,
  getManagers,
  getProjects,
  getRole,
} from "../Employees/Services/service.js";
import { setIsViewDetailsPage } from "../Employees/store/EmployeeSlice";
import { Alert, Snackbar } from "@mui/material";
import {
  getCategory,
  getManagerEmployees,
  getSkillLevel,
  getTechnology,
  LoginApi,
} from "./LoginApiServices/LoginApi";

import { setUserInfo } from "../../Common/store/userSlice/userSlice";
import {
  setBranches,
  setCategory,
  setCoreTechnologies,
  setSkillLevel,
  setDepartments,
  setDesignations,
  setManagers,
  setProjects,
  setRoles,
  setTechnology,
  setManagerEmployees,
} from "../../Common/store/metaDataSlice/metaDataSlice.js";
import { setEmployeeId } from "../Employees/store/employeeIdSlice/employeeIdSlice.js";
import { setIsAuthenticated } from "../../Common/store/authenticatedSlice/authenticatedSlice.js";
import { setNavigationRoute } from "../SideNavbar/Store/navigationSlice/navigationSlice.js";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Must be a valid email")
    .matches(
      /^[^\s@]+@hyniva\.com$/,
      "Must be a valid email with the domain hyniva.com"
    )
    .max(255)
    .required("Email is required"),
  password: yup.string().max(255).required("Password is required"),
});

const Login = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loadingButton, setLoadingButton] = useState(false);
  //const employeeId = useSelector((state) => state?.employeeInfo?.employeeId);
  //const loginUserId = useSelector((state) => state?.user?.data?.employeeId);
  //console.log("my login ID" , loginUserId)
  useRemoveSideNav();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = "";
  const password = "";

  const defaultValue = {
    email: email,
    password: password,
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });
  const { handleSubmit, reset, control, register, formState, watch } =
    useFunction;
  const { isValid, dirtyFields, errors, isDirty } = formState;

  useEffect(() => {
    dispatch(setIsAuthenticated(false));
  }, []);

  const fetchBranch = async () => {
    try {
      const branches = await getBranch();
      dispatch(setBranches(branches));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchProjects = async () => {
    try {
      const projects = await getProjects();
      dispatch(setProjects(projects));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchRole = async () => {
    try {
      const roles = await getRole();
      dispatch(setRoles(roles));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };
  const fetchManagers = async (responseloginUserId) => {
    try {
      const managers = await getManagers(responseloginUserId);
      dispatch(setManagers(managers));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };
  const fetchDepartments = async () => {
    try {
      const departments = await getDepartments();
      dispatch(setDepartments(departments));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };
  const fetchCoreTechnology = async () => {
    try {
      const Technologies = await getCoreTechnology();
      dispatch(setCoreTechnologies(Technologies));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchCategory = async () => {
    try {
      const category = await getCategory();
      dispatch(setCategory(category));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchSkillLevel = async () => {
    try {
      const currentLevel = await getSkillLevel();
      dispatch(setSkillLevel(currentLevel));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchTechnology = async () => {
    try {
      const technology = await getTechnology();
      dispatch(setTechnology(technology));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const fetchEmployee = async (responseloginUserId) => {
    try {
      const managerEmployees = await getManagerEmployees(responseloginUserId);
      dispatch(setManagerEmployees(managerEmployees));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleDashBoard = async (data) => {
    const { email, password } = data;
    const payload = {
      email: email,
      password: password,
    };
    setLoadingButton(true);
    await LoginApi(payload)
      .then((response) => {
        dispatch(setIsAuthenticated(true));
        dispatch(setUserInfo(response));
        dispatch(setUserNameValue(email));
        dispatch(setPasswordValue(password));
        dispatch(setIsSideNav(true));
        dispatch(setIsViewDetailsPage(false));
        dispatch(setEmployeeId(response?.data.employeeId));
        const { role } = response?.data;

        navigate(
          role === "Accountant"
            ? "/invoice"
            : role === "Developer" || role === "Manager"
            ? "/dashboard"
            : role === "HR"
            ? "/employee"
            : "/"
        );

        dispatch(
          setNavigationRoute(
            role === "Accountant"
              ? "/invoice"
              : role === "Developer" || role === "Manager"
              ? "/dashboard"
              : role === "HR" && "/employee"
          )
        );

        setSnackbarMessage("Loggedin sucessfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        fetchBranch();
        fetchProjects();
        fetchRole();
        fetchDepartments();
        fetchCoreTechnology();
        fetchCategory();
        fetchSkillLevel();
        fetchTechnology();
        fetchManagers(response?.data.employeeId);
        // fetchEmployee();
        if (role === "Manager") {
          fetchEmployee(response?.data.employeeId);
        }
  
      })
      .catch((error) => {
        const errorMessage = error.response.data.detail;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
    setLoadingButton(false);
  };

  const handleForgotPassword = () => {
    navigate("/forgotPassword");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Grid>
        <LoginPageMainGrid>
          <LoginPageBox>
            <LoginGrid>
              <Grid>
                <HynivaLogoStyle src={HynivaLogo} />
                <LoginPageHeading variant="h4">Employee Login</LoginPageHeading>
              </Grid>
              <LoginPageFormField
                control={control}
                register={register}
                errors={errors}
                handleDashBoard={handleSubmit(handleDashBoard)}
                handleForgotPassword={handleForgotPassword}
                loadingButton={loadingButton}
                watch={watch}
              />
            </LoginGrid>
          </LoginPageBox>
        </LoginPageMainGrid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <NotificationPopup />
    </>
  );
};
export default Login;
