import React, { useState, useEffect } from "react";
import {
  LogContainer,
  StatusContainer,
  StyledIcon,
  StyledText,
  RequestTypo,
} from "../LogAttendance.styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import LogAttendanceDailog from "../LogAttendanceDailog";
import {
  useNotification,
  usePermissionCustomHook,
} from "../../../../Common/CommonUtils";
import { useSelector, useDispatch } from "react-redux";
import { getAttendanceRequests } from "../../services/Services";
import { HrViewAttendanceDatagridUtils } from "./HrViewAttendanceDatagridUtils";
import { formatAttendanceData } from "../LogAttendanceDatagridUtils";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Button } from "@mui/material";
const HrViewAttendanceDatagrid = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [newData, setNewData] = useState([]);
  const isHR = usePermissionCustomHook("HR");

  const statusStyles = {
    approved: {
      color: "green",
      Icon: TaskAltIcon,
    },
    pending: {
      color: "orange",
      Icon: HourglassEmptyIcon,
    },
    rejected: {
      color: "red",
      Icon: CancelIcon,
    },
  };
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const fetchedData = async () => {
    try {
      const data = await getAttendanceRequests(loginUserId);
      const formattedData = data.data.attendance_requests;

      if (!formattedData || formattedData.length === 0) {
        setNewData([]);
      } else {
        const result = HrViewAttendanceDatagridUtils(formattedData);
        setNewData(result);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setOpenNotification(true);
        setNotificationMessage(
          error.response?.data?.message ||
            "An error occurred while fetching attendance-data."
        );
      }

      setNewData([]);
    }
  };
  useEffect(() => {
    fetchedData();
  }, []);

  const handleViewLeave = (params) => {
    const {
      checkInTime,
      checkOutTime,
      workingHours,
      date,
      remarks,
      dayStatus,
      reason,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    } = params.row;
    const data = {
      checkInTime,
      checkOutTime,
      workingHours,
      remarks,
      reason,
      dayStatus,
      date,
      status,
      attendanceId,
      comment,
      attendanceRequestId,
    };

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedAttendance(null);
    setIsDialogOpen(false);
  };
  const colsData = [
    {
      field: "employeeName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Request Date",
      flex: 1,
    },
    { field: "checkIn", headerName: "Check In", flex: 1 },
    { field: "checkOut", headerName: "Check Out", flex: 1 },
    { field: "workingHours", headerName: "Hours W0orked", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const status = params.value ? params.value.toLowerCase() : null;
        const { color, Icon } = statusStyles[status] || statusStyles.default;

        return (
          <StatusContainer>
            <StyledIcon color={color}>
              <Icon />
            </StyledIcon>
            <StyledText color={color}>{params.value}</StyledText>
          </StatusContainer>
        );
      },
    },
    { field: "reason", headerName: "Reason", flex: 1 },
    { field: "remarks", headerName: "Remark", flex: 1 },
    { field: "comment", headerName: "Comment", flex: 1 },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button variant="text" onClick={() => handleViewLeave(params)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <LogContainer>
        <RequestTypo>Request attendance</RequestTypo>
        <DataGridComponent
          autoHeight
          columnsData={colsData}
          rowData={newData}
          pageSizeValue={10}
          editable={true}
        />

        {isDialogOpen && selectedAttendance && (
          <LogAttendanceDailog
            attendanceData={selectedAttendance}
            onClose={handleDialogClose}
            onOpen={isDialogOpen}
            isEdit={true}
            isHR={isHR}
            fetchedData={fetchedData}
          />
        )}
      </LogContainer>
      <NotificationPopup />
    </>
  );
};

export default HrViewAttendanceDatagrid;
