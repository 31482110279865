import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import FormDropDown from '../../Common/FormDropDown';
import { EmployeePortalinvoiceDailog } from '../../Common/EmployeePortalDialog.styled';
import { FormTextField } from '../../Common/FormTextField';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputDate } from '../../Common/FormDatePicker';

const schema = yup.object().shape({
  totalamount: yup
    .string()
    .matches(
      /^\$?[0-9]{1,15}(\.[0-9]{1,2})?$/, // Matches amount with dollar sign and optional decimal
      'Amount must be in the format $XX.XX or XX.XX'
    )
    .required('Amount is required'),
  paidAmount: yup
    .string()
    .matches(
      /^\$?[0-9]{1,15}(\.[0-9]{1,2})?$/, // Matches amount with dollar sign and optional decimal
      'Amount must be in the format $XX.XX or XX.XX'
    )
    .required('Amount is required'),
});

const InvoiceListForm = (props) => {
  const { onSubmit, onCancel, snackbarOpen, onCloseSnackbar } = props;

  const dispatch = useDispatch();

  const invoiceListForm = useSelector((state) => state?.invoiceListForm);

  const { totalamount, paidAmount, paymentdate } = invoiceListForm || {};

  const defaultValues = {
    totalamount: totalamount,
    paidAmount: paidAmount,
    paymentdate: paymentdate,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;
  const isOtpFieldDirty = dirtyFields.email;

  const courseOptions = [
    { value: 'Draft', label: 'Draft' },
    { value: 'UnPaid', label: 'UnPaid' },
    { value: 'Paid', label: 'Paid' },
  ];

  return (
    <>
      <Grid container style={{ margin: '0px 16px', width: 'auto' }}>
        <FormTextField
          label="Total Amount"
          name="totalamount"
          type="text"
          control={control}
          errors={!!errors?.totalamount}
          helperText={errors?.totalamount?.message}
          options={courseOptions}
        />
        <FormTextField
          label="Paid Amount"
          name="paidAmount"
          type="text"
          control={control}
          errors={!!errors?.paidAmount}
          helperText={errors?.paidAmount?.message}
        />
        <FormInputDate
          label="Payment Date"
          name="paymentdate"
          type="text"
          control={control}
          errors={!!errors?.paymentdate}
          helperText={errors?.paymentdate?.message}
        />
      </Grid>

      <EmployeePortalinvoiceDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        >
          Save
        </Button>
      </EmployeePortalinvoiceDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Invoice Details Edited Successfully
        </Alert>
      </Snackbar>
    </>
  );
};
export default InvoiceListForm;
