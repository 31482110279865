import { SvgIcon } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PaidIcon from '@mui/icons-material/Paid';
import DevicesIcon from '@mui/icons-material/Devices';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DescriptionIcon from '@mui/icons-material/Description';
import AdjustIcon from '@mui/icons-material/Adjust';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';

export const items = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: (
      <SvgIcon fontSize="medium">
        <DashboardRoundedIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'My Assignment',
    path: '/myassignment',
    icon: (
      <SvgIcon fontSize="medium">
        <AssignmentIndOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Employees',
    path: '/employee',
    icon: (
      <SvgIcon fontSize="medium">
        <GroupsIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Leave',
    path: '/leave',
    icon: (
      <SvgIcon fontSize="medium">
        <EventNoteOutlinedIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'My Skill',
    path: '/mySkill',
    icon: (
      <SvgIcon fontSize="medium">
        <AdjustIcon />
      </SvgIcon>
    ),
  },
  {
    title: 'Attendance',
    path: '/attendance',
    icon: (
      <SvgIcon fontSize="medium">
        <AccessTimeIcon />
      </SvgIcon>
    ),
  },
 
  // {
  //   title: 'Payroll',
  //   path: '/payroll',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <PaidIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: 'Asset Management',
  //   path: '/assetManagement',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <DevicesIcon />
  //     </SvgIcon>
  //   ),
  // },
  // {
  //   title: 'User Management',
  //   path: '/userManagement',
  //   icon: (
  //     <SvgIcon fontSize="medium">
  //       <ManageAccountsIcon />
  //     </SvgIcon>
  //   ),
  // },
  {
    title: 'Invoice',
    path: '/invoice',
    icon: (
      <SvgIcon fontSize="medium">
        <DescriptionIcon />
      </SvgIcon>
    ),
  },
];
