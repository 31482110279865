import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import CreateInvoiceLeftSection from './CreateInvoiceLeftSection';
import CreateInvoiceRightSection from './CreateInvoiceRightSection';
import CreateInvoiceCardHeaderSection from './CreateInvoiceCardHeaderSection';
import CreateInvoiceBottomSection from './CreateInvoiceBottomSection';
import { CreateInvoiceHeadingCard, HeaderButton } from './CreateInvoice.Styled';
import {
  StyledContainerGrid,
  StyledGridContainer,
} from '../../Employees/Employee.Styled';
import { TextTypography } from '../Invoice.styled';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetInvoiceInfoForm,
  setInvoiceRowData,
} from '../store/invoiceInfoFormSlice/invoiceInfoFormSlice';
import { format } from 'date-fns';
import { dateFnsYMDFormat } from '../../../Common/Constant';
import SendInvoiceDialog from './SendInvoiceDialog';
import InvoicePDFDialog from './InvoicePDFDialog';
import { showLoader, useNotification } from '../../../Common/CommonUtils';
import { setIsLoading } from '../../../Common/Loader/store/loaderSlice';
import { getCustomers, postInvoice } from '../services/invoiceApi';
import useSnackbar from '../../../Common/CustomHooks/useSnackbar';

const CreateInvoice = () => {
  const dispatch = useDispatch();

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const { showSnackbar, AlertSnackbar } = useSnackbar();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isPdfDialogOpen, setIsPdfDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [customers, setCustomers] = useState(null);
  const [subTotalAmount, setSubTotalAmount] = useState(0);

  const invoiceInfoForm = useSelector((state) => state.invoiceInfoForm);

  const { invoiceRowData, currency, note, invoiceNumber } =
    invoiceInfoForm || {};

  const isLoading = useSelector((state) => state?.loader?.isLoading);

  const defaultValues = {
    invoiceNumber: invoiceNumber,
    invoicedate: new Date(),
    paymentDueDate: new Date(),
    items: invoiceRowData,
    currency: currency,
    note: note,
  };

  const { control, handleSubmit, register, reset, getValues, setValue } =
    useForm({
      mode: 'onChange',
      defaultValues,
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      dispatch(setIsLoading(true));
      const customersData = await getCustomers();
      setCustomers(customersData);
      dispatch(setIsLoading(false));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      dispatch(setIsLoading(false));
    }
  };

  const onSubmit = async (data, status) => {
    const {
      invoiceNumber,
      invoicedate,
      items,
      currency,
      paymentDueDate,
      note,
    } = data;
    dispatch(setInvoiceRowData(items));

    const lineItems = items?.map((item) => ({
      ...item,
      invoice_id: 0,
    }));

    const totalAmountAndTax = lineItems?.reduce((acc, currentItem) => {
      const amount = parseFloat(currentItem.amount);
      const taxAmount = parseFloat(currentItem.tax_amount);
      if (!isNaN(amount) && !isNaN(taxAmount)) {
        return acc + amount + taxAmount;
      } else {
        return acc;
      }
    }, 0);
    setSubTotalAmount(totalAmountAndTax);

    const { customer, days } = invoiceInfoForm;
    const payload = {
      id: 0,
      customer_id: customer?.data?.[0]?.customerId,
      invoice_number: invoiceNumber,
      invoice_date: invoicedate
        ? format(new Date(invoicedate), dateFnsYMDFormat)
        : null,
      payment_due_date: paymentDueDate
        ? format(new Date(paymentDueDate), dateFnsYMDFormat)
        : null,
      payment_due_days: days,
      line_items: lineItems,
      currency: currency,
      note: note === null ? '' : note,
      subtotal: totalAmountAndTax,
      discount_percentage: 0,
      gst_type: 'CGST',
      gst_percentage: 0,
      gst_amount: 0,
      discount_amount: 0,
      total: totalAmountAndTax,
      status: status,
      created_date: format(new Date(), dateFnsYMDFormat),
      updated_date: format(new Date(), dateFnsYMDFormat),
    };
    // dispatch(resetInvoiceInfoForm());
    showSnackbar('Invoice Saved Successfully');

    try {
      await postInvoice(payload);
    } catch (error) {}
  };

  const handleSaveAndDraft = async (data) => {
    onSubmit(data, 'draft');
  };

  const handleSaveAndContinue = async (data) => {
    onSubmit(data, 'unpaid');
    setIsPdfDialogOpen(true);
  };

  if (isLoading) {
    return showLoader();
  }

  return ( 
    <>
      <StyledGridContainer
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        data-testId="invoice-container"
      >
        <TextTypography variant="h3">New Invoice</TextTypography>
        <StyledContainerGrid item data-testId="invioce-buttons">
          <HeaderButton
            variant="contained"
            onClick={handleSubmit(handleSaveAndDraft)}
            data-testId="invoice-save-and-draft-button"
          >
            Save & Draft
          </HeaderButton>
          <HeaderButton
            variant="contained"
            onClick={handleSubmit(handleSaveAndContinue)}
            data-testId="invoice-save-and-continue-button"
          >
            Save & Continue
          </HeaderButton>
        </StyledContainerGrid>
      </StyledGridContainer>
      <CreateInvoiceHeadingCard data-testId=" invoice-card-section">
        <CreateInvoiceCardHeaderSection />
        <Grid container spacing={2} data-testId="invoice-grid-section">
          <Grid item xs={12} sm={6} data-testId="invoice-add-customer-section">
            <CreateInvoiceLeftSection customers={customers} />
          </Grid>
          <Grid item xs={12} sm={6} data-testId=" invoice-date-section">
            <CreateInvoiceRightSection
              control={control}
              register={register}
              setValue={setValue}
            />
          </Grid>
        </Grid>
        <CreateInvoiceBottomSection
          control={control}
          register={register}
          fields={fields}
          append={append}
          remove={remove}
          setValue={setValue}
          getValues={getValues}
          subTotalAmount={subTotalAmount}
        />
      </CreateInvoiceHeadingCard>
      <SendInvoiceDialog
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        setIsPdfDialogOpen={setIsPdfDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      <InvoicePDFDialog
        onClose={() => setIsPdfDialogOpen(false)}
        onOpen={isPdfDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
      <NotificationPopup />
      <AlertSnackbar />
    </>
  );
};

export default CreateInvoice;
