import React from "react";
import HrManagerSkillTabs from "./HrManagerSkillTabs";
import { usePermissionCustomHook, useSideNav } from "../../../Common/CommonUtils";
import MySkill from "../MySkill";

const HrManagerSkill = () => {
  useSideNav();

  const isDeveloper = usePermissionCustomHook("Developer");

  return (
    <>

      {isDeveloper ? <MySkill /> :  <HrManagerSkillTabs />}
    </>
  );
};

export default HrManagerSkill;
