import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoughnutChart from "./DoughnutChart";
import { getLeavesBalence } from "../../Leave/services/LeaveApi";
import { useNotification } from "../../../Common/CommonUtils";
import {
  ApplyLink,
  DividerContainer,
  LeaveChartCard,
  LeaveChartCircle,
  LeaveChartContainer,
  LeaveOverview,
  Title,
} from "./Dashboard.styled";
import { useNavigate } from "react-router-dom";
import {
  setIsMyLeave,
  setIsNewApplyLeaveForm,
} from "../../Leave/store/applyLeavesSlice/applyLeaveSlice";
import { setNavigationRoute } from "../../SideNavbar/Store/navigationSlice/navigationSlice";

const LeaveChart = () => {
  const dispatch = useDispatch();
  const [leaveBalence, setLeaveBalence] = useState([]);
  const navigate = useNavigate();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    fetchLeavesBalence();
  }, []);

  const fetchLeavesBalence = async () => {
    try {
      const leavesBalence = await getLeavesBalence(loginUserId);
      setLeaveBalence(leavesBalence);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const bgColor = ["#80bfff", "#ffad33"];
  const chartData = leaveBalence?.leave_summary?.map((item, index) => ({
    label: item.leave_type,
    value: item.availed_leave,
    total: item.total_leave,
    balanceLeave: item.balance_leave,
    bgColor: bgColor[index],
  }));

  const handleLeaveOverview = () => {
    dispatch(setNavigationRoute("/leave"));
  };

  const handleApplyLeave = () => {
    navigate("/leave");
    handleLeaveOverview();
    dispatch(setIsNewApplyLeaveForm(true));
    dispatch(setIsMyLeave(true));
  };

  return (
    <>
      <LeaveChartCard>
        <LeaveChartContainer container>
          <Title variant="h2">Leaves</Title>
          <ApplyLink variant="text" onClick={handleApplyLeave}>
            Apply Leave
          </ApplyLink>
        </LeaveChartContainer>
        <DividerContainer />
        <LeaveChartCircle container spacing={2}>
          {chartData?.map((item, index) => (
            <DoughnutChart
              key={index}
              label={item.label}
              value={item.value}
              bgColor={item.bgColor}
              total={item.total}
              balanceLeave={item.balanceLeave}
            />
          ))}
        </LeaveChartCircle>
        <LeaveOverview
          href="/leave"
          underline="always"
          onClick={handleLeaveOverview}
        >
          Leave Overview
        </LeaveOverview>
      </LeaveChartCard>
      <NotificationPopup />
    </>
  );
};

export default LeaveChart;
