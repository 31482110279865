import { createSlice } from "@reduxjs/toolkit";
import {
  _setApplyLeaveForm,
  _setIsNewApplyLeaveForm,
  _setIsMyLeave,
  _setIsMyTeamLeave,
} from "./applyLeaveAction";
import initialState from "./applyLeaveInitialState";

export const applyLeaveSlice = createSlice({
  name: "ep/applyLeave",
  initialState,
  reducers: {
    setApplyLeaveForm: _setApplyLeaveForm,
    setIsNewApplyLeaveForm: _setIsNewApplyLeaveForm,
    setIsMyLeave: _setIsMyLeave,
    setIsMyTeamLeave: _setIsMyTeamLeave,
    resetApplyLeaveForm: () => initialState,
  },
});

export const {
  setApplyLeaveForm,
  setIsNewApplyLeaveForm,
  setIsMyLeave,
  setIsMyTeamLeave,
  resetApplyLeaveForm,
} = applyLeaveSlice.actions;

export default applyLeaveSlice.reducer;
