import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EmployeePortalDialog from '../../../../../Common/EmployeePortalDialog';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import AddDocumentForm from './AddDocumentForm';
import { documentNameOptions } from '../../../../../Common/Constant';

const schema = yup.object().shape({
  documentType: yup.string().required('Document Type is required'),
  documentName: yup.string().required('Document Name is required'),
});

const AddDocument = (props) => {
  const { onClose, onOpen } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const defaultValues = {
    serialNo: '',
    documentId: '',
    documentType: '',
    documentName: '',
    date: new Date(),
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;

  const onSubmit = (data) => {
    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, 1500);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onCancel = () => {
    onClose();
  };

  const disablingButton =
    Object.keys(dirtyFields).length !== Object.keys(schema.fields).length ||
    Object.keys(errors).length !== 0;

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Add Document'}
        customWidth={'450px'}
      >
        <AddDocumentForm
          control={control}
          register={register}
          errors={errors}
          documentNameOptions={documentNameOptions}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          onCancel={onCancel}
          formState={formState}
          disablingButton={disablingButton}
        />
      </EmployeePortalDialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Document added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddDocument;
