export const _setCertificationForm = (state, action) => action.payload;

export const _setIsNewCertificateForm = (state, action) => {
  state.isNewCertificateForm = action.payload;
};

export const _setCertificationId = (state, action) => {
  state.certificationId = action.payload;
};
export const _setTechnologyName = (state, action) => {
  state.technologyName = action.payload;
};
export const _setCertificationAuthority = (state, action) => {
  state.certificationAuthority = action.payload;
};
export const _setCertifiedDate = (state, action) => {
  state.certifiedDate = action.payload;
};
export const _setValidlastDate = (state, action) => {
  state.validlastDate = action.payload;
};
