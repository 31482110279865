import React, { useState } from 'react';
import { LoginPageButtonGrid } from '../Login/Login.styled';
import { CancelButton, SendInstructionButton } from './ForgotPassword.Styled';
import VerifyOtp from './VerifyOtp/VerifyOtp';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { sendInstructionApi } from './ForgotPasswordApiServices/ForgotPasswordApi';
import { useDispatch } from 'react-redux';
import { setResetPasswordEmail } from './ResetPassword/store/resetPasswordSlice';

const ForgotPasswordButton = (props) => {
  const { handleClick, handleSubmit, forgotPasswordEmail } = props;
  const [openVerifyOtp, setOpenVerifyOtp] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const dispatch = useDispatch();

  const handleSendInstructionClick = (data) => {
    const { email } = data;
      dispatch(setResetPasswordEmail(email))

    sendInstructionApi(email)
      .then(() => {
        setSnackbarMessage('OTP has sent successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setOpenVerifyOtp(true);
      })
      .catch((error) => {
        const errorMessage = error.response.data.detail;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleVerifyOtpPageClose = () => {
    setOpenVerifyOtp(false);
  };

  return (
    <>
      <LoginPageButtonGrid>
        <CancelButton
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          variant="contained"
          onClick={handleClick}
        >
          Cancel
        </CancelButton>
        <SendInstructionButton
          fullWidth
          size="large"
          sx={{ mt: 3 }}
          variant="contained"
          onClick={handleSubmit(handleSendInstructionClick)}
        >
          Send Instruction
        </SendInstructionButton>
      </LoginPageButtonGrid>
      {openVerifyOtp && (
        <VerifyOtp onClose={handleVerifyOtpPageClose} email={forgotPasswordEmail} />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ForgotPasswordButton;
