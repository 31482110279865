import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StyledContainerGrid } from '../../Employee.Styled';
import AddIcon from '@mui/icons-material/Add';
import SvgIcon from '@mui/material/SvgIcon';
import CreateEmployee from '../../CreateEmployee';
import AddDocument from './DocumentDetails/AddDocumentDialog';

const DocumentDetails = () => {
  const [openCreateEmployeeDialog, setOpenCreateEmployeeDialog] =
    useState(false);

  const handleCloseCreateEmployeeDialog = () => {
    setOpenCreateEmployeeDialog(false);
  };
  return (
    <>
      <StyledContainerGrid item>
        <Button
          endIcon={
            <SvgIcon fontSize="small">
              <AddIcon />
            </SvgIcon>
          }
          variant="contained"
          onClick={() => setOpenCreateEmployeeDialog(true)}
        >
          Add Document
        </Button>
      </StyledContainerGrid>
      {openCreateEmployeeDialog && (
        <AddDocument
          onClose={handleCloseCreateEmployeeDialog}
          onOpen={openCreateEmployeeDialog}
          // fetchEmployeeData={fetchEmployeeData}
        />
      )}
    </>
  );
};

export default DocumentDetails;
