import React from "react";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DividerContainer, LeaveChartCard, Title } from "./Dashboard.styled";
import { Grid, Typography } from "@mui/material";
import { ListStyled, PdfList } from "./HolidayList.styled";
const HolidayList = () => {
  return (
    <LeaveChartCard>
      <Grid container style={{ padding: "8px " }}>
        <Title variant="h2">HR Policies</Title>
      </Grid>
      <DividerContainer />
      <Grid container style={{ padding: "8px " }}>
        <ListStyled>
          {[
            "Hyniva_ Holiday List_ 2024.pdf",
            "Hyniva_ Holiday List_ 2023.pdf",
            "Hyniva_ Holiday List_ 2022.pdf",
          ].map((value, index) => (
            <React.Fragment key={value}>
              <ListItem disableGutters>
                <PdfList variant="labelText" sx={{ flexGrow: 1 }}>
                  {value}
                </PdfList>
                <IconButton aria-label="open in new tab">
                  <OpenInNewIcon />
                </IconButton>
              </ListItem>
            </React.Fragment>
          ))}
        </ListStyled>
      </Grid>
    </LeaveChartCard>
  );
};

export default HolidayList;
