import React, { useState } from 'react';
import { FormTextField } from '../../../Common/FormTextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormInputDate } from '../../../Common/FormDatePicker';
import {
  DateFieldGrid,
  GridCenter,
  InviceDateText,
  InviceNumberText,
} from './CreateInvoice.Styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDays,
  setDaysString,
  setInvoiceNumber,
  setPaymentDueDate,
} from '../store/invoiceInfoFormSlice/invoiceInfoFormSlice';
import { dateFnsFormat } from '../../../Common/Constant';
import { format } from 'date-fns';

const CreateInvoiceRightSection = (props) => {
  const { control, register, setValue } = props;

  const dispatch = useDispatch();
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const invoiceInfoForm = useSelector((state) => state.invoiceInfoForm);
  const { daysString } = invoiceInfoForm || {};

  const handleChange = (event) => {
    const daysNumber =
      event.target.value === 'today'
        ? 0
        : event.target.value === 'a'
        ? 15
        : event.target.value === 'b'
        ? 30
        : 45;
    dispatch(setDaysString(event.target.value));
    dispatch(setDays(daysNumber));
    const dueDate = new Date(
      invoiceDate.getTime() + daysNumber * 24 * 60 * 60 * 1000
    );
    const updatedDueDate = format(new Date(dueDate), dateFnsFormat);
    dispatch(setPaymentDueDate(updatedDueDate));
    setValue('paymentDueDate', new Date(dueDate));
  };

  return (
    <>
      <GridCenter container data-testId="invoice-number-grid">
        <InviceNumberText data-testId="invoice-number">
          Invoice Number:
        </InviceNumberText>
        <FormTextField
          label="Invoice Number"
          name="invoiceNumber"
          type="text"
          control={control}
          readOnly
          register={register('invoiceNumber', {
            onChange: (e) => dispatch(setInvoiceNumber(e.target.value)),
          })}
          style={{ width: '160px' }}
        />
      </GridCenter>
      <GridCenter container data-testId="invoice-date-grid">
        <InviceDateText data-testId="invoice-date">
          Invoice Date:
        </InviceDateText>
        <DateFieldGrid container data-testId="invoice-date-container">
          <FormInputDate
            label="Invoice Date"
            name="invoicedate"
            type="date"
            control={control}
            readOnly
          />
        </DateFieldGrid>
      </GridCenter>

      <GridCenter container data-testId="invoice-payment-due-date">
        <InviceDateText data-testId="payment-due-date">
          Payment Due Date:
        </InviceDateText>
        <DateFieldGrid
          container
          data-testId="invoice-payment-due-date-container"
        >
          <FormInputDate
            label="Payment Due Date"
            name="paymentDueDate"
            type="date"
            control={control}
            readOnly
          />
        </DateFieldGrid>
      </GridCenter>

      <GridCenter container data-testId="invoice-radio-button">
        <FormControlLabel
          value="today"
          control={
            <Radio
              checked={daysString === 'today'}
              size="small"
              onChange={handleChange}
            />
          }
          label="Today"
          data-testId="today-radio-button"
        />
        <FormControlLabel
          value="a"
          control={
            <Radio
              checked={daysString === 'a'}
              size="small"
              onChange={handleChange}
            />
          }
          label="15 days"
          data-testId="15-days-radio-button"
        />
        <FormControlLabel
          value="b"
          control={
            <Radio
              checked={daysString === 'b'}
              size="small"
              onChange={handleChange}
            />
          }
          label="30 days"
          data-testId="30-days-radio-button"
        />
        <FormControlLabel
          value="c"
          control={
            <Radio
              checked={daysString === 'c'}
              size="small"
              onChange={handleChange}
            />
          }
          label="45 days"
          data-testId="45-days-radio-button"
        />
      </GridCenter>
    </>
  );
};

export default CreateInvoiceRightSection;
