import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CreateButtonStyling,
  EmployeePortalActionDailog,
} from "../../Common/EmployeePortalDialog.styled";
import { useSelector } from "react-redux";
import { FormTextField } from "../../Common/FormTextField";
import FormDropDown from "../../Common/FormDropDown";
import { FormInputDate } from "../../Common/FormDatePicker";
import {
  CreateEmployeeLeftTextGrid,
  CreateEmployeeRightTextGrid,
} from "./CreateEmployeeForm.styled";
import { getDesignationByRole } from "./Services/service";
import { dropDownOptions } from "../../Common/CommonUtils";

const schema = yup.object().shape({
  firstName: yup
    .string()
    .max(255)
    .required("First Name is required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z\s]*$/,
      "First Name should only contain letters"
    ),
  lastName: yup
    .string()
    .max(255)
    .required("Last Name is required")
    .matches(
      /^(?=.*[A-Za-z])[A-Za-z\s]*$/,
      "Last Name should only contain letters and spaces"
    ),
  date: yup.date().required().typeError("Date of birth is required"),
  email: yup
    .string()
    .matches(/^[^\s@]+@hyniva\.com$/i, "Must be a valid @hyniva.com email")
    .transform((value) => (value ? value.trim() : null))
    .required("Email is required"),
  employeeId: yup
    .string()
    .max(255)
    .test("is-numeric", "Only digits are allowed", (value) =>
      /^[0-9]*$/.test(value)
    ),
  branchOffice: yup.string().required("Please select the branch office"),
  designation: yup.string().required("Please select the Designation"),
  role: yup.string().required("Please select the role"),
});

const CreateEmployeeForm = (props) => {
  const { onSubmit, snackbarOpen, onCloseSnackbar } = props;

  const [designationByRoleOptions, setDesignationByRoleOptions] = useState([]);

  const metaData = useSelector((state) => state?.metaData);

  const { branches, roles } = metaData;

  const defaultValues = {
    firstName: "",
    lastName: "",
    date: "",
    email: "",
    employeeId: "",
    branchOffice: "",
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, isValid } = formState;

  const roleByDesignation = dropDownOptions(designationByRoleOptions);
  const brancheOptions = dropDownOptions(branches);
  const roleOptions = dropDownOptions(roles);

  const handleRole = async (e) => {
    try {
      const designationByRole = await getDesignationByRole(e.target.value);
      setDesignationByRoleOptions(designationByRole);
    } catch (error) {}
  };

  return (
    <>
      <Grid container spacing={3}>
        <CreateEmployeeLeftTextGrid item xs={12} sm={6}>
          <FormTextField
            label="First Name"
            name="firstName"
            type="text"
            control={control}
            errors={!!errors?.firstName}
            helperText={errors?.firstName?.message}
            register={register("firstName")}
          />
          <FormTextField
            label="Last Name"
            name="lastName"
            type="text"
            control={control}
            errors={!!errors?.lastName}
            helperText={errors?.lastName?.message}
            register={register("lastName")}
          />
          <FormDropDown
            label="Role"
            name="role"
            control={control}
            errors={errors?.role}
            helperText={errors?.role?.message}
            options={roleOptions}
            register={register("role", {
              onChange: (e) => {
                handleRole(e);
              },
            })}
          />
          <FormDropDown
            label="Branch Office"
            name="branchOffice"
            control={control}
            errors={errors?.branchOffice}
            helperText={errors?.branchOffice?.message}
            options={brancheOptions}
          />
        </CreateEmployeeLeftTextGrid>
        <CreateEmployeeRightTextGrid
          item
          xs={12}
          sm={6}
          style={{ paddingLeft: "10px", paddingRight: "16px" }}
        >
          <FormTextField
            label="Email Address"
            name="email"
            type="email"
            control={control}
            errors={!!errors?.email}
            helperText={errors?.email?.message}
            register={register("email")}
          />
          <FormInputDate
            name="date"
            control={control}
            label="Date Of Birth"
            helperText={errors?.date?.message}
            errors={!!errors.date}
            maxDate={new Date()}
          />
          <FormDropDown
            label="Designation"
            name="designation"
            control={control}
            errors={errors?.designation}
            helperText={errors?.designation?.message}
            options={roleByDesignation}
          />
        </CreateEmployeeRightTextGrid>
      </Grid>

      <EmployeePortalActionDailog>
        <CreateButtonStyling
          variant="contained"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </CreateButtonStyling>
      </EmployeePortalActionDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Employee has created Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateEmployeeForm;
