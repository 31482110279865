import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "@emotion/react";

import {
  DataMainGrid,
  StyledGridContainer,
} from "../../../Employees/Employee.Styled";
import { intialPageSize } from "../../../../Common/Constant";
import { AddButtonGrid } from "../../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled";
import DataGridComponent from "../../../../Common/DataGridComponent";
import {
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../../../Common/CommonUtils";
import { getLeaveDetails, getLeavesRequests } from "../../services/LeaveApi";
import ApplyLeave from "../ApplyLeave";
import LensIcon from "@mui/icons-material/Lens";
import { useDispatch, useSelector } from "react-redux";
import { MuiCard } from "../../../../Common/Common.styled";
import { getLeaveRequestDataGridUtils } from "./LeavesRequestDataUtils";
import {
  ApplyLeaveButton,
  HolidayGrid,
  LeaveTypography,
} from "../Leave.styled";
import {
  resetApplyLeaveForm,
  setApplyLeaveForm,
  setIsMyTeamLeave,
  setIsNewApplyLeaveForm,
} from "../../store/applyLeavesSlice/applyLeaveSlice";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";

const ManagerViewDataGrid = () => {
  const dispatch = useDispatch();
  const [teamsLeaves, setTeamsLeaves] = useState(false);
  const [teamsLeavesApply, setTeamsLeavesApply] = useState(false);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [leaveDetails, setLeaveDeatils] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });
  const { page } = leaveDetails;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isHR = usePermissionCustomHook("HR");

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const useFunction = useForm({
    mode: "onChange",
    // defaultValues: defaultValue,
  });

  const { reset, control, register } = useFunction;
  const theme = useTheme();

  const columnsData = [
    {
      field: "employeeName",
      headerName: "Name",
      width: 120,
    },
    {
      field: "appliedDate",
      headerName: "Request Date",
      width: 120,
    },
    {
      field: "leaveName",
      headerName: "Leave Type",
      width: 120,
    },
    {
      field: "fromDate",
      headerName: "From Date",
      width: 120,
    },
    {
      field: "toDate",
      headerName: " To Date",
      width: 120,
    },
    {
      field: "totalDays",
      headerName: "Days",
      width: 120,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 120,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LensIcon
            sx={{
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              marginLeft: "8px",
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "action_by",
      headerName: "Action By",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => {
        return (
          <Button variant="text" onClick={() => handleViewLeave(params)}>
            View
          </Button>
        );
      },
    },
  ];

  const handleViewLeave = async (params) => {
    const { row } = params;
    try {
      setTeamsLeaves(true);
      const leaveDetails = await getLeaveDetails(row.leaveId);
      dispatch(setApplyLeaveForm(leaveDetails));
      const statusButton = row.status.toLowerCase() === "pending" ? true : false
      dispatch(setIsMyTeamLeave(row.status.toLowerCase() === "pending"))
      setIsDialogOpen(true);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleApplyNewLeave = () => {
    // setSnackbarOpen(false);
    //dispatch(resetApplyLeaveForm());
    //dispatch(setIsNewApplyLeaveForm(true));
    setIsDialogOpen(true);
  };

  const handleApplyLeaveCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    fetchLeavesData();
    setTeamsLeavesApply(true);
  }, []);

  const fetchLeavesData = async () => {
    try {
      setLeaveDeatils({ ...leaveDetails, isLoading: true });

      const leavesData = await getLeavesRequests(loginUserId);

      const leavesListData = getLeaveRequestDataGridUtils(leavesData?.data);
      setLeaveDeatils((prev) => ({
        ...prev,
        rows: leavesListData,
        isLoading: false,
      }));
    } catch (error) {
      setLeaveDeatils({ ...leaveDetails, isLoading: false });
    }
  };

  return (
    <>
      {isHR && (
        <>
          <StyledGridContainer
            container
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <LeaveTypography variant="h3">Leave Requests</LeaveTypography>
          </StyledGridContainer>
          <HolidayGrid style={{ marginTop: "16px" }}></HolidayGrid>
        </>
      )}
      {!isHR ||
        (!teamsLeavesApply && (
          <AddButtonGrid
            container
            data-testid="certification-accordion-add-button-grid"
          >
            <ApplyLeaveButton
              variant="contained"
              onClick={() => handleApplyNewLeave()}
            >
              Apply Leave
            </ApplyLeaveButton>
          </AddButtonGrid>
        ))}
      <ApplyLeave
        onClose={handleApplyLeaveCloseDialog}
        onOpen={isDialogOpen}
        teamsLeaves={teamsLeaves}
        setTeamsLeaves={setTeamsLeaves}
        teamsLeavesApply={teamsLeavesApply}
        setTeamsLeavesApply={setTeamsLeavesApply}
        fetchLeavesData={fetchLeavesData}
      />
      <DataMainGrid>
        <MuiCard>
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={leaveDetails.rows}
            pageSizeValue={leaveDetails.limit}
            // height="auto"
            // width="auto"
            editable={true}
          />
        </MuiCard>
      </DataMainGrid>
      <NotificationPopup />
    </>
  );
};
export default ManagerViewDataGrid;
