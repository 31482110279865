import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const HeaderText = styled(Typography)`
  text-align: center;
`;

export const ContentText = styled(Typography)`
  text-align: center;
  padding: 0px 10px 2px;
`;

export const NotificationDialogContent = styled(DialogContent)`
  padding: 0px;
`;

export const ButtonStyling = styled(Button)`
  padding: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
`;
