const initialState = {
  customer: null,
  invoiceNumber: 'HY202425',
  invoiceDate: null,
  paymentDueDate: null,
  daysString: 'today',
  days: 0,
  invoiceRowData: [],
  currency: '',
  note: null,
};
export default initialState;
