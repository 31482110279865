import { createSlice } from '@reduxjs/toolkit';
import { _setIsAuthenticated } from './authenticatedAction';
import initialState from './authenticatedInitialState';


export const authenticatedSlice = createSlice({
    name: 'ep/Authenticated',
    initialState,
    reducers: {
        setIsAuthenticated: _setIsAuthenticated,
        resetAuthenticated: () => initialState,
    },
});
export const { setIsAuthenticated, resetAuthenticated } =
authenticatedSlice.actions;
export default authenticatedSlice.reducer;
