import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  EditButtonGrid,
  SalaryAndBankDetailsHeading,
  SalaryAndBankDetailsMainGrid,
} from './SalaryAndBankDetails.styled';
import EditControls from '../../../../../Common/EditControl';
import PayrollDetailsFormField from './PayrollDetailsFormField';
import { ViewDtailsCard } from '../../ViewDetailsPage.styled';

const schema = yup.object().shape({
  panNumber: yup
    .string()
    .required('PAN Number is required.')
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]$/,
      'PAN Number should be in the format ABCDE1234F.'
    ),

  basicPay: yup
    .string()
    .required('Basic Pay is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Basic Pay should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  earnings: yup
    .string()
    .required('Earnings is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Earnings should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  deductions: yup
    .string()
    .required('Deductions is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Deductions should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  netPay: yup
    .string()
    .required('Net Pay is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Net Pay should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  bonus: yup
    .string()
    .required('Bonus is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Bonus should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  variablePay: yup
    .string()
    .required('Variable Pay is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Variable Pay should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  enCashment: yup
    .string()
    .required('Leave EnCashment is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Leave EnCashment should be a valid amount (e.g., 662.00).'
    )
    .nullable(),
});

const PayrollDetails = () => {
  const initialFormData = {
    panNumber: 'ABCDE1234F',
    basicPay: '8678.00',
    earnings: '237896.00',
    deductions: '1345',
    netPay: '23789',
    bonus: '2000',
    variablePay: '1789',
    enCashment: '1897',
    createdDateTime: '02/10/2021',
    updatedDateTime: '02/10/2021 ',
  };

  const defaultValue = {
    panNumber: initialFormData.panNumber,
    basicPay: initialFormData.basicPay,
    earnings: initialFormData.earnings,
    deductions: initialFormData.deductions,
    netPay: initialFormData.netPay,
    bonus: initialFormData.bonus,
    variablePay: initialFormData.variablePay,
    enCashment: initialFormData.enCashment,
    createdDateTime: initialFormData.createdDateTime,
    updatedDateTime: initialFormData.updatedDateTime,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(initialFormData);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(initialFormData);
    setEditable(false);
    setFormChanged(false);
  };

  const handleSaveClick = () => {
    setEditable(false);
    setInitialFormValues(formData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard>
      <SalaryAndBankDetailsMainGrid container item xs={12}>
        <Grid item xs={6}>
          <SalaryAndBankDetailsHeading variant="h6">
            Payroll
          </SalaryAndBankDetailsHeading>
        </Grid>
        <EditButtonGrid item xs={6}>
          <EditControls
            editable={editable}
            onCancelClick={handleCancelClick}
            onSaveClick={handleSubmit(handleSaveClick)}
            onEditClick={handleEditClick}
            saveDisabled={!formChanged || isErrorField}
          />
        </EditButtonGrid>
      </SalaryAndBankDetailsMainGrid>
      <PayrollDetailsFormField
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        register={register}
        handleChange={handleChange}
        editable={editable}
      />
    </ViewDtailsCard>
  );
};

export default PayrollDetails;
