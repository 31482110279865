import React, { useEffect, useState } from "react";
import { getLeavesBalence } from "../services/LeaveApi";
import { useSelector } from "react-redux";
import { useNotification } from "../../../Common/CommonUtils";
import DoughnutChart from "./DoughnutChart";

const LeaveChart = () => {
  const [leaveBalance, setLeaveBalence] = useState([]);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    fetchLeavesBalence();
  }, []);

  const fetchLeavesBalence = async () => {
    try {
      const leavesBalence = await getLeavesBalence(loginUserId);
      setLeaveBalence(leavesBalence);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };


const checkSickLeave = leaveBalance?.leave_summary?.find((each) => each.leave_type === "Sick Leave")
const checkAnnualLeave = leaveBalance?.leave_summary?.find((each) => each.leave_type === "Annual Leave")



  const chartData = [
    { label: "Total Accumulated", value: leaveBalance?.total_all_leaves ,annualLeave : checkAnnualLeave?.total_leave, sickLeave : checkSickLeave?.total_leave},
    { label: "Total Availed", value: leaveBalance?.availed_all_leaves , annualLeave : checkAnnualLeave?.availed_leave, sickLeave : checkSickLeave?.availed_leave},
    { label: "Total Balance", value: leaveBalance?.balance_all_leaves, annualLeave : checkAnnualLeave?.balance_leave, sickLeave : checkSickLeave?.balance_leave},
  ];

  return (
    <>
      {chartData.map((data, index) => (
        <DoughnutChart
          key={index}
          label={data.label}
          value={data.value}
          total={leaveBalance?.total_all_leaves}
          annualLeave = {data.annualLeave}
          sickLeave = {data.sickLeave}
        />
      ))}
      <NotificationPopup />
    </>
  );
};

export default LeaveChart;
