import { createSlice } from '@reduxjs/toolkit';
import { _setNavigationRoute } from './navigationAction';
import initialState from '../sideNavbarIntialState';
// import initialState from './navigationInitialState';
 
export const navigationSlice = createSlice({
  name: 'ep/navigation',
  initialState,
  reducers: {
    setNavigationRoute: _setNavigationRoute,
    resetNavigationRoute: () => initialState,
  },
});
 
export const { setNavigationRoute, resetNavigationRoute } =
  navigationSlice.actions;
 
export default navigationSlice.reducer;