import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const EmployeeInformationCardGrid = styled(Grid)`
  display: flex;
`;

export const EmployeeInformationCardEditIconGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const EmployeeInformationCard = styled(Card)`
  box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.3),
    0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
`;
