import Grid from "@mui/material/Grid";
import { FormTextField } from "../../../../../Common/FormTextField";
import { statusOptions } from "../../../../../Common/Constant";
import FormDropDown from "../../../../../Common/FormDropDown";

const AssetsInformationForm = (props) => {
  const { control, errors, defaultValue, register, handleChange, editable } =
    props;
  return (
    <Grid container spacing={2} sx={{ paddingY: 2 }}>
      <Grid item xs={12} sm={4}>
        <FormTextField
          name="assetType"
          label="Asset Type"
          type="text"
          control={control}
          defaultValue={defaultValue?.assetType}
          errors={!!errors?.assetType}
          helperText={errors?.assetType?.message}
          register={register("assetType", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormTextField
          name="serialNumber"
          label="Serial Number"
          type="text"
          control={control}
          defaultValue={defaultValue?.serialNumber}
          errors={!!errors?.serialNumber}
          helperText={errors?.serialNumber?.message}
          register={register("serialNumber", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormDropDown
          label="Status"
          name="status"
          control={control}
          options={statusOptions}
          errors={!!errors?.status}
          helperText={errors?.status?.message}
          register={register("status", {
            onChange: handleChange,
          })}
          readOnly={!editable}
        />
      </Grid>
    </Grid>
  );
};

export default AssetsInformationForm;
