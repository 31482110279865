export const _setIsClicked = (state, action) => {
  state.isClicked = action.payload;
};

export const _setUserNameValue = (state, action) => {
  state.userNameValue = action.payload;
};

export const _setPasswordValue = (state, action) => {
  state.passwordValue = action.payload;
};
