import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { css } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { InputLabel } from "@mui/material";

export const disableFocusStyles = css`
  background-color: transparent;
  border-color: rgb(229, 231, 235);
  border-style: solid;
  border-width: 1px;
  box-shadow: unset;
`;

export const MuiCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
  border-radius: 8px;
`;

export const LeaveChartCard = styled(MuiCard)(({ theme }) => ({
  height: "100%",
  display: "flex",
  padding: "4px 48px",
  alignItems: "center",
  justifyContent: "space-between",

  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  [`@media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px)`]: {
    padding: "2px 4px",
  },
}));

export const TimeOutGrid = styled(Grid)`
  padding: 8px 32px;
`;

export const InputLabelRed = styled(InputLabel)`
  color: #ff0000;
`;

export const TimePickerTypography = styled(Grid)`
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => (props.errors ? "#ff0000" : "inherit")};
`;
