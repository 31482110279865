import React, { useEffect, useState } from "react";
import { Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../Components/Login/Login";
import Attendance from "../Components/Attendance/Attendance";
import ForgotPassword from "../Components/ForgotPassword/ForgotPassword";
import ResetPassword from "../Components/ForgotPassword/ResetPassword/ResetPassword";
import { SideNavbar } from "../Components/SideNavbar/SideNavbar";
import Employee from "../Components/Employees/Employee";
import TopNav from "../Components/SideNavbar/TopNav";
import { LayoutContainer, LayoutRoot } from "./EmployeePortalConfig.Styled";
import ViewDetailsPage from "../Components/Employees/ViewDetailspage/ViewDetailsPage";
import Invoice from "../Components/Invoice/Invoice";
import CreateInvoice from "../Components/Invoice/CreateInvoice/CreateInvoice";
import AssetMangement from "../Components/AssetManagment/AssetMangement";
import Payroll from "../Components/Payroll/payroll";
import UserMangement from "../Components/UserManagment/UserMangement";
import Leave from "../Components/Leave/components/Leave";
import HrManagerSkill from "../Components/MySkill/ManagerAndHrViewSkills/HrManagerSkill";
import ChangePassword from "../Components/ForgotPassword/ChangePassword/ChangePassword";
import Dashboard from "../Components/Dashboard/Dashboard";
import ProtectedRoute from "../Common/ProtectedRoute";
import MyAssignment from "../Components/MyAssignment/Components/MyAssignment";
import {
  resetNavigationRoute,
  setNavigationRoute,
} from "../Components/SideNavbar/Store/navigationSlice/navigationSlice";
import {
  TimeOutPopup,
  useAuthentication,
  useNotification,
} from "../Common/CommonUtils";
import {
  resetAuthenticated,
  setIsAuthenticated,
} from "../Common/store/authenticatedSlice/authenticatedSlice";
import { postLogout } from "../Components/Login/LoginApiServices/LoginApi";
import { setIsSideNav } from "../Components/SideNavbar/Store/slideNavbarSlice";
import { resetPasswordEmial } from "../Components/ForgotPassword/ResetPassword/store/resetPasswordSlice";
import {
  resetIsTimeout,
  setIsLogoutTiming,
} from "../Common/store/timeoutSlice/timeoutSlice";
import { resetUserInfo } from "../Common/store/userSlice/userSlice";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const EmployeePortalConfig = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.up("md"));
  const isSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const [isMobileNavbar, setIsMobileNavbar] = useState(false);
  const [activePath, setActivePath] = useState("/dashboard");
  const navigationRoute = useSelector(
    (state) => state.navigation.navigationRoute
  );

  const isSideNav = useSelector((state) => state.sideNav.isSideNav);
  const role = useSelector((state) => state.user?.data?.role);

  const [seconds, setSeconds] = useState(60);
  const authorization = useAuthentication();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const navigate = useNavigate();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const isLogoutTiming = useSelector((state) => state.timeout.isLogoutTiming);
  let inactivityTimer;

  const resetInactivityTimer = () => {
    clearTimeout(inactivityTimer);
    inactivityTimer = setTimeout(() => {
      dispatch(setIsLogoutTiming(true));
    }, 9 * 60 * 1000);
  };
  const handleUserActivity = () => {
    resetInactivityTimer();
  };

  useEffect(() => {
    resetInactivityTimer();
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    return () => {
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    if (isLogoutTiming) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      if (seconds === 0) {
        handleLogout();
        clearInterval(interval);
        setTimeout(() => {
          dispatch(resetIsTimeout());
        }, 2000);
      }
      return () => {
        clearInterval(interval);
      };
    }
  }, [isLogoutTiming, seconds]);

  const handleLogout = async () => {
    try {
      dispatch(setIsLogoutTiming(false));
      await postLogout(authorization, loginUserId);
      dispatch(setIsSideNav(false));
      dispatch(resetPasswordEmial());
      dispatch(setIsAuthenticated(false));
      dispatch(resetNavigationRoute());
      dispatch(resetUserInfo());
      navigate("/");
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
      dispatch(setIsAuthenticated(false));
    }
  };

  const handleActivePath = (path) => {
    // setActivePath(path);
    dispatch(setNavigationRoute(path));
  };

  // useEffect(() => {
  //   if (role === "Accountant") {
  //     setActivePath("/invoice");
  //   } else if (role === "HR") {
  //     setActivePath("/employee");
  //   }
  //   else {
  //     setActivePath("/dashboard");
  //   }
  // }, [role]);

  return (
    <>
      {isSideNav && <TopNav setIsMobileNavbar={setIsMobileNavbar} />}
      <SideNavbar
        open={isSideNav}
        handleActivePath={handleActivePath}
        activePath={navigationRoute}
        isMobileNavbar={isMobileNavbar}
        setIsMobileNavbar={setIsMobileNavbar}
      />
      {isSideNav && (
        <TimeOutPopup
          seconds={seconds}
          setSeconds={setSeconds}
          signOut={handleLogout}
        />
      )}
      <NotificationPopup />
      <LayoutRoot isSideNav={isSideNav} isTab={isTab} isSmall={isSmall}>
        <LayoutContainer>
          <RouterRoutes>
            <Route path="/" element={<Login />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/attendance" element={<Attendance />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/myassignment" element={<MyAssignment />} />
              <Route path="/mySkill" element={<HrManagerSkill />} />
              <Route path="/assetManagement" element={<AssetMangement />} />
              <Route path="/userManagement" element={<UserMangement />} />
              <Route path="/payroll" element={<Payroll />} />
              <Route
                path="/invoice/createInvoice"
                element={<CreateInvoice />}
              />
              <Route path="/changePassword" element={<ChangePassword />} />
              <Route path="/employee" element={<Employee />} />
              <Route path="/invoice" element={<Invoice />} />
              <Route path="/leave" element={<Leave />} />
              <Route
                path="/employee/viewDetailsPage"
                element={<ViewDetailsPage />}
              />
              <Route path="/viewDetailsPage" element={<ViewDetailsPage />} />
            </Route>
          </RouterRoutes>
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
};

export default EmployeePortalConfig;
