import { createSlice } from '@reduxjs/toolkit';
import { _setAssetInfoForm } from './assetInfoAction';

const assetInfoSlice = createSlice({
  name: 'ep/assetInformation',
  initialState: null,
  reducers: {
    setAssetInfoForm: _setAssetInfoForm,
    resetAssetInfoForm: () => null,
  },
});
export const { setAssetInfoForm, resetAssetInfoForm } =
assetInfoSlice.actions;
export default assetInfoSlice.reducer;