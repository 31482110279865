import React from 'react';
import EmployeePortalDialog from '../../Common/EmployeePortalDialog';
import InvoiceListForm from './InvoiceListForm';

const InvoiceListDetails = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen } = props;

  const handleCloseDialog = () => {
    onClose();
  };

  const handleUpdateInvoiceListSubmit = async (data) => {
    const { totalamount, paidAmount, paymentdate } = data;
    const payload = {
      totalamount,
      paidAmount,
      paymentdate,
      amountdue: totalamount - paidAmount,
    };
    console.log(payload, 'payload');
    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, [1000]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Invoice List '}
        customWidth={'450px'}
      >
        <InvoiceListForm
          onSubmit={handleUpdateInvoiceListSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default InvoiceListDetails;
