import { usePermissionCustomHook } from '../../../../../Common/CommonUtils';
import DataGridComponent from '../../../../../Common/DataGridComponent';
import {
  IconDelete,
  RoundedIconEdit,
} from './EducationAndCertification.Styled';

const EducationDataGrid = (props) => {
  const { handleDeleteButton, handleEditButton } = props;
  const isHR = usePermissionCustomHook('HR');

  const employeesEducation = [
    {
      id: 1,
      serialNo: '01',
      degree: 'Degree',
      course: 'MBA',
      universityinstutute: 'Reva University',
      yearOfPassing: '2022',
    },
    {
      id: 2,
      serialNo: '02',
      degree: 'Degree',
      course: 'MCA',
      universityinstutute: 'School',
      yearOfPassing: '2017',
    },
    {
      id: 3,
      serialNo: '03',
      degree: 'Degree',
      course: 'MBA',
      universityinstutute: 'Sri Chaitanya jr. College',
      yearOfPassing: '2015',
    },
  ];

  const columnsData = [
    {
      field: 'serialNo',
      headerName: 'Serial No.',
      width: 120,
    },
    {
      field: 'degree',
      headerName: 'Degree',
      width: 180,
    },
    {
      field: 'course',
      headerName: 'Course',
      flex: 1,
    },
    {
      field: 'universityinstutute',
      headerName: 'University/Institute',
      flex: 1,
    },
    {
      field: 'yearOfPassing',
      headerName: 'Year Of Passing',
      width: 180,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,

      renderCell: (params) => {
        return (
          <>
            <RoundedIconEdit
              variant="outlined"
              onClick={() => handleEditButton(params)}
              color="primary"
              data-testid={`education-edit-icon-${params.row.id}`}
            >
              Education Details
            </RoundedIconEdit>
          </>
        );
      },
    },
    {
      renderCell: (params) => {
        return (
          <>
            {isHR && (
              <IconDelete
                variant="outlined"
                onClick={() => handleDeleteButton(params)}
                color="primary"
                data-testid={`education-delete-icon-${params.row.id}`}
              >
                Delete
              </IconDelete>
            )}
          </>
        );
      },
    },
  ];
  return (
    <DataGridComponent
      autoHeight
      columnsData={columnsData}
      rowData={employeesEducation}
      pageSizeValue={employeesEducation.length}
      height="auto"
      width="100%"
      dataTestId="education-data-grid"
    />
  );
};

export default EducationDataGrid;
