const initialState = {
  isNewCertificateForm: false,
  certificationId: '',
  technologyName: '',
  certificationAuthority: '',
  certifiedDate: null,
  validlastDate: null,
};

export default initialState;
