import { createSlice } from '@reduxjs/toolkit';
import initialState from './invoiceIntialState';
import { _setInvoiceListForm } from './invoiceAction';

export const InvoiceEditListSlice = createSlice({
  name: 'ep/InvoiceListForm',
  initialState,
  reducers: {
    setInvoiceListForm: _setInvoiceListForm,
    resetInvoiceListForm: () => initialState,
  },
});

export const { setInvoiceListForm, resetInvoiceListForm } =
  InvoiceEditListSlice.actions;

export default InvoiceEditListSlice.reducer;
