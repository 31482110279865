import { createSlice } from '@reduxjs/toolkit';
import {
    _setCustomer,
    _setInvoiceNumber,
    _setInvoiceDate,
    _setPaymentDueDate,
    _setDaysString,
    _setDays,
    _setInvoiceRowData,
    _setCurrency,
    _setNote,
} from './invoiceInfoFormAction';
import initialState from './invoiceInfoFormIntialState';


export const invoiceInfoFormSlice = createSlice({
    name: 'ep/InvoiceInfoForm',
    initialState,
    reducers: {
        setCustomer: _setCustomer,
        setInvoiceNumber: _setInvoiceNumber,
        setInvoiceDate: _setInvoiceDate,
        setPaymentDueDate: _setPaymentDueDate,
        setDaysString: _setDaysString,
        setDays: _setDays,
        setInvoiceRowData: _setInvoiceRowData,
        setCurrency: _setCurrency,
        setNote: _setNote,
        resetInvoiceInfoForm: () => initialState,
    },
});
export const {
    setCustomer,
    setInvoiceNumber,
    setInvoiceDate,
    setPaymentDueDate,
    setDaysString,
    setDays,
    setInvoiceRowData,
    setCurrency,
    setNote,
    resetInvoiceInfoForm,
} = invoiceInfoFormSlice.actions;
export default invoiceInfoFormSlice.reducer;