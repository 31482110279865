import { pdfjs } from 'react-pdf';

export const { REACT_APP_FASTAPI_URL, REACT_APP_NODEJS_URL } = process.env;
// export const { REACT_APP_UAT_FASTAPI_URL, REACT_APP_UAT_NODEJS_URL } = process.env;

export const genderOptions = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const nationalityOptions = [
  { value: 'indian', label: 'Indian' },
  { value: 'american', label: 'American' },
];

export const maritalStatusOptions = [
  { value: 'Single', label: 'Single' },
  { value: 'Married', label: 'Married' },
  { value: 'Divorced', label: 'Divorced' },
];

export const statusOptions = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

export const abscondedOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const accountTypeOptions = [
  { value: 'Savings', label: 'Savings' },
  { value: 'Current ', label: 'Current' },
  { value: 'Salary', label: 'Salary' },
];

export const taxPercentageOptions = [
  { value: 'CGST+SGST', label: 'CGST+SGST' },
  { value: 'IGST', label: 'IGST' },
];

export const AmountTypeValues = [
  { value: 'USD ($)', label: 'USD ($)' },
  { value: 'INR (₹) ', label: 'INR (₹) ' },
];

export const CustomerNameOptions = [
  {
    value: 'Victory Capital Management(VCM)',
    label: 'Victory Capital Management(VCM)',
  },
  { value: 'Current ', label: 'Current' },
  { value: 'Salary', label: 'Salary' },
];

export const branchOptions = [
  { value: 'San Antonio, USA', label: 'San Antonio, USA' },
  { value: 'Bangalore, INDIA', label: 'Bangalore, INDIA' },
];

export const designationValues = [
  { value: 'Software Engineer Trainee', label: 'Software Engineer Trainee' },
  { value: 'Software Engineer', label: 'Software Engineer' },
  { value: 'Senior Software  Engineer', label: 'Senior Software  Engineer' },
  { value: 'Testing Engineer Trainee', label: 'Testing Engineer Trainee' },
  { value: 'Testing Engineer', label: 'Testing Engineer' },
  { value: ' Senior Testing Engineer', label: 'Senior Testing Engineer' },
  { value: 'Tech Lead', label: 'Tech Lead' },
  { value: 'Testing Lead', label: 'Testing Lead' },
  { value: 'Manager', label: 'Manager' },
  { value: 'Project Manager', label: 'Project Manager' },
  { value: 'Senior Manager', label: 'Senior Manager' },
  { value: 'Analyst', label: 'Analyst' },
  { value: 'Senior Analyst', label: 'Senior Analyst' },
  { value: 'Architect', label: 'Architect' },
  { value: 'Senior Architect', label: 'Senior Architect' },
  { value: 'Solution Architect', label: 'Solution Architect' },
  { value: 'Scrum Master', label: 'Scrum Master' },
  { value: 'Data Engineer', label: 'Data Engineer' },
];

export const documentNameOptions = [
  { label: 'Aadhaar Card', value: 'Aadhaar Card' },
  { label: 'Passport', value: 'Passport' },
  { label: 'PAN Card', value: 'PAN Card' },
  { label: "Driver's License", value: "Driver's License" },
  { label: 'Resume', value: 'Resume' },
  { label: 'Experience Certificate', value: 'Experience Certificate' },
  { label: 'Form 16', value: 'Form 16' },
  {
    label: 'Bank Statement of 6 months',
    value: 'Bank Statement of 6 months',
  },
  { label: 'Last 3 month pay slip', value: 'Last 3 month pay slip' },
  {
    label: 'Previous company offer letter',
    value: 'Previous company offer letter',
  },
  {
    label: 'Previous company Hike letter',
    value: 'Previous company Hike letter',
  },
  {
    label: 'Bank Details (Cancelled cheque/cheque book front page)',
    value: 'Bank Details (Cancelled cheque/cheque book front page)',
  },
];

export const documentType = [
  { label: 'ID Proof', value: 'ID Proof' },
  { label: 'Career Doc', value: 'Career Doc' },
];

export const notificationHeader = 'Notification';

// export const EMPLOYEE_PORTAL_API_GATEWAY_URL = REACT_APP_UAT_NODEJS_URL;
export const EMPLOYEE_PORTAL_API_GATEWAY_URL = REACT_APP_NODEJS_URL;
export const intialPageSize = 10;
export const BASE_URL = REACT_APP_FASTAPI_URL;
// export const BASE_URL = REACT_APP_UAT_FASTAPI_URL;

export const HynivaAddress = `
  # 8,1st Floor, HBR Layout, 4th Block  
  Outer Ring Road, Hennur 
  Bengaluru, Karnataka - 560043 `;

export const dateFnsFormat = 'MM-dd-yyyy';
export const dateFnsYMDFormat = 'yyyy-MM-dd';

export const requiredField = 'This field is required'

export const PDF_WORKER_SRC = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export const downloadOptions = [
  { label: 'Excel', value: 'excel' },
  { label: 'PDF', value: 'pdf' },
];


export const PaperProps = {
  sx: {
    maxWidth: 'unset',
    width: '613px',
    maxHeight: 'auto',
  },
};