import { api } from "../../../Common/AxoisConfig";

export const postInvoice = async (payload) => {
  await api("FAST_API_URL").post(`invoice`, payload);
};

export const getCustomers = async () => {
  const customers = await api("FAST_API_URL").get(
    `customers/status?is_active=true`
  );
  return customers?.data;
};

export const getCustomer = async (customerId) => {
  const customer = await api("FAST_API_URL").get(`customers/${customerId}`);
  return customer?.data;
};

export const getInvoices = async () => {
  const invoices = await api("FAST_API_URL").get(`invoice`);
  return invoices?.data;
};
