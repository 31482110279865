import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ArrowBackIconStyle,
  BackButtonGrid,
  BackButtonStyle,
} from "./BackButton.styled";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { setIsViewDetailsPage } from "../../Components/Employees/store/EmployeeSlice";
import { usePermissionCustomHook } from "../CommonUtils";

const BackButtonComponent = () => {
  const dispatch = useDispatch();
  const isHR = usePermissionCustomHook("HR");
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
    dispatch(setIsViewDetailsPage(false));
  };

  return (
    <>
      <Grid container>
        {isHR ? (
          <BackButtonGrid item >
            <ArrowBackIconStyle onClick={handleGoBack} />
            <BackButtonStyle variant="h6" onClick={handleGoBack}>
              Back to Employees
            </BackButtonStyle>
          </BackButtonGrid>
        ) : (
          <BackButtonGrid item >
            <ArrowBackIconStyle onClick={handleGoBack} />
            <BackButtonStyle variant="h6" onClick={handleGoBack}>
              Back
            </BackButtonStyle>
          </BackButtonGrid>
        )}
      </Grid>
    </>
  );
};

export default BackButtonComponent;
