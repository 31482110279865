import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Snackbar from "@mui/material/Snackbar";
import {
  CreateButtonStyling,
  EmployeePortalActionDailog,
} from "../../../Common/EmployeePortalDialog.styled";
import { useForm } from "react-hook-form";
import { LeaveStyledGrid } from "../../Leave/components/Leave.styled";
import { FormInputDate } from "../../../Common/FormDatePicker";
import FormDropDown from "../../../Common/FormDropDown";
import { FormTextField } from "../../../Common/FormTextField";
import { useSelector } from "react-redux";
import {
  getFrameWorks,
  postRequestSkill,
  putApproveOrRejectSkill,
} from "../services/mySkillService";
import {
  dropDownOptions,
  formatedZoneDate,
  useAuthentication,
  useNotification,
} from "../../../Common/CommonUtils";
import { format } from "date-fns";
import { dateFnsFormat, requiredField } from "../../../Common/Constant";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetViewSkills } from "../store/viewSkillsSlice/viewSkillsSlice";
import useSnackbar from "../../../Common/CustomHooks/useSnackbar";

const skillSchema = yup.object().shape({
  employeeName: yup.string().required(requiredField),
  category: yup.string().required(requiredField),
  frameWork: yup.string().required(requiredField),
  technology: yup.string().required(requiredField),
  currentLevel: yup.string().required(requiredField),
  targetLevel: yup.string().required(requiredField),
  targetDate: yup.string().required(requiredField),
  remarks: yup.string().required(requiredField),
});
const ViewManagerForm = (props) => {
  const { snackbarOpen, onCloseSnackbar, onClose, viewIsOpen, fetchMySkills } =
    props;

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
    const { showSnackbar, AlertSnackbar } = useSnackbar();

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const viewSkillsForm = useSelector((state) => state?.viewSkillsForm?.data);

  const [technologyValue, setTechnologyValue] = useState(
    viewSkillsForm?.technology
  );
  const [frameWorkOptions, setFrameWorks] = useState(null);

  const isNewRequestSkillForm = useSelector(
    (state) => state?.viewSkillsForm?.isNewRequestSkillForm
  );

  const metaData = useSelector((state) => state?.metaData);

  const { skillLevel, managerEmployees } = metaData || {};

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.name,
      value: item.name,
    })) || [];

  const dropDownEmployeeName = (name) =>
    managerEmployees?.find((item) => item.name === name)?.employeeId;

  const employeeOptionsList = dropDownOptions(managerEmployees);
  const categoryOptionsList = dropDownOptions(metaData.category);
  const skillLevelOptionsList = dropDownOptions(skillLevel);
  const technologyOptionsList = mapToOptionsList(metaData.technology);
  const frameworkOptionsList = dropDownOptions(frameWorkOptions);
  const {
    technology,
    category,
    currentLevel,
    targetLevel,
    targetDate,
    manager,
    status,
    approvedOn,
    comments,
    remarks,
    isActive,
    frameWork,
    employeeId,
    employeeName,
    managerName,
    skillId,
  } = viewSkillsForm || {};

  const technologyName = metaData.technology?.find(
    (item) => item.name === technologyValue
  )?.name;

  const defaultValue = {
    employeeName: employeeName,
    technology: technology,
    category: category,
    frameWork: frameWork,
    status: status,
    approvedOn: approvedOn,
    currentLevel: currentLevel,
    targetLevel: targetLevel,
    targetDate: targetDate ? new Date(formatedZoneDate(targetDate)) : null,
    comments: comments,
    remarks: remarks,
  };

  const { control, register, formState, handleSubmit, resetField } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(skillSchema),
  });

  const { errors, isValid } = formState;

  useEffect(() => {
    if (technologyValue) {
      fetchFrameWork();
    } else {
      setFrameWorks([]);
      resetField("frameWork");
    }
  }, [technologyValue]);
  const fetchFrameWork = async () => {
    try {
      const frameData = await getFrameWorks(technologyName);
      setFrameWorks(frameData);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const onSubmit = async (data, skillApprovedRejected) => {
    const { remarks } = data;
    const payload = {
      status: skillApprovedRejected,
      remarks: remarks,
    };
    try {
      await putApproveOrRejectSkill(
        loginUserId,
        skillId,
        payload
      );
      fetchMySkills();
      onClose();
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  const handleApprovedSkill = async (data) => {
    onSubmit(data, "approved");
  };

  const handleRejectSkill = async (data) => {
    onSubmit(data, "rejected");
  };

  const onSubmitRequestSkill = async (data) => {
    const {
      employeeName,
      category,
      technology,
      frameWork,
      currentLevel,
      targetLevel,
      comments,
      targetDate,
      remarks,
    } = data;

    const payload = {
      userId: Number(loginUserId),
      employeeId: dropDownEmployeeName(employeeName),
      category: category,
      technology: technologyName,
      frameWork: frameWork,
      currentLevel: currentLevel,
      targetLevel: targetLevel,
      comments: comments ? comments : "",
      targetDate: format(new Date(targetDate), dateFnsFormat),
      remarks: remarks,
    };
    if (currentLevel >= targetLevel) {
      setOpenNotification(true);
      setNotificationMessage(
        "Target Level must be greater than Current Level."
      );
      return;
    }
    try {
      await postRequestSkill(payload);
      fetchMySkills();
      onClose();
      showSnackbar("Skill request submitted successfully")
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  return (
    <>
      <LeaveStyledGrid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              label="Employee Name"
              name="employeeName"
              control={control}
              errors={errors?.employeeName}
              helperText={errors?.employeeName?.message}
              options={employeeOptionsList}
              readOnly={!isNewRequestSkillForm}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormDropDown
              name="category"
              label="Category"
              control={control}
              errors={errors?.category}
              helperText={errors?.category?.message}
              options={categoryOptionsList}
              readOnly={!isNewRequestSkillForm}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              label="Technology"
              name="technology"
              control={control}
              errors={errors?.technology}
              helperText={errors?.technology?.message}
              options={technologyOptionsList}
              readOnly={!isNewRequestSkillForm}
              register={register("technology", {
                onChange: (e) => {
                  setTechnologyValue(e.target.value);
                  resetField("frameWork");
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              label="Framework"
              name="frameWork"
              control={control}
              errors={errors?.frameWork}
              helperText={errors?.frameWork?.message}
              options={frameworkOptionsList}
              readOnly={!isNewRequestSkillForm}
              //readOnly
            />
          </Grid>
        </Grid>
        {viewIsOpen && (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormDropDown
                label="Status"
                name="status"
                control={control}
                errors={errors?.status}
                helperText={errors?.status?.message}
                options={frameworkOptionsList}
                readOnly={!isNewRequestSkillForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormDropDown
                label="ApprovedOn"
                name="approvedOn"
                control={control}
                errors={errors?.approvedOn}
                helperText={errors?.approvedOn?.message}
                options={frameworkOptionsList}
                // readOnly
                readOnly={!isNewRequestSkillForm}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormDropDown
              label="Current Level"
              name="currentLevel"
              control={control}
              errors={errors?.currentLevel}
              helperText={errors?.currentLevel?.message}
              options={skillLevelOptionsList}
              readOnly={!isNewRequestSkillForm}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormDropDown
              label="Target Level"
              name="targetLevel"
              control={control}
              errors={errors?.targetLevel}
              helperText={errors?.targetLevel?.message}
              options={skillLevelOptionsList}
              maxDate
              readOnly={!isNewRequestSkillForm}
              //readOnly
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputDate
              label="Target Date"
              name="targetDate"
              control={control}
              minDate={new Date()}
              helperText={errors?.targetDate?.message}
              errors={!!errors.targetDate}
              readOnly={!isNewRequestSkillForm}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {!isNewRequestSkillForm && (
            <Grid item xs={12} sm={6}>
              <FormTextField
                label="Comments"
                name="comments"
                rows={3}
                multiline
                control={control}
                errors={!!errors?.comments}
                helperText={errors?.comments?.message}
                readOnly={!isNewRequestSkillForm}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="Remarks"
              name="remarks"
              rows={3}
              multiline
              control={control}
              errors={!!errors?.remarks}
              helperText={errors?.remarks?.message}
              readOnly={!isNewRequestSkillForm && !(status.toLowerCase() === "pending")}
            />
          </Grid>
        </Grid>
        {isNewRequestSkillForm && (
          <Grid
            container
            style={{ padding: "16px", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              onClick={handleSubmit(onSubmitRequestSkill)}
            >
              Add
            </Button>
          </Grid>
        )}
      </LeaveStyledGrid>
      {status === "pending" && (
        <>
          <Grid
            container
            style={{ padding: "16px", justifyContent: "flex-end" }}
          >
            <Button
              variant="outlined"
              color="error"
              style={{ marginRight: "16px" }}
              onClick={handleSubmit(handleRejectSkill)}
            >
              Rejected
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(handleApprovedSkill)}
            >
              Approved
            </Button>
          </Grid>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Leave Applied Successfully
        </Alert>
      </Snackbar>
      <NotificationPopup />
      <AlertSnackbar />
    </>
  );
};

export default ViewManagerForm;
