import { createSlice } from '@reduxjs/toolkit';
import initialState from './sideNavbarIntialState';
import { _setIsSideNav } from './sideNavbarAction';
export const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    setIsSideNav: _setIsSideNav,
    resetSideNav: () => initialState,
  },
});
export const { setIsSideNav, resetSideNav } = sideNavSlice.actions;
export default sideNavSlice.reducer;
