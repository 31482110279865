import Grid from '@mui/material/Grid';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import {
  Accordionstyling,
  AddButtonGrid,
} from './EducationAndCertification.Styled';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CertificationDataGrid from './CertificationDataGrid';
import { useState } from 'react';
import AddCerificationDetails from './AddCerificationDetails';
import { useDispatch } from 'react-redux';
import {
  resetCertificationForm,
  setCertificationForm,
  setIsNewCertificateForm,
} from './store/certificationFormSlice/certificationSlice';
import DeleteConfirmationDialog from '../../../../../Common/DeleteConfirmationDialog';
import { usePermissionCustomHook } from '../../../../../Common/CommonUtils';

const CertificationAccordion = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const isHR = usePermissionCustomHook('HR');

  const dispatch = useDispatch();

  const handleCertificationAddButton = () => {
    setSnackbarOpen(false);
    setIsDialogOpen(true);
    dispatch(resetCertificationForm());
    dispatch(setIsNewCertificateForm(true));
  };
  const handleCertificationEdit = (params) => {
    setSnackbarOpen(false);
    setIsDialogOpen(true);
    const { row } = params;
    dispatch(setCertificationForm(row));
  };
  const handleCertificationDelete = (params) => {
    setSelectedRow(params.row);
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  return (
    <Grid data-testid="certification-grid">
      <Accordionstyling data-testid="certification-accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          data-testid="certification-accordion-summary"
        >
          <Typography variant="h6">Certification</Typography>
        </AccordionSummary>
        <AddButtonGrid
          container
          data-testid="certification-accordion-add-button-grid"
        >
          <Button
            variant="contained"
            onClick={() => handleCertificationAddButton()}
          >
            Add
          </Button>
        </AddButtonGrid>

        <AccordionDetails data-testid="certification-accordion-details">
          <CertificationDataGrid
            handleCertificationEdit={handleCertificationEdit}
            handleCertificationDelete={handleCertificationDelete}
          />
        </AccordionDetails>
      </Accordionstyling>
      <AddCerificationDetails
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />

      <DeleteConfirmationDialog
        open={deleteConfirmationOpen}
        handleClose={handleCloseDeleteConfirmation}
        handleDelete={handleConfirmDelete}
        message="Are you sure you want to delete this certification record?"
      />
    </Grid>
  );
};
export default CertificationAccordion;
