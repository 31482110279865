import React from 'react';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { EditIconCancelButton, EditIconPencil } from './EditControl.styled';

const EditControls = (props) => {
  const { editable, onCancelClick, onSaveClick, onEditClick, saveDisabled } =
    props;

  return (
    <>
      {editable ? (
        <>
          <EditIconCancelButton
            onClick={onCancelClick}
            color="primary"
            variant="contained"
            startIcon={<CancelIcon />}
          >
            Cancel
          </EditIconCancelButton>
          <Button
            onClick={onSaveClick}
            color="primary"
            variant="contained"
            startIcon={<SaveIcon />}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </>
      ) : (
        <IconButton onClick={onEditClick} color="primary">
          <EditIconPencil />
        </IconButton>
      )}
    </>
  );
};

export default EditControls;
