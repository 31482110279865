import React from 'react';
import MultiSelectFormDropdown from '../../Common/MultiSelectFormDropdown';

const names = [
  'Software Engineer Trainee',
  'Testing Engineer Trainee',
  'Software Engineer ',
  'Senior software  Engineer ',
  'Tech Lead',
  'Manager',
  'Project Manager',
  'Senior Manager',
  'Analyst',
  'Senior Analyst',
  'Architect',
  'Senior Architect',
  'Solution Architect',
  'Scrum Master',
  'Data Engineer',
];

const EmployeeDesignationDropdown = (props) => {
  const { control, register, handleDesignation } = props;

  return (
    <MultiSelectFormDropdown
      name="designation"
      label="Designation"
      options={names}
      control={control}
      register={register('designation', {
        onChange: (e) => {
          handleDesignation(e);
        },
      })}
      m={1}
      // width={230}
    />
  );
};

export default EmployeeDesignationDropdown;
