import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';

import {
  HynivaLogoStyle,
  LoginPageBox,
  LoginPageHeading,
  LoginPageMainGrid,
} from '../../Login/Login.styled';
import HynivaLogo from '../../../Assets/Images/HynivaLogo.png';
import ResetPasswordForm from './ResetPasswordForm';
import {
  ResetPasswordHeadingGrid,
  ResetPasswordLogoGrid,
} from './ResetPassword.styled';
import { useRemoveSideNav } from '../../../Common/CommonUtils';
import { ResetPasswordApi } from '../ForgotPasswordApiServices/ForgotPasswordApi';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setPasswordValue } from '../../Login/Store/loginPageSlice';
import {
  resetPasswordEmial,
  setResetPasswordEmail,
} from './store/resetPasswordSlice';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('You must enter a password')
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must be at most 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  newPassword: yup
    .string()
    .required('You must confirm your new password')
    .oneOf(
      [yup.ref('password'), null],
      'New Password and Confirm password must be the same'
    ),
});

const ResetPassword = ({ id, userId }) => {
  const dispatch = useDispatch();
  useRemoveSideNav();
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState('');
  const [newPasswords, setNewPasswords] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const email = useSelector(
    (state) => state?.resetPasswordEmail?.resetPasswordEmail
  );

  const password = '';
  const newPassword = '';

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const emailParam = params.get('token');
    if (emailParam) {
      dispatch(setResetPasswordEmail(emailParam));
    }
  }, []);

  const defaultValue = {
    password: password,
    newPassword: newPassword,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handlePasswordChange = (e) => {
    setPasswords(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPasswords(e.target.value);
  };

  const handleCancel = () => {
    navigate('/');
  };

  const handlePassWordDetailsSubmit = (data) => {
    const { password, newPassword } = data;
    const payload = {
      token_or_official_email: email,
      new_password: password,
      confirm_password: newPassword,
    };
    ResetPasswordApi(email, payload)
      .then(() => {
        dispatch(setPasswordValue(password));
        setSnackbarMessage('Password has been successfully reset.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/');
        }, 2000);
        dispatch(resetPasswordEmial());
      })
      .catch((error) => {
        const errorMessage = error.response.data.detail;
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <LoginPageMainGrid item xs={12}>
          <LoginPageBox>
            <ResetPasswordLogoGrid>
              <Grid>
                <HynivaLogoStyle src={HynivaLogo} />
              </Grid>
              <ResetPasswordHeadingGrid>
                <LoginPageHeading variant="h4">Reset Password</LoginPageHeading>
              </ResetPasswordHeadingGrid>
            </ResetPasswordLogoGrid>
            <ResetPasswordForm
              onSubmit={handlePassWordDetailsSubmit}
              onCancel={handleCancel}
              snackbarOpen={snackbarOpen}
              onCloseSnackbar={handleCloseSnackbar}
            />
          </LoginPageBox>
        </LoginPageMainGrid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ResetPassword;
