import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfData from './pdfInvoice.json';
import EmployeePortalDialog from '../../../Common/EmployeePortalDialog';
import { HeaderButton } from './CreateInvoice.Styled';
import { Grid } from '@mui/material';
import { PDF_WORKER_SRC } from '../../../Common/Constant';

pdfjs.GlobalWorkerOptions.workerSrc = PDF_WORKER_SRC;

const InvoicePDFDialog = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen, setIsDialogOpen } =
    props;

  const handleContinueAndSend = (data) => {
    onClose();
    setIsDialogOpen(true);
  };

  const handleEditInvoice = (data) => {
    onClose();
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title="Invoice PDF "
        customWidth="700px"
        dataTestId="invoice-pdf-dialog"
      >
        <Grid
          container
          style={{ justifyContent: 'flex-end', padding: '0px 16px 16px 0px' }}
        >
          <HeaderButton variant="contained" onClick={handleEditInvoice}>
            Edit Invoice
          </HeaderButton>

          <HeaderButton variant="contained" onClick={handleContinueAndSend}>
            Continue & Send
          </HeaderButton>
        </Grid>
        <Grid container style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <Document file={`${pdfData.pdfInfo}`}>
            <Page
              pageNumber={1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              renderMode="canvas"
            />
          </Document>
        </Grid>
      </EmployeePortalDialog>
    </>
  );
};

export default InvoicePDFDialog;
