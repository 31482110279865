import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  StyledDialogTitle,
  StyledIconButton,
  StyledDialogContent,
  WarningIconStyled,
  StyledButton,
  StyledDialogActions,
  StyledDilog,
  StyledTypo,
  StyledPara,
} from "./PromptMessage.styled";

const PromptMessage = ({
  open,
  onClose,
  onConfirm,
  title,
  message,
  confirmLabel,
  cancelLabel,
}) => {
  return (
    <StyledDilog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <StyledTypo>{title}</StyledTypo>
        <WarningIconStyled />
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledPara>{message}</StyledPara>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledButton onClick={onClose} variant="outlined" color="primary">
          {cancelLabel}
        </StyledButton>
        <StyledButton onClick={onConfirm} variant="contained">
          {confirmLabel}
        </StyledButton>
      </StyledDialogActions>
    </StyledDilog>
  );
};

export default PromptMessage;
