import DataGridComponent from '../../../../../Common/DataGridComponent';
import {
  formatDateTime,
  usePermissionCustomHook,
} from '../../../../../Common/CommonUtils';
import {
  IconDelete,
  RoundedIconEdit,
} from './EducationAndCertification.Styled';

const CertificationDataGrid = (props) => {
  const { handleCertificationEdit, handleCertificationDelete } = props;

  const isHR = usePermissionCustomHook('HR');

  const employeeCertification = [
    {
      id: 1,
      serialNo: '01',
      certificationId: '12323',
      technologyName: 'React',
      certificationAuthority: 'Reva University',
      certifiedDate: formatDateTime('05/01/2021'),
      validlastDate: formatDateTime('08/01/2025'),
    },
    {
      id: 2,
      serialNo: '02',
      certificationId: '43343',
      technologyName: 'Java',
      certificationAuthority: 'J spiders',
      certifiedDate: formatDateTime('05/01/2021'),
      validlastDate: formatDateTime('06/01/2024'),
    },
    {
      id: 3,
      serialNo: '03',
      certificationId: '3484',
      technologyName: 'PHP',
      certificationAuthority: 'YV institutions',
      certifiedDate: formatDateTime('05/01/2021'),
      validlastDate: formatDateTime('12/01/2027'),
    },
  ];

  const columnsData = [
    {
      field: 'serialNo',
      headerName: 'Serial No.',
      width: 120,
    },
    {
      field: 'certificationId',
      headerName: 'Certification ID',
      width: 140,
    },
    {
      field: 'technologyName',
      headerName: 'Technology Name',
      width: 180,
    },
    {
      field: 'certificationAuthority',
      headerName: 'Certification Authority',
      flex: 1,
    },
    {
      field: 'certifiedDate',
      headerName: 'Certified Date',
      width: 160,
    },
    {
      field: 'validlastDate',
      headerName: 'Valid Last Date',
      width: 180,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,

      renderCell: (params) => {
        return (
          <>
            <RoundedIconEdit
              variant="outlined"
              onClick={() => handleCertificationEdit(params)}
              color="primary"
              data-testid={`certification-edit-icon-${params.row.id}`}
            >
              Certification Details
            </RoundedIconEdit>
          </>
        );
      },
    },
    {
      renderCell: (params) => {
        return (
          <>
            {isHR && (
              <IconDelete
                variant="outlined"
                onClick={() => handleCertificationDelete(params)}
                color="primary"
                data-testid={`certification-delete-icon-${params.row.id}`}
              >
                Delete Certification
              </IconDelete>
            )}
          </>
        );
      },
    },
  ];

  return (
    <DataGridComponent
      autoHeight
      columnsData={columnsData}
      rowData={employeeCertification}
      pageSizeValue={employeeCertification.length}
      // height="auto"
      // width="100%"
      dataTestId="certification-data-grid"
    />
  );
};

export default CertificationDataGrid;
