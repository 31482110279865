import { createSlice } from '@reduxjs/toolkit';
import initialState from './employeeIdIntialState';
import { _setEmployeeId, _setEmployeeRole } from './employeeIdAction';

export const employeeIdSlice = createSlice({
  name: 'ep/employeeId',
  initialState,
  reducers: {
    setEmployeeId: _setEmployeeId,
    setEmployeeRole: _setEmployeeRole,
    resetEmployeeId: () => initialState,
  },
});
export const { setEmployeeId, setEmployeeRole, resetEmployeeId } =
  employeeIdSlice.actions;
export default employeeIdSlice.reducer;
