import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../../Common/TabPanel/TabPanel";
import {
  StickyTab,
  TabContainer,
  ViewDetailsPageeducationTab,
  ViewDetailsPagepersonalInfoTab,
} from "../../../Employees/ViewDetailspage/ViewDetailsPage.styled";
import LeaveDataGrid from "../../components/LeaveDataGrid";
import ManagerViewDataGrid from "./ManagerViewDataGrid";
import { Grid } from "@mui/material";

const ManagerViewTabs = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    <Grid style={{marginTop: '16px'}}></Grid>
      <TabContainer>
        <StickyTab
          value={value}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          <ViewDetailsPagepersonalInfoTab label="My Leaves" {...a11yProps(0)} />
          <ViewDetailsPageeducationTab label="Team Leaves" {...a11yProps(1)} />
        </StickyTab>
      </TabContainer>
      <TabPanel value={value} index={0}>
        <LeaveDataGrid />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManagerViewDataGrid />
      </TabPanel>
    </>
  );
};

export default ManagerViewTabs;
