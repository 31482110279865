export const _setCustomer = (state, action) => {
    state.customer = action.payload;
};
export const _setInvoiceNumber = (state, action) => {
    state.invoiceNumber = action.payload;
};
export const _setInvoiceDate = (state, action) => {
    state.invoiceDate = action.payload;
};
export const _setPaymentDueDate = (state, action) => {
    state.paymentDueDate = action.payload;
};
export const _setDaysString = (state, action) => {
    state.daysString = action.payload;
};
export const _setDays = (state, action) => {
    state.days = action.payload;
};
export const _setInvoiceRowData = (state, action) => {
    state.invoiceRowData = action.payload;
};
export const _setCurrency = (state, action) => {
    state.currency = action.payload;
};
export const _setNote = (state, action) => {
    state.note = action.payload;
};