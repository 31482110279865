import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { items } from "./SideNavbarConfig";
import { SideNavbarItem } from "./SideNavbarItem";
import HynivaLogo from "../../Assets/Images/HynivaLogo.png";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import {
  HynivaNavLogoStyle,
  LogoContainer,
  NavigationContainer,
  SideGridNav,
  SideNavGrid,
} from "./SideNavbar.Styled";
import { usePermissionCustomHook } from "../../Common/CommonUtils";
import { SvgIcon } from "@mui/material";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export const SideNavbar = (props) => {
  const {
    open,
    activePath,
    handleActivePath,
    isMobileNavbar,
    setIsMobileNavbar,
  } = props;
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up("md"));
  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");
  const isAccountant = usePermissionCustomHook("Accountant");
  const hideInvoice = isHR || isDeveloper || isManager;

  let filteredItems = items;

  if (hideInvoice) {
    filteredItems = items.filter((item) => item.title !== "Invoice");
  } else if (isAccountant) {
    filteredItems = items.filter((item) => item.title === "Invoice");
  }

  filteredItems = filteredItems.filter((item) => {
    if (item.title === "Employees") {
      return isHR;
    }
    return true;
  });

  const content = (
    <SideGridNav container direction="column">
      <LogoContainer item>
        <HynivaNavLogoStyle src={HynivaLogo} />
      </LogoContainer>
      <NavigationContainer item flexGrow={1}>
        <SideNavGrid component="ul" spacing={0.5}>
          {filteredItems.map((item) => {
            const active = item.path ? activePath === item.path : false;

            console.log(isHR, "isHR");
            return (
              <SideNavbarItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
                handleActivePath={handleActivePath}
                setIsMobileNavbar={setIsMobileNavbar}
              />
            );
          })}
        </SideNavGrid>
      </NavigationContainer>
    </SideGridNav>
  );

  const handleCloseNavebar = () => {
    setIsMobileNavbar(false);
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={isMobileNavbar && !isWeb}
        onClose={handleCloseNavebar}
        PaperProps={{
          sx: {
            // backgroundColor: '#f0f0f0',
            backgroundColor: "common.white",
            // color: 'common.white',
            color: "#345196",
            width: 250,
            overflowY: "unset",
          },
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
      <Drawer
        anchor="left"
        open={open && isWeb}
        PaperProps={{
          sx: {
            // backgroundColor: '#f0f0f0',
            backgroundColor: "common.white",
            // color: 'common.white',
            color: "#345196",
            width: 250,
            overflowY: "unset",
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </>
  );
};
