import React from "react";
import AssignmentInformation from "../../Employees/ViewDetailspage/ViewDetailsPageTab/EmployeeInformation/AssignmentInformation";
import  Grid  from "@mui/material/Grid";
import { useSideNav } from "../../../Common/CommonUtils";

const MyAssignment = () => {
  useSideNav();

  return (
    <Grid container>
      <AssignmentInformation />
    </Grid>
  );
};

export default MyAssignment;
