import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditControls from '../../../../../Common/EditControl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditButtonGrid,
  SalaryAndBankDetailsHeading,
  SalaryAndBankDetailsMainGrid,
} from './SalaryAndBankDetails.styled';
import PFDetailsFormField from './PFDetailsFormField';
import { ViewDtailsCard } from '../../ViewDetailsPage.styled';

const schema = yup.object().shape({
  uanNumber: yup
    .string()
    .required('UAN Number is required')
    .matches(/^[0-9]+$/, 'UAN Number should only contain numbers')
    .nullable(),

  pfNumber: yup
    .string()
    .required('PF Number is required')
    .matches(/^[0-9]+$/, 'PF Number should only contain numbers')
    .nullable(),

  esiNumber: yup
    .string()
    .required('ESI Number is required')
    .matches(/^[0-9]+$/, 'ESI Number should only contain numbers')
    .nullable(),

  pfJoiningDate: yup.date().required('PF Joining Date is required'),
  esiJoingingDate: yup.date().required('ESI Joining Date is required'),

  esiLeavingDate: yup
    .mixed()
    .required('ESI Leaving Date is required')
    .test('valid-date', 'Please provide a valid date', function (value) {
      if (!value || value === '') return true;
      return !isNaN(Date.parse(value));
    })
    .test(
      'after-joining-date',
      'ESI Leaving Date must be after joining date',
      function (value, { parent }) {
        const joiningDate = parent.esiJoingingDate;
        if (!joiningDate) return true;
        return !value || new Date(value) > new Date(joiningDate);
      }
    ),
});

const PFDetails = () => {
  const initialFormData = {
    uanNumber: '990672692206',
    pfNumber: '66827128379817',
    esiNumber: '92252030909',
    pfJoiningDate: new Date('01/01/2022'),
    esiJoingingDate: new Date('01/01/2022'),
    esiLeavingDate: new Date('01/01/2022'),
    createdDateTime: '02/10/2021',
    updatedDateTime: '02/10/2021',
  };

  const defaultValue = {
    uanNumber: initialFormData.uanNumber,
    pfNumber: initialFormData.pfNumber,
    esiNumber: initialFormData.esiNumber,
    pfJoiningDate: initialFormData.pfJoiningDate,
    esiJoingingDate: initialFormData.esiJoingingDate,
    esiLeavingDate: initialFormData.esiLeavingDate,
    createdDateTime: initialFormData.createdDateTime,
    updatedDateTime: initialFormData.updatedDateTime,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(initialFormData);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    setEditable(false);
    reset(initialFormData);
    setFormChanged(false);
  };

  const handleSaveClick = () => {
    setEditable(false);
    setInitialFormValues(formData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard>
      <SalaryAndBankDetailsMainGrid container item xs={12}>
        <Grid item xs={6}>
          <SalaryAndBankDetailsHeading variant="h6">
            PF/ESI Details
          </SalaryAndBankDetailsHeading>
        </Grid>
        <EditButtonGrid item xs={6}>
          <EditControls
            editable={editable}
            onCancelClick={handleCancelClick}
            onSaveClick={handleSaveClick}
            onEditClick={handleEditClick}
            saveDisabled={!formChanged || isErrorField}
          />
        </EditButtonGrid>
      </SalaryAndBankDetailsMainGrid>
      <PFDetailsFormField
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        register={register}
        handleChange={handleChange}
        editable={editable}
      />
    </ViewDtailsCard>
  );
};

export default PFDetails;
