import React from "react";
import {
  DoughnutContainer,
  Title,
  DoughnutWrapper,
  LeaveTotal,
  DoughnutLeave,
  StyledList,
  StyledListItem,
  ListItemTextContainer,
} from "./Leave.styled";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import  Grid from "@mui/material/Grid";


ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const { label, value, total , sickLeave ,annualLeave } = props;

  const generateChartData = (label, value, total) => ({
    labels: [label, "Remaining"],
    datasets: [
      {
        data: [value, total - value],
        backgroundColor: ["#345196", "rgba(201, 203, 207, 0.3)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(201, 203, 207, 1)"],
        borderWidth: 1,
        cutout: "85%",
      },
    ],
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };


  const LeaveList = () => {
    return (
      <StyledList>
      <StyledListItem color="#284495">
        <ListItemTextContainer primary={`Annual Leave - ${annualLeave ? annualLeave : 0}`} />
      </StyledListItem>
      <StyledListItem color="#ED1C24">
        <ListItemTextContainer primary={`Loss of Pay - 0`}  />
      </StyledListItem>
      <StyledListItem color="#D9D9D9">
        <ListItemTextContainer primary={`Sick Leave - ${sickLeave ? sickLeave : 0}`}  />
      </StyledListItem>
    </StyledList>
    );
  };

  return (
    <DoughnutLeave>
      <DoughnutWrapper>
        <Doughnut
          data={generateChartData(label, value, total)}
          options={options}
        />
        <DoughnutContainer>
          <Title variant="h5">{label}</Title>
          <LeaveTotal variant="h2">{value}</LeaveTotal>
        </DoughnutContainer>
      </DoughnutWrapper>
      <Grid>
        <LeaveList />
      </Grid>
    </DoughnutLeave>
  );
};

export default DoughnutChart;
