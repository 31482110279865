import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const TextForgotPasswordGrid = styled(Grid)`
  display: flex;
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 4}px 0px ${
      props.theme.palette.spacing[1] * 2
    }px ${props.theme.palette.spacing[1] * 10}px`};
`;

export const LoginTypography = styled(Typography)`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;
export const ForgotPasswordGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;

export const SendInstructionButton = styled(Button)`
  width: 160px;
`;

export const CancelButton = styled(Button)`
  width: 150px;
`;

export const ForgotPasswordstylingForm = styled('form')`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
`;
