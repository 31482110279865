import React from 'react';
import { useSideNav } from '../../Common/CommonUtils';

const AssetMangement = () => {
  useSideNav();
  return (
    <div>
      <p>Employee Assets</p>
    </div>
  );
};

export default AssetMangement;
