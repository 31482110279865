import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const SalaryAndBankDetailsGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const SalaryAndBankDetailsMainGrid = styled(Grid)`
  display: flex;
`;

export const SalaryAndBankDetailsHeading = styled(Typography)`
  display: flex;
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const EditButtonGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const SalaryAndBankDetailsTextFieldGrid = styled(Grid)`
  padding-top: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
  padding-bottom: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const TextFieldGrid = styled(Grid)`
  display: flex;
  gap: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;
