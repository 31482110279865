import React from "react";
import { Controller } from "react-hook-form";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormHelperText, TextField } from "@mui/material";
import {
  DateFormHelperText,
  DatePickeDesktop,
} from "./FormInputDatePicker.styled";

export const FormInputDate = (props) => {
  const {
    name,
    control,
    label,
    register,
    errors,
    helperText,
    minDate,
    maxDate,
    readOnly,
    disabled,
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Controller
        name={name}
        control={control}
        register={register}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <DatePickeDesktop
            primary={errors}
            label={label}
            disabled={disabled}
            inputFormat="MM/DD/YYYY"
            value={value === "" ? null : value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            readOnly={readOnly}
            disableFocus={readOnly}
            renderInput={(params) => (
              <TextField
                variant="standard"
                error={errors}
                helperText={helperText}
                style={{ width: "100%", marginBottom: "20px" }}
                {...params}
              />
            )}
          />
        )}
      />
      {errors && <DateFormHelperText>{helperText}</DateFormHelperText>}
    </LocalizationProvider>
  );
};
