import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIsSideNav } from "./Store/slideNavbarSlice";
import { resetPasswordEmial } from "../ForgotPassword/ResetPassword/store/resetPasswordSlice";
import { postLogout } from "../Login/LoginApiServices/LoginApi";
import {
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
} from "../../Common/CommonUtils";
import { setIsAuthenticated } from "../../Common/store/authenticatedSlice/authenticatedSlice";
import { resetNavigationRoute } from "./Store/navigationSlice/navigationSlice";
import { resetUserInfo } from "../../Common/store/userSlice/userSlice";
import { resetEmployeeId } from "../Employees/store/employeeIdSlice/employeeIdSlice";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;

  const firstName = useSelector((state) => state.user?.data?.firstName);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const isHR = usePermissionCustomHook("HR");
  const authorization = useAuthentication();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const handleLogout = async () => {
    try {
      await postLogout(authorization, loginUserId);
      dispatch(setIsSideNav(false));
      dispatch(resetPasswordEmial());
      dispatch(setIsAuthenticated(false));
      dispatch(resetNavigationRoute());
      dispatch(resetUserInfo());
      dispatch(resetEmployeeId());
      navigate("/");
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message || error.response.data.detail);
      dispatch(setIsAuthenticated(false));
    }
  };

  const handleChangePassword = async () => {
    navigate("/changePassword");
  };

  const handleMyProfile = async () => {
    navigate("/viewDetailsPage");
    onClose();
  };

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 200 } }}
      >
        <Box
          sx={{
            py: 1.5,
            px: 2,
          }}
        >
          <Typography variant="overline">Account</Typography>
          <Typography color="text.secondary" variant="body2">
            {firstName}
          </Typography>
        </Box>
        <Divider />

        {!isHR && (
          <MenuList
            disablePadding
            dense
            sx={{
              p: "8px",
              "& > *": {
                borderRadius: 1,
              },
            }}
          >
            <MenuItem onClick={handleMyProfile}>My Profile</MenuItem>
          </MenuList>
        )}
        <MenuList
          disablePadding
          dense
          sx={{
            p: "8px",
            "& > *": {
              borderRadius: 1,
            },
          }}
        >
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        </MenuList>
        <MenuList
          disablePadding
          dense
          sx={{
            p: "8px",
            "& > *": {
              borderRadius: 1,
            },
          }}
        >
          <MenuItem onClick={handleLogout}>Sign out</MenuItem>
        </MenuList>
      </Popover>
      <NotificationPopup />
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
