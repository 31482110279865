import React, { useState, useEffect } from "react";
import { LogContainer } from "./LogAttendance.styled";
import DataGridComponent from "../../../Common/DataGridComponent";
import LogAttendence from "./LogAttendance";
import LogAttendanceDailog from "./LogAttendanceDailog";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { isSameMonth, parse } from "date-fns";
import { useNotification } from "../../../Common/CommonUtils";
import { useSelector, useDispatch } from "react-redux";
import {
  StatusAbsent,
  StatusPresent,
  StatusContainer,
  StyledIcon,
  StyledText,
} from "./LogAttendance.styled";
import { getAttendanceSummary } from "../services/Services";
import { formatAttendanceData } from "./LogAttendanceDatagridUtils";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import CancelIcon from "@mui/icons-material/Cancel";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
const LogAttendanceDataGrid = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [filteredData, setFilteredData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [newData, setNewData] = useState([]);

  const statusStyles = {
    approved: {
      color: "green",
      Icon: TaskAltIcon,
    },
    pending: {
      color: "orange",
      Icon: HourglassEmptyIcon,
    },
    rejected: {
      color: "red",
      Icon: CancelIcon,
    },
    default: {
      color: "",
      Icon: null,
    },
  };
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const getCurrentMonthYear = () => {
    const currentDate = new Date();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${month}-${year}`;
  };

  const monthYear = getCurrentMonthYear();

  const fetchedData = async () => {
    try {
      const data = await getAttendanceSummary(loginUserId, monthYear);
      if (!data.attendanceHistory || data.attendanceHistory.length === 0) {
        setNewData([]);
        setFilteredData([]);
      } else {
        const formattedData = formatAttendanceData(data.attendanceHistory);

        setNewData(formattedData);
        setFilteredData(formattedData);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setOpenNotification(true);
        setNotificationMessage(
          error.response?.data?.message ||
            "An error occurred while fetching attendance-data."
        );
      }

      setNewData([]);
    }
  };

  useEffect(() => {
    fetchedData();
  }, [monthYear]);

  const capitalizeFirstLetter = (status) =>
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  const monthMap = (() => {
    const today = new Date();
    return Array.from({ length: 3 }, (_, i) => {
      const monthDate = new Date(today.getFullYear(), today.getMonth() - i);
      return {
        name: monthDate.toLocaleString("default", { month: "long" }),
        date: monthDate,
      };
    });
  })();

  const filterAttendanceData = (data, month) => {
    const selectedMonthDate = new Date(month);
    return data.filter((row) => {
      const attendanceDate = new Date(row.date);
      return isSameMonth(attendanceDate, selectedMonthDate);
    });
  };

  const handleSelectMonth = (month) => {
    const selectedMonth = monthMap.find((m) => m.name === month)?.date;
    if (selectedMonth) {
      setCurrentMonth(selectedMonth);
      const filtered = filterAttendanceData(newData, selectedMonth);
      setFilteredData(filtered);
    }
  };

  const handleEditAttendance = (params) => {
    const {
      checkInTime,
      checkOutTime,
      workingHours,
      date,
      remarks,
      dayStatus,
      reason,
      status,
      attendanceId,
    } = params.row;
    const data = {
      checkInTime,
      checkOutTime,
      workingHours,
      remarks,
      reason,
      dayStatus,
      date,
      status,
      attendanceId,
    };

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };
  const handleApplyAttendance = (params) => {
    const data = params.row;

    setSelectedAttendance(data);
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setSelectedAttendance(null);
    setIsDialogOpen(false);
  };
  const colsData = [
    { field: "date", headerName: "Date", width: 150 },
    {
      field: " dayStatus",
      headerName: "Day Status",
      width: 150,
      renderCell: (params) => {
        const { dayStatus } = params.row;
        const formattedDayStatus = capitalizeFirstLetter(dayStatus);
        return formattedDayStatus === "Present" ? (
          <StatusPresent>{formattedDayStatus}</StatusPresent>
        ) : (
          <StatusAbsent>{formattedDayStatus}</StatusAbsent>
        );
      },
    },
    { field: "checkIn", headerName: "Check In", width: 120 },
    { field: "checkOut", headerName: "Check Out", width: 120 },
    { field: "workingHours", headerName: "Hours Worked", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const status = params.value ? params.value.toLowerCase() : null;
        const { color, Icon } = statusStyles[status] || statusStyles.default;

        return (
          <StatusContainer>
            {Icon && (
              <StyledIcon color={color}>
                <Icon />
              </StyledIcon>
            )}
            <StyledText color={color}>{params.value || ""}</StyledText>
          </StatusContainer>
        );
      },
    },
    { field: "reason", headerName: "Reason", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 120,
      align: "center",
      getActions: (params) => {
        const { dayStatus } = params.row;
        return [
          <GridActionsCellItem
            label="Edit"
            onClick={() => handleEditAttendance(params)}
            showInMenu
            disabled={dayStatus !== "Present"}
          />,
          <GridActionsCellItem
            label="Apply Attendance"
            onClick={() => handleApplyAttendance(params)}
            showInMenu
            disabled={dayStatus !== "Absent" && dayStatus !== ""}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <LogContainer>
        <LogAttendence
          currentMonth={currentMonth}
          onSelectMonth={handleSelectMonth}
        />
        <DataGridComponent
          autoHeight
          columnsData={colsData}
          rowData={filteredData}
          pageSizeValue={10}
          editable={true}
        />

        {isDialogOpen && selectedAttendance && (
          <LogAttendanceDailog
            attendanceData={selectedAttendance}
            onClose={handleDialogClose}
            onOpen={isDialogOpen}
          />
        )}
      </LogContainer>
      <NotificationPopup />
    </>
  );
};

export default LogAttendanceDataGrid;
