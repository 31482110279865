import { CardContent, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

export const StyledCardContentLeft = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.values.lg}px) and (max-width: ${(props) => props.theme.breakpoints.values.xl}px){
    padding : 8px 8px;
    height : 220px;
  }
`;

export const TimeContainer = styled("div")`
  display: flex;
  align-items: baseline;
`;

export const CheckInCheckOutTime = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const CheckInCheckOutTimeContainer = styled(CheckInCheckOutTime)`
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
`;

export const StyledCardContentRight = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const StyledFormattedTime = styled(Typography)`
  color: theme?.palette.warning.black,
  fontWeight: "bold",
  font-size: 12px;
`;

export const StyledSecondsAmPm = styled(Typography)`
  fontSize: 12,
   margin-left: "0.25rem" 
`;

export const checkOutStyled = styled(Typography)`
  color: "black", 
  fontWeight: "bold"
`;

export const DateMonthYearStyled = styled(Typography)`
   display: "flex",
   alignItems: "center",
   fontSize: 13 
`;

export const StyledLoginIcon = styled(LogoutIcon)`
  font-size: 55px;
  margin-left: 1px;
  color: white;
`;

export const StyledLogOutIcon = styled(LoginIcon)`
  font-size: 55px;
  margin-left: 1px;
  color: white;
`;

export const StyledCheckInOutText = styled(Typography)`
  color: white;
  font-weight: bold;
`;

export const StyledCheckInText = styled(Typography)`
  display: inline; 
  font-size: 12px;
`;

export const StyledCheckInTimeText = styled(Typography)`
  color: black;
  font-weight: bold;
  display: inline;
  font-size: 14px;
`;

export const StyledCheckOutTimeText = styled(Box)`
 display: "flex", 
 alignItems: "center", 
 marginTop: 1
`;

export const StyledCheckOutIconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.palette.info.blue};
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const MonthDayYearText = styled(Typography)`
  font-size: 12px;
`;


export const AttendanceOverview = styled(Link)`
  color: #0000b3;
  cursor: 'pointer',
`;

export const AttendanceOverviewContainer = styled(Box)`
  display: flex;
  justify-content: flex-end; 
  margin-top: auto;
`;

