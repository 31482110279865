import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

export const LeaveTotal = styled(Typography)`
  font-family: Roboto;
  font-size: 26px;
  font-weight: bold;
  margin: 4px 0 0 0;
`;

export const Title = styled(Typography)`
  font-size: 19px;
  font-family: Roboto;
  margin: 0;
`;

export const DoughnutContainer = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DoughnutWrapper = styled(Grid)`
  position: relative;
  width: 100px;
  height: 100px;
  align-self: center;
  @media (min-width: ${(props) =>
      props.theme.breakpoints.values.lg}px) and (max-width: ${(props) =>
      props.theme.breakpoints.values.xl}px) {
    width: 90px;
    height: 90px;
    margin-left: 5px;
  }
`;

export const DoughnutChartContainer = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "10px 0px",
  },

  [`@media (min-width: ${theme.breakpoints.values.lg}px) and (max-width: ${theme.breakpoints.values.xl}px)`]:
    {
      padding: "1px 0px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
}));

export const DoughnutChartContent = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 5px;
  width: 100px;
`;

export const DoughnutChartLeaveType = styled(Typography)`
  color: gray;
  font-size: 12px;
  font-family: "Roboto";
`;

export const LeaveChartContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
  width: 100%;
`;

export const MuiCard = styled(Card)`
  && {
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.05),
      0px 0px 0px 0.5px rgba(0, 0, 0, 0.03);
  }
  padding: ${(props) => `${props.theme.spacing(2)}`};
  border-radius: 8px;
`;

export const LeaveChartCard = styled(MuiCard)`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1px 0px;
  align-items: center;
  justify-content: space-between;
`;

export const LeaveChartCircle = styled(Grid)(({ theme }) => ({
  height: "100%",
  display: "flex",
  justifyContent: "space-around",
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "10px 10px",
  },
}));

export const DividerContainer = styled(Divider)`
  background-color: gray;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e1ea;
  margin: ${(props) => `${props.theme.palette.spacing[1]}px`};
`;

export const LeaveOverview = styled(Link)`
  text-align: center;
  color: #0000b3;
  cursor: "pointer";
  font-size: 17px;
  margin-bottom: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    text-align: right;
    display: flex;
    justify-content: end;
    color: #000000;
  }
`;

export const ApplyLink = styled(Button)`
  cursor: pointer;
  color: #0000b3;
`;

export const DashboardContainer = styled(Grid)`
  padding: 0px 24px 0px 0px;
  height: 35vh;
`;
