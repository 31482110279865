import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SvgIcon from '@mui/material/SvgIcon';
 
export const FormInputSearch = (props) => {
  const { searchQuery, setSearchQuery,placeholder , fullWidth} = props;
 
  return (
    <OutlinedInput
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      placeholder={placeholder}
      sx={{
        height: { xs: '56px', sm: '58px', md: '60px' },
        marginBottom : "14px",
        alignSelf : "center",
      }}
      fullWidth = {fullWidth ? true : false}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      }
    />
  );
};
