import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  EditButtonGrid,
  SalaryAndBankDetailsHeading,
  SalaryAndBankDetailsMainGrid,
} from './SalaryAndBankDetails.styled';
import EditControls from '../../../../../Common/EditControl';
import FullFinalSettlementFormField from './FullFinalSettlementFormField';
import { ViewDtailsCard } from '../../ViewDetailsPage.styled';

const schema = yup.object().shape({
  unPaidSalary: yup
    .string()
    .required('Unpaid salary is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Unpaid salary should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  bonus: yup
    .string()
    .required('Bonus is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Bonus should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  variablePay: yup
    .string()
    .required('Variable Pay is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Variable Pay should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  accumulatedLeaves: yup
    .string()
    .required('Accumulated Leaves is required.')
    .matches(/^[0-9]+$/, 'Un Availed Leaves should only contain numbers.')
    .nullable(),

  leaveEnCashment: yup
    .string()
    .required('Leave Encashment is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Leave Encashment should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  gratuity: yup
    .string()
    .required('Gratuity is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Gratuity should be a valid amount (e.g., 662.00).'
    )
    .nullable(),

  paidLeaves: yup
    .string()
    .required('Paid Leaves is required.')
    .matches(/^[0-9]+$/, 'Paid Leaves should only contain numbers.')
    .nullable(),

  professionalTax: yup
    .string()
    .required('Professional Tax is required.')
    .matches(
      /^\d+(\.\d{1,2})?$/,
      'Professional Tax should be a valid amount (e.g., 662.00).'
    )
    .nullable(),
});

const FullFinalSettlement = () => {
  const initialFormData = {
    unPaidSalary: '8678.00',
    bonus: '2000',
    variablePay: '1789',
    accumulatedLeaves: '10',
    leaveEnCashment: '1200',
    gratuity: '17689',
    paidLeaves: '2',
    professionalTax: '1600',
    createdDateTime: '02/10/2021',
    updatedDateTime: '02/10/2021',
  };

  const defaultValue = {
    unPaidSalary: initialFormData.unPaidSalary,
    bonus: initialFormData.bonus,
    variablePay: initialFormData.variablePay,
    accumulatedLeaves: initialFormData.accumulatedLeaves,
    leaveEnCashment: initialFormData.leaveEnCashment,
    gratuity: initialFormData.gratuity,
    paidLeaves: initialFormData.paidLeaves,
    professionalTax: initialFormData.professionalTax,
    createdDateTime: initialFormData.createdDateTime,
    updatedDateTime: initialFormData.updatedDateTime,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(initialFormData);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(initialFormData);
    setEditable(false);
    setFormChanged(false);
  };

  const handleSaveClick = () => {
    setEditable(false);
    setInitialFormValues(formData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard>
      <SalaryAndBankDetailsMainGrid container item xs={12}>
        <Grid item xs={6}>
          <SalaryAndBankDetailsHeading variant="h6">
            Full Final Settlement
          </SalaryAndBankDetailsHeading>
        </Grid>
        <EditButtonGrid item xs={6}>
          <EditControls
            editable={editable}
            onCancelClick={handleCancelClick}
            onSaveClick={handleSubmit(handleSaveClick)}
            onEditClick={handleEditClick}
            saveDisabled={!formChanged || isErrorField}
          />
        </EditButtonGrid>
      </SalaryAndBankDetailsMainGrid>
      <FullFinalSettlementFormField
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        register={register}
        handleChange={handleChange}
        editable={editable}
      />
    </ViewDtailsCard>
  );
};

export default FullFinalSettlement;
