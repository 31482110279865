import React from 'react';
import MultiSelectFormDropdown from '../../Common/MultiSelectFormDropdown';

const BranchNames = ['Bangalore - India', 'San Antonio - USA'];

const EmployeeBranchDropdown = (props) => {
  const { control, register, handleBranch } = props;

  return (
    <MultiSelectFormDropdown
      name="branch"
      label="Select Branch"
      options={BranchNames}
      control={control}
      register={register('branch', {
        onChange: (e) => {
          handleBranch(e);
        },
      })}
      m={1}
      // width={230}
    />
  );
};

export default EmployeeBranchDropdown;
