import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditControls from '../../../../../Common/EditControl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  EditButtonGrid,
  SalaryAndBankDetailsHeading,
  SalaryAndBankDetailsMainGrid,
} from './SalaryAndBankDetails.styled';
import BankDetailsFormField from './BankDetailsFormField';
import { ViewDtailsCard } from '../../ViewDetailsPage.styled';

const schema = yup.object().shape({
  bankName: yup
    .string()
    .required('Bank Name is required')
    .matches(
      /^[A-Za-z\s]+$/,
      'Bank Name should only contain letters and spaces'
    )
    .nullable(),
  bankAddress: yup
    .string()
    .required('Bank Address is required')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()-_+=<>?,./;:'"|])[A-Za-z0-9\s!@#$%^&*()-_+=<>?,./;:'"|]*$/,
      'Bank Address should contain at least one letter and one special character, and may also include numbers.'
    )
    .nullable(),

  accountHolderName: yup
    .string()
    .required('Account Holder Name is required')
    .matches(
      /^[A-Za-z\s]+$/,
      'Account Holder Name should only contain letters and spaces'
    )
    .nullable(),
  bankAccountNumber: yup
    .string()
    .required('Bank Account Number  is required')
    .matches(
      /^[0-9]{5,15}$/,
      'Bank account number must be between 5 and 15 digits'
    ),
  ifscCode: yup
    .string()
    .required('IFSC code is required')
    .matches(
      /^[A-Z]{4}[0][A-Z0-9]{6}$/,
      'IFSC code must be in the format XXXX0YYYYYY'
    )
    .nullable(),
});

const BankDetails = () => {
  const initialFormData = {
    bankName: 'Axis Bank',
    bankAddress: 'Hut Chins Road',
    accountHolderName: 'Ajay Shah',
    bankAccountNumber: '92252030909',
    ifscCode: 'ICIC0000001',
    accountType: 'Savings',
  };

  const defaultValue = {
    bankName: initialFormData.bankName,
    bankAddress: initialFormData.bankAddress,
    accountHolderName: initialFormData.accountHolderName,
    bankAccountNumber: initialFormData.bankAccountNumber,
    ifscCode: initialFormData.ifscCode,
    accountType: initialFormData.accountType,
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(initialFormData);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(initialFormData);
    setEditable(false);
    setFormChanged(false);
  };

  const handleSaveClick = () => {
    setEditable(false);
    setInitialFormValues(formData);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard>
      <SalaryAndBankDetailsMainGrid container item xs={12}>
        <Grid item xs={6}>
          <SalaryAndBankDetailsHeading variant="h6">
            Bank Details
          </SalaryAndBankDetailsHeading>
        </Grid>
        <EditButtonGrid item xs={6}>
          <EditControls
            editable={editable}
            onCancelClick={handleCancelClick}
            onSaveClick={handleSubmit(handleSaveClick)}
            onEditClick={handleEditClick}
            saveDisabled={!formChanged || isErrorField}
          />
        </EditButtonGrid>
      </SalaryAndBankDetailsMainGrid>
      <BankDetailsFormField
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        register={register}
        handleChange={handleChange}
        editable={editable}
      />
    </ViewDtailsCard>
  );
};

export default BankDetails;
