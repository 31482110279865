import React from 'react';
import EmployeePortalDialog from '../../../../../Common/EmployeePortalDialog';
import PerformanceAddRowForm from './PerformanceAddRowForm';

const PerformanceAddRowDialog = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen } = props;

  const handleCloseDialog = () => {
    onClose();
  };

  const handleCreateRowFormSubmit = async (data) => {
    const { parameters } = data;
    const payload = {
      parameters,
    };
    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, [1000]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Performance Form '}
        customWidth={'450px'}
        dataTestId="performanceForm"
      >
        <PerformanceAddRowForm
          onSubmit={handleCreateRowFormSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default PerformanceAddRowDialog;
