import DataGridComponent from "../../../Common/DataGridComponent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../../Common/CommonUtils";
import {
  DataMainGrid,
  StyledGridContainer,
} from "../../Employees/Employee.Styled";
import { intialPageSize } from "../../../Common/Constant";
import { useForm } from "react-hook-form";

import { getLeaveDataGridUtils } from "./LeaveDataGridUtils";
import {
  getLeaveDetails,
  getLeaves,
  putCancelLeave,
} from "../services/LeaveApi";
import { useDispatch, useSelector } from "react-redux";
import {
  AddButtonGrid,
  RoundedIconEdit,
} from "../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled";
import ApplyLeave from "./ApplyLeave";
import { MuiCard } from "../../../Common/Common.styled";
import {
  setApplyLeaveForm,
  setIsMyLeave,
  setIsNewApplyLeaveForm,
} from "../store/applyLeavesSlice/applyLeaveSlice";
import { Box } from "@mui/system";
import { useTheme } from "@emotion/react";
import LensIcon from "@mui/icons-material/Lens";
import { ApplyLeaveButton } from "./Leave.styled";
import { useMediaQuery } from "@mui/material";
const LeaveDataGrid = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [popUpActions, setPopUpActions] = useState("");
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const [leaveDetails, setLeaveDeatils] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });
  const { page } = leaveDetails;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const isHR = usePermissionCustomHook("HR");
  const isManager = usePermissionCustomHook("Manager");

  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  // const isXsToMd = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("sm"));

  const useFunction = useForm({
    mode: "onChange",
    // defaultValues: defaultValue,
  });

  const columnsData = [
    {
      field: "applied_date_time",
      headerName: "Request Date",
      width: 120,
    },
    {
      field: "request_type",
      headerName: "Leave Type",
      width: 150,
    },
    {
      field: "from_date",
      headerName: "From Date ",
      width: 120,
    },
    {
      field: "to_date",
      headerName: " To Date",
      width: 120,
    },
    {
      field: "total_days_leave_applied",
      headerName: "Days",
      width: 80,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 100,
    },
    {
      field: "request_status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LensIcon
            sx={{
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              marginLeft: "8px",
              color:
                params.value.toLowerCase() === "approved"
                  ? "green"
                  : params.value.toLowerCase() === "pending" ||
                    params.value.toLowerCase() === "pending by manager"
                  ? "orange"
                  : "red",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "commment",
      headerName: "Comments",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 150,
      align: "center",
      getActions: (params) => {
        const { request_status } = params.row;
        const actionsButtons =
          request_status.toLowerCase() === "pending" ||
          request_status.toLowerCase() === "pending by manager"
            ? "Edit"
            : "View";
        const actions = [];
        actions.push(
          <GridActionsCellItem
            label={actionsButtons}
            onClick={() => handleEditLeave(params, actionsButtons)}
            showInMenu
          />
        );
        !(
          request_status.toLowerCase() === "cancelled" ||
          request_status.toLowerCase() === "rejected"
        ) &&
          actions.push(
            <GridActionsCellItem
              label={"Cancel"}
              onClick={() => handleCancelLeave(params, "cancel")}
              showInMenu
            />
          );
        return actions;
      },
    },
  ];

  const handleEditLeave = async (params, action = "") => {
    const { row } = params;
    try {
      dispatch(setIsMyLeave(true));
      const leaveDetails = await getLeaveDetails(row.applied_leave_id);
      dispatch(setApplyLeaveForm(leaveDetails));
      if (
        row.request_status === "Pending" ||
        row.request_status === "Pending By Manager" ||
        action === "cancel"
      ) {
        dispatch(setIsMyLeave(true));
      }
      setIsDialogOpen(true);

      setPopUpActions(
        action === "Edit" ? "Edit" : action === "cancel" ? "Cancel" : "View"
      );
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };


  const handleApplyNewLeave = () => {
    dispatch(setIsNewApplyLeaveForm(true));
    dispatch(setIsMyLeave(true));
    setIsDialogOpen(true);
  };

  const handleCancelLeave = async (params, action) => {
    handleEditLeave(params, action);
    setPopUpActions("Cancel");
  };

  const handleApplyLeaveCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    fetchLeavesData();
  }, []);

  const fetchLeavesData = async () => {
    try {
      setLeaveDeatils({ ...leaveDetails, isLoading: true });

      const leavesData = await getLeaves(loginUserId);

      const leavesListData = getLeaveDataGridUtils(leavesData?.leave_history);
      setLeaveDeatils((prev) => ({
        ...prev,
        rows: leavesListData,
        isLoading: false,
      }));
    } catch (error) {
      setLeaveDeatils({ ...leaveDetails, isLoading: false });
    }
  };

  return (
    <>
      <AddButtonGrid
        container
        data-testid="certification-accordion-add-button-grid"
      >
        <ApplyLeaveButton
          variant="contained"
          onClick={() => handleApplyNewLeave()}
        >
          Apply Leave
        </ApplyLeaveButton>
      </AddButtonGrid>
      <ApplyLeave
        onClose={handleApplyLeaveCloseDialog}
        onOpen={isDialogOpen}
        popUpActions={popUpActions}
        fetchLeavesData={fetchLeavesData}
      />
      <DataMainGrid>
        <MuiCard>
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={leaveDetails.rows}
            pageSizeValue={leaveDetails.limit}
            // height="auto"
            // width="auto"
            editable={true}
          />
        </MuiCard>
      </DataMainGrid>
      <NotificationPopup />
    </>
  );
};
export default LeaveDataGrid;
