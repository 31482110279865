import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import SvgIcon from '@mui/material/SvgIcon';

export const CompaniesSearch = () => (
  <OutlinedInput
    defaultValue=""
    placeholder="Search Employee"
    startAdornment={
      <InputAdornment position="start">
        <SvgIcon color="action" fontSize="small">
          <SearchIcon />
        </SvgIcon>
      </InputAdornment>
    }
  />
);
