import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { TextField } from "@mui/material";
export const LogContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 634px;
  margin-top: 10px;
  overflow-x: hidden;
`;

export const LogHeader = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin: 5px 0px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
  display: flex;  
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap:20px;
  margin-bottom : 20px;
  }
`;

export const HeadButton = styled(Button)(
  ({ theme, selected, firstButton }) => ({
    width: "90px",
    backgroundColor: selected ? "#284495" : "transparent",
    color: selected ? "#fff" : theme.palette.text.primary,
    border: `1px solid ${selected ? "#284495" : theme.palette.text.primary}`,
    textAlign: "center",
    padding: "7px",
    fontSize: "16px",
    borderRadius: firstButton ? "4px 0 0 4px" : "0",
    "&:hover": {
      color: "white",
      backgroundColor: "#284495",
    },
  })
);

export const HeadTypo = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 5px;
`;

export const ButtonGrid = styled(Grid)`
  display: flex;
  width: 269px;
  align-items: center;
  height: 44px;
  margin-right: 15px;
`;

export const Container = styled(Grid)`
  padding: 0px 16px;
`;

export const StyledContainer = styled(Box)`
  width: 500px;
`;
export const CreateButtonStyling = styled(Button)`
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export const StyledGrid = styled(Grid)`
  padding: 0px 18px;

  @media (max-width: 600px) {
    padding: 0px 10px;
  }
`;

export const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 3px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledFormBox = styled(Box)`
  width: 100%;

  @media (min-width: 600px) {
    width: 48%;
  }
`;

export const WrapperInput = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputTypo = styled(Typography)`
  font-weight: 500;
  font-size: 13px;
  margin-left: 5px;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const TextArea = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;

  textarea {
    resize: none;
  }
`;

export const ButtonBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 13px;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const MonthFetchAttendence = styled(Grid)`
  display: flex;
`;

export const DownloadAttendence = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const StatusPresent = styled(Box)`
  color: green;
  text-align: center;
  color: #00c24e;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
export const StatusAbsent = styled(Box)`
  color: green;
  text-align: center;
  color: #ff4b4b;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
export const FormTypo = styled(Typography)`
  font-size: 15px;
  font-weight: 400;
  margin-left: 5px;
  margin-top: -15px;
`;
export const StatusContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Box)`
  font-size: 10px; // Set the desired font size here
  margin-right: 8px;
  color: ${({ color }) => color};
  text-align: center;
`;

export const StyledText = styled(Typography)`
  color: ${({ color }) => color};
  text-align: center;
`;
export const RequestTypo = styled(Typography)`
  font-size: 23px;
  padding: 30px 5px;
  font-weight: 400;
`;
