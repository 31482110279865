import React from "react";
import LogAttendanceForm from "./LogAttendanceForm";
import EmployeePortalDialog from "../../../Common/EmployeePortalDialog";
import { useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const LogAttendanceDialog = (props) => {
  const { onClose, onOpen, attendanceData, isHR, isEdit, fetchedData } = props;
  const dispatch = useDispatch();
  const handleCloseDialog = () => {
    onClose();
  };
  const dialogTitle = isHR
    ? "Request Attendance"
    : attendanceData.dayStatus === "Present"
    ? "Edit Attendance"
    : "Apply Attendance";

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <EmployeePortalDialog
          open={onOpen}
          onClose={handleCloseDialog}
          title={dialogTitle}
          customWidth={"520px"}
        >
          <LogAttendanceForm
            onCancel={onClose}
            attendanceData={attendanceData}
            isHR={isHR}
            isEdit={isEdit}
            fetchedData={fetchedData}
          />
        </EmployeePortalDialog>
      </LocalizationProvider>
    </>
  );
};

export default LogAttendanceDialog;
