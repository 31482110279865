import React, { useEffect, useState } from 'react';
import { getHolidays } from '../services/LeaveApi';
import { useNotification } from '../../../Common/CommonUtils';
import { MuiCard } from '../../../Common/Common.styled';
import { Grid, ListItemText } from '@mui/material';
import { StyledHolidayListContainer, StyledHolidayListItem, HolidayTitle } from './Leave.styled';


const HolidayList = () => {
  const { setOpenNotification, setNotificationMessage, NotificationPopup } = useNotification();

  const [holidayDetails, setHolidayDeatils] = useState({
    isLoading: false,
    rows: [],
  });

  useEffect(() => {
    fetchHolidayData();
  }, []);

  const fetchHolidayData = async () => {
    try {
      setHolidayDeatils({ ...holidayDetails, isLoading: true });
      const holidays = await getHolidays(new Date().getFullYear());
      setHolidayDeatils({ rows: holidays, isLoading: false });
    } catch (error) {
      setHolidayDeatils({ ...holidayDetails, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  return (
    <>
      <MuiCard>
        <HolidayTitle variant="h6">Holiday List</HolidayTitle>
        <StyledHolidayListContainer dense>
          {holidayDetails.rows.map((holiday) => (
            <StyledHolidayListItem key={holiday.holiday_id}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <ListItemText primary={holiday.day} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={holiday.date} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={holiday.holiday_name} />
                </Grid>
                <Grid item xs={3}>
                  <ListItemText primary={holiday.description} />
                </Grid>
              </Grid>
            </StyledHolidayListItem>
          ))}
        </StyledHolidayListContainer>
      </MuiCard>
      <NotificationPopup />
    </>
  );
};

export default HolidayList;
