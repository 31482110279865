import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

export const Loader = styled(Box)`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSpinnerProgress = styled(CircularProgress)`
  color: #345196;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -11px;
  margin-left: -12px;
`;

export const ScrollBarWrapper = styled(Box)`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const GridForm = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[0] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px `};
`;

export const ButtonWrapper = styled(Grid)`
  justify-content: end;
  padding: 0px 16px 16px 0px;
`;
export const StyledButton = styled(Button)`
  height: 40px;
  width: 80px;
  margin-top: 10px;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
`;
