import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { disableFocusStyles } from './Common.styled';

export const FieldText = styled(TextField)`
  && {
    & .MuiFilledInput-root.Mui-focused {
      ${(props) => props.disableFocus && disableFocusStyles};
    }
  }
`;
