import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const ArrowBackIconStyle = styled(ArrowBackIcon)`
  cursor: pointer;
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  font-size: 20px;
`;

export const BackButtonGrid = styled(Grid)`
  display: flex;
  align-self: center;
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};
`;

export const BackButtonStyle = styled(Typography)`
  cursor: pointer;
  margin-left: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};
`;
