import { createSlice } from '@reduxjs/toolkit';
import initialState from './resetPasswordIntialState';
import { _setResetPasswordEmail } from './resetPasswordAction';



export const resetPasswordSlice = createSlice({
    name: 'ep/employeeId',
    initialState,
    reducers: {
        setResetPasswordEmail: _setResetPasswordEmail,
        resetPasswordEmial: () => initialState,
    },
});
export const { setResetPasswordEmail, resetPasswordEmial } =
    resetPasswordSlice.actions;
export default resetPasswordSlice.reducer;
