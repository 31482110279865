import React from 'react';
import CertificationAccordion from './CertificationAccordion';
import EducationAccordion from './EducationAccordion';

const EducationAndCertificationDetails = () => {
  return (
    <>
      <EducationAccordion />
      <CertificationAccordion />
    </>
  );
};

export default EducationAndCertificationDetails;
