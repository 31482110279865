import { createSlice } from '@reduxjs/toolkit';
import { _setIsLogoutTiming } from './timeoutAction';
import initialState from './timeoutInitialState';

 
export const timeoutSlice = createSlice({
  name: 'ep/timeout',
  initialState,
  reducers: {
    setIsLogoutTiming: _setIsLogoutTiming,
    resetIsTimeout: () => initialState,
  },
});
 
export const { setIsLogoutTiming, resetIsTimeout } = timeoutSlice.actions;
 
export default timeoutSlice.reducer;