import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { FormTextField } from '../../../Common/FormTextField';
import { Controller } from 'react-hook-form';
import {
  AmountTypeValues,
  taxPercentageOptions,
} from '../../../Common/Constant';
import FormDropDown from '../../../Common/FormDropDown';
import {
  AddDiscountButton,
  AddLineItemButton,
  AddRow,
  AddeItemTableHeadingGrid,
  ContactGrid,
  ControlPointIconStyle,
  ControlPointStyle,
  CreateInvoiceHorizontalLine,
  GridEnd,
  GridRow,
  NoteFieldGrid,
  TaxTypography,
  TotalFieldGrid,
  TotalTypography,
} from './CreateInvoice.Styled';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import { IconDelete } from '../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled';
import {
  setCurrency,
  setNote,
} from '../store/invoiceInfoFormSlice/invoiceInfoFormSlice';

const CreateInvoiceDataGrid = (props) => {
  const {
    control,
    register,
    fields,
    append,
    remove,
    setValue,
    getValues,
    subTotalAmount,
  } = props;

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showNoteFields, setNoteShowFields] = useState(false);

  const handleAddRowClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddNoteClick = () => {
    setNoteShowFields(true);
  };

  const handleItemSelect = (selectedItem) => {
    append({ item_name: selectedItem });
    setAnchorEl(null);
  };

  const handleTaxChange = (index, value) => {
    setValue(`items[${index}].tax_type_percentage`, value);
  };

  const handleQuantityChange = (index, quantity) => {
    const priceValue = getValues(`items[${index}].price`);
    setValue(`items[${index}].quantity`, quantity);
    setValue(`items[${index}].amount`, quantity * priceValue);
  };

  const handlePriceChange = (index, price) => {
    const quantityValue = getValues(`items[${index}].quantity`);
    setValue(`items[${index}].price`, price);
    setValue(`items[${index}].amount`, quantityValue * price);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        data-testId="invoice-tax-menu"
      >
        <MenuItem
          onClick={() => handleItemSelect('Professional Services')}
          data-testId="invoice-professional-services"
        >
          Professional Services
        </MenuItem>
        <MenuItem
          onClick={() => handleItemSelect('Rent')}
          data-testId="invoice-rent"
        >
          Rent
        </MenuItem>
        <MenuItem
          onClick={() => handleItemSelect('Travel Imbursement')}
          data-testId="invoice-travel-imbursement"
        >
          Travel Imbursement
        </MenuItem>
      </Menu>

      <AddeItemTableHeadingGrid
        container
        spacing={1}
        data-testId="invoice-add-items-table"
      >
        <Grid item xs={12} sm={2} data-testId="invoice-item-grid">
          <Typography data-testId="invoice-item">Item</Typography>
        </Grid>
        <Grid item xs={12} sm={3} data-testId="invoice-description-grid">
          <Typography data-testId="invoice-description">Description</Typography>
        </Grid>
        <Grid item xs={12} sm={1.7} data-testId="invoice-quantity-grid">
          <Typography data-testId="invoice-quantity">Quantity</Typography>
        </Grid>
        <Grid item xs={12} sm={1.7} data-testId="invoice-price-grid">
          <Typography data-testId="invoice-price">Price</Typography>
        </Grid>
        <Grid item xs={12} sm={1.7} data-testId="invoice-amount-grid">
          <Typography data-testId="invoice-amount">Amount</Typography>
        </Grid>
        <Grid item xs={12} sm={1.7} data-testId="invoice-action-grid">
          <Typography data-testId="invoice-action">Action</Typography>
        </Grid>
      </AddeItemTableHeadingGrid>
      {fields.map((field, index) => (
        <GridRow
          container
          spacing={2}
          key={field.id}
          data-testId={`invoice-row-grid-${field.id}`}
        >
          <Grid
            item
            xs={12}
            sm={2}
            data-testId={`invoice-item-grid-${field.id}-item_name`}
          >
            <Controller
              name={`items[${index}].item_name`}
              control={control}
              defaultValue={field.item_name || ''}
              data-testId={`invoice-controller-${field.id}-item_name`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Item"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            data-testId={`invoice-item-grid-${field.id}-description`}
          >
            <Controller
              name={`items[${index}].description`}
              control={control}
              defaultValue={field.description || ''}
              data-testId={`invoice-controller-${field.id}-description`}
              render={({ field }) => (
                <TextField {...field} label="Description" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1.7}
            data-testId={`invoice-item-grid-${field.id}-quantity`}
          >
            <Controller
              name={`items[${index}].quantity`}
              control={control}
              defaultValue={field.quantity || ''}
              data-testId={`invoice-controller-${field.id}-quantity`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Quantity"
                  type="number"
                  onChange={(e) => {
                    handleQuantityChange(index, e.target.value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1.7}
            data-testId={`invoice-item-grid-${field.id}-price`}
          >
            <Controller
              name={`items[${index}].price`}
              control={control}
              defaultValue={field.price || ''}
              data-testId={`invoice-controller-${field.id}-price`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Price"
                  type="number"
                  onChange={(e) => {
                    handlePriceChange(index, e.target.value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1.7}
            data-testId={`invoice-item-grid-${field.id}-amount`}
          >
            <Controller
              name={`items[${index}].amount`}
              control={control}
              defaultValue={field.amount || ''}
              data-testId={`invoice-controller-${field.id}-amount`}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Amount"
                  type="number"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={1.7}
            data-testId={`invoice-delete-grid-item-${field.id}`}
          >
            <IconButton
              onClick={() => remove(index)}
              data-testId={`invoice-delete-icon-${field.id}`}
            >
              <IconDelete />
            </IconButton>
          </Grid>
        </GridRow>
      ))}

      <AddRow data-testId="invoice-add-line-grid">
        <AddLineItemButton
          onClick={handleAddRowClick}
          data-testId="invoice-add-line-button"
        >
          <ControlPointIconStyle /> Add Line Item
        </AddLineItemButton>
      </AddRow>

      <CreateInvoiceHorizontalLine />

      <GridRow container spacing={2} data-testId="invoice-total-grid">
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={3}></Grid>
        <GridEnd item xs={12} sm={2.5} data-testId="invoice-total-grid-end">
          <Typography>Subtotal</Typography>
          <AddDiscountButton data-testId="invoice-discount-button">
            <ControlPointStyle />
            Add a Discount
          </AddDiscountButton>
          <TotalFieldGrid container data-testId="invoice-tax-grid-field">
            <Grid item xs={12} sm={6} data-testId="invoice-tax-text-item">
              <TotalTypography>Tax :</TotalTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              data-testId=" invoice-currency-dropdown-item"
            >
              <FormDropDown
                name="gstType"
                label="GST Type"
                control={control}
                options={taxPercentageOptions}
             
              />
            </Grid>
          </TotalFieldGrid>
          <TotalFieldGrid container data-testId="invoice-total-grid-field">
            <Grid item xs={12} sm={6} data-testId="invoice-total-text-item">
              <TotalTypography>Total :</TotalTypography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              data-testId=" invoice-currency-dropdown-item"
            >
              <FormDropDown
                name="currency"
                label="Currency"
                control={control}
                options={AmountTypeValues}
                register={register('currency', {
                  onChange: (e) => dispatch(setCurrency(e.target.value)),
                })}
              />
            </Grid>
          </TotalFieldGrid>
        </GridEnd>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={1.7} data-testId="invoice-total-grid-item">
          <Typography>{subTotalAmount}</Typography>
        </Grid>
        <Grid item xs={12} sm={1.7}></Grid>
      </GridRow>
      <CreateInvoiceHorizontalLine />
      <GridRow container data-testId="invoice-add-note-grid">
        <Grid item xs={12} data-testId="invoice-add-note-grid">
          <Button onClick={handleAddNoteClick}>Add Note</Button>
        </Grid>
        <NoteFieldGrid item xs={6} data-testId="invoice-add-note-field-item">
          {showNoteFields && (
            <FormTextField
              label="Note (optional)"
              name="note"
              type="text"
              control={control}
              register={register('note', {
                onChange: (e) => dispatch(setNote(e.target.value)),
              })}
            />
          )}
        </NoteFieldGrid>
      </GridRow>
      <ContactGrid container data-testId="invoice-contact-grid">
        <Typography>
          If you have any questions concerning this invoice, please contact
          [Jyoshna Gandikota]|[jyoshna.gandikota@hyniva.com]
        </Typography>
      </ContactGrid>
    </>
  );
};

export default CreateInvoiceDataGrid;
