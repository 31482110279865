import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { ViewDtailsCard } from '../../Employees/ViewDetailspage/ViewDetailsPage.styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import { IconDelete } from '../../Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/EducationAndCertification.Styled';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import { EmployeePortalActionDailog } from '../../../Common/EmployeePortalDialog.styled';

export const CreateInvoiceHeadingCard = styled(ViewDtailsCard)`
  height: fit-content;
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[1] * 3
    }px ${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
  padding: 0px;
`;

export const CreateInvoiceHorizontalLine = styled('hr')`
  border-color: lightgrey;
  border: 1px solid lightgrey;
`;

export const AddeItemTableHeadingGrid = styled(Grid)`
  border: 2px solid lightgrey;
  border-left-color: white;
  border-right-color: white;
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 2}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
  padding-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  padding-left: 26px;
  background: rgba(240, 243, 248, 0.38);
`;

export const AddLineItemButton = styled(Button)`
  font-weight: bold;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const ControlPointIconStyle = styled(ControlPointIcon)`
  font-size: 16px;
  font-weight: bold;
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const AddDiscountButton = styled(Button)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * -1}px`};
`;

export const ControlPointStyle = styled(ControlPointIcon)`
  font-size: 16px;
  font-weight: bold;
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 0.5}px`};
  cursor: pointer;
`;

export const HeaderSectionGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  padding-right: ${(props) => `${props.theme.palette.spacing[1] * 12}px`};
  padding-left: ${(props) => `${props.theme.palette.spacing[1] * 9}px`};
`;

export const HeadingTypography = styled(Typography)`
  font-weight: bold;
`;

export const HeaderButtonGrid = styled(Grid)`
  display: flex;
`;

export const HeaderButton = styled(Button)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const LeftSectionMainGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * -7}px`};
`;

export const LeftSectionMainBox = styled(Box)`
  height: 180px;
  width: 280px;
  border: 1px solid lightgrey;
  margin-left: 40px;
  display: flex;
  ${(props) =>
    props.customerdetails ? `align-items: flex-start` : `align-items: center`};
  ${(props) => (props.customerdetails ? '' : `justify-content: center`)};
  flex-direction: column;
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const BoxHeading = styled(Typography)`
  color: #345196;
`;

export const BoxButtonGrid = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
  margin-top: -${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const BoxButtonStyle = styled(Button)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 6}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const EditIconStyle = styled(EditIcon)`
  font-size: 16px;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const RightSectionMainGrid = styled(Grid)`
  display: flex;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const InviceNumberText = styled(Typography)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  width: 140px;
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  font-size: 0.93rem;
`;

export const InvoiceDateGrid = styled(Grid)`
  display: flex;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const InviceDateText = styled(Typography)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  width: 140px;
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 4.5}px`};
  font-size: 0.93rem;
`;

export const DateFieldGrid = styled(Grid)`
  width: 160px;
`;

export const RadioButtonsGrid = styled(Grid)`
  display: flex;
  justifycontent: center;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const EmployeePortalinvoiceDailog = styled(EmployeePortalActionDailog)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * -1}px`};
`;

export const SaveAndDraftButton = styled(Button)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const GridCenter = styled(Grid)`
  justify-content: center;
`;
export const GridRow = styled(Grid)`
  padding-left: 26px;
  padding-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;
export const AddRow = styled(Box)`
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const GridEnd = styled(Grid)`
  text-align: end;
`;
export const TotalTypography = styled(Typography)`
  text-align: center;
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1.5}px`};
`;
export const TaxTypography = styled(Typography)`
  text-align: end;
  padding-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const TotaField = styled(Grid)`
  align-items: center;
`;
export const TotalFieldGrid = styled(Grid)`
  align-items: center;
`;
export const NoteFieldGrid = styled(Grid)`
  padding-left: ${(props) => `${props.theme.palette.spacing[1] * 1.5}px`};
`;
export const ContactGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 0
    }px ${props.theme.palette.spacing[1] * 3}px 40px`};
`;
export const GridInvoiceSection = styled(Grid)`
  padding: 24px 40px 24px 24px;
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const InvoiceTypography = styled(Typography)`
  font-weight: bold;
  color: #345196;
  padding-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const GridCompanyDetails = styled(Grid)`
  flex-direction: column;
`;
export const SendEmailGrid = styled(Grid)`
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
  width: auto;
`;
