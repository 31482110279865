import { createSlice } from '@reduxjs/toolkit';
import { _setPersonalInformationForm } from './personalInformationAction';

const personalInformationSlice = createSlice({
  name: 'ep/personalInformation',
  initialState: null,
  reducers: {
    setPersonalInformationForm: _setPersonalInformationForm,
    resetPersonalInformationForm: () => null,
  },
});
export const { setPersonalInformationForm, resetPersonalInformationForm } =
  personalInformationSlice.actions;
export default personalInformationSlice.reducer;
