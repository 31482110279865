import { createSlice } from '@reduxjs/toolkit';
import { _setIsLoading } from './loaderAction';
import initialState from './loaderInitialState';

export const loaderSlice = createSlice({
  name: 'ep/loader',
  initialState,
  reducers: {
    setIsLoading: _setIsLoading,
    resetIsLoading: () => initialState,
  },
});

export const { setIsLoading, resetIsLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
