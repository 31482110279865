export const _setEducationForm = (state, action) => action.payload;

export const _setIsNewEducationForm = (state, action) => {
  state.isNewEducationForm = action.payload;
};

export const _setDegree = (state, action) => {
  state.degree = action.payload;
};
export const _setCourse = (state, action) => {
  state.course = action.payload;
};
export const _setUniversityInstutute = (state, action) => {
  state.universityinstutute = action.payload;
};
export const _setYearOfPassing = (state, action) => {
  state.yearOfPassing = action.payload;
};
