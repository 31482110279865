import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { notificationHeader } from './Constant';
import {
  ButtonStyling,
  ContentText,
  HeaderText,
  NotificationDialogContent,
} from './NotificationDialog.Styled';

function NotificationDialog(props) {
  const { message, openPopUp, setNotificationMessage, setOpenNotification } =
    props;
  const handleOk = () => {
    setOpenNotification(false);
    setNotificationMessage('');
  };
  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xs'} open={openPopUp}>
        <DialogTitle>
          <HeaderText variant="h6">{notificationHeader}</HeaderText>
        </DialogTitle>
        <NotificationDialogContent>
          <ContentText variant="h6" id="alert-dialog-description">
            {message}
          </ContentText>
        </NotificationDialogContent>
        <DialogActions>
          <ButtonStyling variant="text" onClick={handleOk}>
            Ok
          </ButtonStyling>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default NotificationDialog;
