import { createSlice } from "@reduxjs/toolkit";
import initialState from "./viewSkillInitialState";
import { _setViewSkills, _setIsNewRequestSkillForm } from "./viewSkillsAction";

export const viewSkillsSlice = createSlice({
  name: "ep/viewSkillsSlice",
  initialState,
  reducers: {
    setViewSkills: _setViewSkills,
    setIsNewRequestSkillForm: _setIsNewRequestSkillForm,
    resetViewSkills: () => initialState,
  },
});

export const { setViewSkills, setIsNewRequestSkillForm, resetViewSkills } =
  viewSkillsSlice.actions;

export default viewSkillsSlice.reducer;
