import React from 'react';
import { Controller } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const MultiFormTextField = (props) => {
  const { fields, label, control, append, remove, errors } = props;
  return (
    <>
      {fields.map((field, index) => (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
          key={field.id}
        >
          <Controller
            name={`fields[${index}].value`}
            control={control}
            defaultValue={field.value}
            render={({ field }) => (
              <TextField
                {...field}
                label={label}
                style={{ width: '100%', marginLeft: '16px' }}
                variant="outlined"
                error={!!errors?.fields?.[index]?.value}
                helperText={errors?.fields?.[index]?.value?.message}
                data-testId={`fields[${index}].value`}
              />
            )}
          />
          {index === 0 ? (
            <IconButton
              onClick={() => append({ value: '' })}
              style={{ marginBottom: '10px' }}
            >
              <AddCircleOutlineOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => remove(index)}
              style={{ marginBottom: '10px' }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
        </Grid>
      ))}
    </>
  );
};
