import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";

import {
  HynivaLogoStyle,
  LoginPageBox,
  LoginPageHeading,
  LoginPageMainGrid,
} from "../../Login/Login.styled";
import HynivaLogo from "../../../Assets/Images/HynivaLogo.png";

import {
  useAuthentication,
  useNotification,
  useRemoveSideNav,
} from "../../../Common/CommonUtils";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";

import {
  ResetPasswordHeadingGrid,
  ResetPasswordLogoGrid,
} from "../ResetPassword/ResetPassword.styled";
import { setResetPasswordEmail } from "../ResetPassword/store/resetPasswordSlice";
import ChangePasswordForm from "./ChangePasswordForm";
import { postChangePasswordApi } from "../../Login/LoginApiServices/LoginApi";

const ChangePassword = () => {
  useRemoveSideNav();
  const navigate = useNavigate();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);

  const handleCancel = () => {
    navigate(-1);
  };
  const handlePassWordDetailsSubmit = async (data) => {
    const { current_password, new_password, confirm_new_password } = data;
    const payload = {
      current_password,
      new_password,
      confirm_new_password,
    };

    try {
      await postChangePasswordApi(loginUserId, payload);
      navigate(-1);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <LoginPageMainGrid item xs={12}>
          <LoginPageBox>
            <ResetPasswordLogoGrid>
              <Grid>
                <HynivaLogoStyle src={HynivaLogo} />
              </Grid>
              <ResetPasswordHeadingGrid>
                <LoginPageHeading variant="h4">
                  Change Password
                </LoginPageHeading>
              </ResetPasswordHeadingGrid>
            </ResetPasswordLogoGrid>
            <ChangePasswordForm
              onSubmit={handlePassWordDetailsSubmit}
              onCancel={handleCancel}
            />
          </LoginPageBox>
        </LoginPageMainGrid>
      </Grid>
    </>
  );
};

export default ChangePassword;
