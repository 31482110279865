import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const ResetPasswordGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;

export const ResetPasswordLogoGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1]}px`};
`;

export const ResetPasswordHeadingGrid = styled(Grid)`
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;
