import React from 'react';
import CreateInvoiceDataGrid from './CreateInvoiceDataGrid';

const CreateInvoiceBottomSection = (props) => {
  const {
    control,
    register,
    fields,
    append,
    remove,
    setValue,
    getValues,
    subTotalAmount,
  } = props;
  return (
    <CreateInvoiceDataGrid
      control={control}
      register={register}
      fields={fields}
      append={append}
      remove={remove}
      setValue={setValue}
      getValues={getValues}
      subTotalAmount={subTotalAmount}
    />
  );
};

export default CreateInvoiceBottomSection;
