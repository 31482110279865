import React, { useState } from "react";
import EmployeePortalDialog from "../../Common/EmployeePortalDialog";
import CreateEmployeeForm from "./CreateEmployeeForm";
import { postEmployeeApi } from "./Services/service";
import {
  useAuthentication,
  useNotification,
  useSnackBar,
} from "../../Common/CommonUtils";
import { format } from "date-fns";
import { dateFnsFormat } from "../../Common/Constant";
import { useSelector } from "react-redux";

const CreateEmployee = (props) => {
  const { onClose, onOpen, fetchEmployeeData } = props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { isOpenSnackBar, snackbarLabel, showSnackBar, setIsOpenSanckBar } =
    useSnackBar();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const employeeID = useSelector((state) => state.user?.data?.employeeId);

  const handleCloseDialog = () => {
    onClose();
  };

  const handleComponentSubmit = async (data) => {
    const {
      branchOffice,
      date,
      email,
      employeeId,
      firstName,
      lastName,
      role,
      designation,
    } = data;

    const payload = {
      branchOffice: branchOffice,
      role: role,
      designation: designation,
      dateOfBirth: format(new Date(date), dateFnsFormat),
      officialEmailId: email,
      userId: employeeID,
      firstName: firstName,
      lastName: lastName,
    };

    try {
      await postEmployeeApi(payload);
      fetchEmployeeData();
      setSnackbarOpen(true);
      setOpenNotification(true);
      setNotificationMessage("Successfully Created Employee");
      onClose();
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={"Add Employee"}
        customWidth={"520px"}
      >
        <CreateEmployeeForm
          onSubmit={handleComponentSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
      <NotificationPopup />
    </>
  );
};

export default CreateEmployee;
