import React from 'react';
import { useSideNav } from '../../Common/CommonUtils';

const Payroll = () => {
  useSideNav();
  return (
    <div>
      <p>Employee Payroll</p>
    </div>
  );
};

export default Payroll;
