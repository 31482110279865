import { useState } from "react";
import EmployeePortalDialog from "../../../Common/EmployeePortalDialog";
// import { useNotification } from "../../Common/CommonUtils";
// import ViewManagerForm from "./ViewManagerForm";
import { useNotification } from "../../../Common/CommonUtils";
import ViewManagerForm from "./ViewManagerForm";
import { useSelector } from "react-redux";

const ViewManagerDialog = (props) => {
  const { onClose, onOpen, fetchEmployeeData, viewIsOpen, fetchMySkills } =
    props;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isNewRequestSkillForm = useSelector(
    (state) => state?.viewSkillsForm?.isNewRequestSkillForm
  );
  const viewSkillsForm = useSelector((state) => state?.viewSkillsForm?.data);
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const handleCloseDialog = () => {
    onClose();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={
          isNewRequestSkillForm
            ? "Request Skill"
            : viewSkillsForm?.status === "pending"
            ? "Edit Skill"
            : "View Skill"
        }
        customWidth={"800px"}
      >
        <ViewManagerForm
          onCancel={handleCloseDialog}
          onClose={onClose}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
          viewIsOpen={viewIsOpen}
          fetchMySkills={fetchMySkills}
        />
      </EmployeePortalDialog>
      <NotificationPopup />
    </>
  );
};

export default ViewManagerDialog;
