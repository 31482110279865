import React, { useEffect, useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LensIcon from "@mui/icons-material/Lens";
import { useForm } from "react-hook-form";
import FormDropDown from "../../../Common/FormDropDown";
import { AddMySkillButton, getDynamicStyle } from "../MySkillStyled";
import MySkillDialog from "../MySkillDialog";
import {
  dropDownOptions,
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
} from "../../../Common/CommonUtils";
import DataGridComponent from "../../../Common/DataGridComponent";
import { EmployeeDownloadIconGrid } from "../../Employees/Employee.Styled";
import { FormInputSearch } from "../../../Common/FormInputSearch";
import {
  getFrameWorks,
  getViewSkills,
  postEmployeeSkillsData,
  postTeamSkillsData,
} from "../services/mySkillService";
import { downloadOptions, intialPageSize } from "../../../Common/Constant";
import { getEmployeeSkillsData } from "./HrDataGridUtils";
import ViewManagerDialog from "./ViewManagerDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  resetViewSkills,
  setIsNewRequestSkillForm,
  setViewSkills,
} from "../store/viewSkillsSlice/viewSkillsSlice";
import DownloadDropdown from "../../../Common/DownloadDropdown";
import ClearFilterButton from "../../Employees/ClearFilterButton";
import useDebounceEffect from "../../../Common/CustomHooks/useDebounceEffect";

const HrManagerViewDataGrid = () => {
  const dispatch = useDispatch();
  const [openViewManagerDialog, setOpenViewManagerDialog] = useState(false);
  const [skillData, setSkillData] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [technologyValue, setTechnologyValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryName, setCategoryName] = useState(null);
  const [frameWorkPayload, setFrameWorkPayload] = useState(null);
  const SearchResult =
    technologyValue || searchQuery || categoryName || frameWorkPayload;

  const theme = useTheme();
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const loginUserRole = useSelector((state) => state.user?.data?.role);
  const [frameWorkOptions, setFrameWorkOptions] = useState(null);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();
  const metaData = useSelector((state) => state?.metaData);

  const { category , technology} = metaData || {}

  const technologyName = metaData.technology?.find(
    (item) => item.name === technologyValue
  )?.name;

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.name,
      value: item.name,
    })) || [];

  const categoryOptionsList = dropDownOptions(category);
  const technologyOptionsList = mapToOptionsList(technology);
  const frameworkOptionsList = dropDownOptions(frameWorkOptions);

  const isHR = usePermissionCustomHook("HR");
  const isDeveloper = usePermissionCustomHook("Developer");
  const isManager = usePermissionCustomHook("Manager");

  const handleEditSkillDialog = async (params) => {
    const { row } = params;
    try {
      const viewSkills = await getViewSkills(
        loginUserId,
        row.skillId
      );
      dispatch(setViewSkills(viewSkills));
      setOpenViewManagerDialog(true);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.message);
    }
  };

  const handleCloseViewManagerDialog = () => {
    setOpenViewManagerDialog(false);
  };

  const columnsData = [
    { field: "employeeName", headerName: "Name", width: 120, },
    {
      field: "category",
      headerName: "Category",
      width: 120,
      renderCell: (params) => {
        const color = params.value === "process" ? "blue" : "black";
        return (
          <Typography sx={{ color: color, paddingLeft: "10px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    { field: "technology", headerName: "Technology", width: 120, },
    { field: "frameWork", headerName: "Framework", width: 120, },
    {
      field: "currentLevel",
      headerName: "Current Level",
      width: 120,
      renderCell: (params) => (
        <Box sx={getDynamicStyle(theme?.palette.warning.darkBlue)}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "targetLevel",
      headerName: "Target Level",
      width: 120,
      renderCell: (params) => (
        <Box sx={getDynamicStyle(theme.palette.success.lightGreen)}>
          {params.value}
        </Box>
      ),
    },
    { field: "comments", headerName: "Comments", width: 120, },
    { field: "targetDate", headerName: "Target Date", width: 130 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LensIcon
            sx={{
              color:
                params.value === "approved"
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              marginLeft: "8px",
              color:
                params.value === "approved"
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    { field: "approvedOn", headerName: "Approved On", width: 120, },
    { field: "remarks", headerName: "Remarks", width: 120, },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        const { status } = params.row;

        const iconAction =
          status === "pending" ? (
            <ModeEditIcon onClick={() => handleEditSkillDialog(params)} />
          ) : (
            <VisibilityIcon onClick={() => handleEditSkillDialog(params)} />
          );

        return <Button variant="text">{iconAction}</Button>;
      },
    },
  ];

  const defaultValue = {};
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { control, formState, register, reset, resetField } = useFunction;
  const { errors, isValid } = formState;

  const handleRequestSkill = () => {
    setOpenViewManagerDialog(true);
    dispatch(resetViewSkills());
    dispatch(setIsNewRequestSkillForm(true));
  };

  const handleDownloadChange = (option) => {
    console.log("handleDownloadChange", option.value);
  };

  useDebounceEffect(
    () => {
      fetchMySkills();
      setIsFilterSelected(SearchResult);
    },
    500,
    [technologyValue, searchQuery, categoryName, frameWorkPayload]
  );

  useEffect(() => {
    if (technologyValue) {
      fetchFrameWork();
    } else {
      setFrameWorkOptions([]);
      resetField("frameWork");
    }
  }, [technologyValue]);

  const fetchFrameWork = async () => {
    try {
      const frameData = await getFrameWorks(technologyName);
      setFrameWorkOptions(frameData);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const clearSelection = () => {
    reset(defaultValue);
    setTechnologyValue("");
    setFrameWorkPayload("");
    setCategoryName("");
    setSearchQuery("");
  };

  const fetchMySkills = async () => {
    try {
      setSkillData({ ...skillData, isLoading: true });

      const payload = {
        ...(isManager ? { managerId: loginUserId } : {}),
        userId: loginUserId,
        role: loginUserRole,
        ...(searchQuery && { searchBy: searchQuery }),
        ...(categoryName && { category: categoryName }),
        ...(technologyValue && {
          technology: technologyValue,
        }),
        ...(frameWorkPayload && {
          frameWork: frameWorkPayload,
        }),
        page: skillData.page,
        limit: skillData.limit,
      };

      const employeeDetailsList = isHR
        ? await postEmployeeSkillsData(payload)
        : await postTeamSkillsData(payload);

      if (employeeDetailsList?.data) {
        const skillListData = getEmployeeSkillsData(employeeDetailsList?.data);
        setSkillData((prev) => ({
          ...prev,
          rows: skillListData,
          isLoading: false,
          pagination: employeeDetailsList.pagination,
        }));
      } else {
        setOpenNotification("Invalid API response");
      }
    } catch (error) {
      setSkillData({ ...skillData, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Grid
            container
            spacing={1}
            // alignItems="center"
            // justifyContent="flex-end"
          >
            <Grid item xs={6} sm={2}>
              <FormInputSearch
                searchQuery={searchQuery}
                fullWidth
                setSearchQuery={setSearchQuery}
                placeholder="Search"
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormDropDown
                name="category"
                label="Category"
                control={control}
                errors={errors?.category}
                helperText={errors?.category?.message}
                options={categoryOptionsList}
                register={register("category", {
                  onChange: (e) => {
                    setCategoryName(e.target.value);
                  },
                })}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormDropDown
                label="Technology"
                name="technology"
                control={control}
                errors={errors?.technology}
                helperText={errors?.technology?.message}
                options={technologyOptionsList}
                register={register("technology", {
                  onChange: (e) => {
                    setTechnologyValue(e.target.value);
                    resetField("frameWork");
                  },
                })}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              <FormDropDown
                label="Framework"
                name="frameWork"
                control={control}
                errors={errors?.frameWork}
                helperText={errors?.frameWork?.message}
                options={frameworkOptionsList}
                register={register("frameWork", {
                  onChange: (e) => {
                    setFrameWorkPayload(e.target.value);
                  },
                })}
              />
            </Grid>
            <Grid item xs={2} sm={1.5}>
              {isFilterSelected && (
                <ClearFilterButton clearSelection={clearSelection} />
              )}
            </Grid>
            <EmployeeDownloadIconGrid item xs={12} sm={0.5}>
              {(isHR || isManager) && (
                <DownloadDropdown
                  options={downloadOptions}
                  handleChange={handleDownloadChange}
                />
              )}
            </EmployeeDownloadIconGrid>
            {isManager && (
              <Grid item xs={12} sm={2}>
                <AddMySkillButton>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={handleRequestSkill}
                  >
                    Request Skill
                  </Button>
                </AddMySkillButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ViewManagerDialog
        onOpen={openViewManagerDialog}
        onClose={handleCloseViewManagerDialog}
        fetchMySkills={fetchMySkills}
      />

      <DataGridComponent
        autoHeight
        columnsData={columnsData}
        rowData={skillData.rows}
        pageSizeValue={skillData.limit}
        editable={true}
      />
      <NotificationPopup />
    </>
  );
};

export default HrManagerViewDataGrid;
