import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EditControls from "../../../../../Common/EditControl";
import {
  EmployeeInformationCardEditIconGrid,
  EmployeeInformationCardGrid,
} from "./EmployeeInformationStyled";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PersonalInformationForm from "./PersonalInformationForm";
import { ViewDtailsCard } from "../../ViewDetailsPage.styled";
import { useDispatch, useSelector } from "react-redux";
import { getEmployee, putPersonalInfo } from "../../../Services/service";
import {
  formatedZoneDate,
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
} from "../../../../../Common/CommonUtils";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { dateFnsFormat } from "../../../../../Common/Constant";
import { format } from "date-fns";
import { setPersonalInformationForm } from "../../../store/personalInformation/personalInformationSlice";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .matches(/^[a-zA-Z\s]+$/, "Name should only contain characters")
    .nullable(),
  dateOfBirth: yup.date().required("Date of birth is required"),
  gender: yup.string().required("Gender is required").nullable(),
  status: yup.string().required("Status is required").nullable(),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should be exactly 10 digits")
    .nullable(),
  personalEmailId: yup
    .string()
    .email()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Must be a valid email")
    .nullable(),
  officialEmailId: yup
    .string()
    .matches(/^[^\s@]+@hyniva\.com$/i, "Must be a valid @hyniva.com email")
    .transform((value) => (value ? value.trim() : null))
    .nullable(),
  aadhaarNumber: yup
    .string()
    .matches(
      /^\d{4}\s?\d{4}\s?\d{4}$/,
      "Aadhaar number should be 12 digits separated by spaces"
    )
    .nullable(),
  emergencyContactNumber: yup
    .string()
    .matches(/^\d{10}$/, "Mobile number should be exactly 10 digits")
    .nullable(),
  emergencyContactPerson: yup
    .string()
    .matches(
      /^[a-zA-Z\s]+$/,
      "Emergency contact person should only contain letters"
    )
    .nullable(),
  passportNumber: yup
    .string()
    .matches(
      /^[A-Z0-9]{8,12}$/,
      "Passport number should be 8 to 12 characters long and may contain only alphanumeric characters, including capital letters"
    )
    .nullable(),
  joiningDate: yup.date().required("Joining date is required").nullable(),
  panNumber: yup
    .string()
    .nullable()
    .matches(
      /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
      "Invalid PAN card number, format is ABCTY1234D"
    )
    .optional(),
});

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);



  const employeeUserId = useSelector((state) => state.user?.data?.employeeId);
  const employeeIdd = useSelector((state) => state?.employeeInfo?.employeeId);
  const personalInformationForm = useSelector(
    (state) => state?.personalInformationForm
  );

  const {
    aadhaarNumber,
    contactNumber,
    contactPerson,
    createdDateTime,
    dateOfBirth,
    department,
    emergencyContactNumber,
    emergencyContactPerson,
    employeeId,
    firstName,
    gender,
    joiningDate,
    lastName,
    leaveStructure,
    maritalStatus,
    mobileNumber,
    nationality,
    officialEmailId,
    passportNumber,
    password,
    permanentAddress,
    personalEmailId,
    presentAddress,
    relievedDate,
    resignedDate,
    serialNumber,
    ssnNumber,
    status,
    updatedDateTime,
    panNumber,
  } = personalInformationForm || {};

  const defaultValue = {
    employeeId: employeeId,
    name: `${firstName} ${lastName}`,
    dateOfBirth: dateOfBirth ? new Date(formatedZoneDate(dateOfBirth)) : null,
    gender: gender,
    nationality: nationality,
    maritalStatus: maritalStatus,
    mobileNumber: mobileNumber,
    personalEmailId: personalEmailId,
    aadhaarNumber: aadhaarNumber,
    passportNumber: passportNumber,
    officialEmailId: officialEmailId,
    permanentAddress: permanentAddress,
    presentAddress: presentAddress,
    emergencyContactPerson: emergencyContactPerson,
    emergencyContactNumber: emergencyContactNumber,
    leaveStructure: leaveStructure,
    joiningDate: joiningDate ? new Date(joiningDate) : null,
    isAbsconded: "No",
    resignedDate: resignedDate ? new Date(resignedDate) : null,
    relievedDate: relievedDate ? new Date(relievedDate) : null,
    status: status,
    createdDateTime: createdDateTime ? new Date(createdDateTime) : null,
    updatedDateTime: updatedDateTime ? new Date(updatedDateTime) : null,
    panNumber: panNumber,
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(defaultValue);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  useEffect(() => {
    if (isRefresh) {
      reset(defaultValue);
    }
  }, [isRefresh]);

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(defaultValue);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(defaultValue);
    setFormChanged(false);
    setEditable(false);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onsubmit = async (data) => {
    setEditable(false);
    setInitialFormValues(formData);

    const {
      employeeId,
      dateOfBirth,
      joiningDate,
      status,
      name,
      gender,
      nationality,
      maritalStatus,
      mobileNumber,
      aadhaarNumber,
      passportNumber,
      personalEmailId,
      officialEmailId,
      presentAddress,
      leaveStructure,
      permanentAddress,
      emergencyContactPerson,
      emergencyContactNumber,
      isAbsconded,
      resignedDate,
      relievedDate,
      createdDateTime,
      updatedDateTime,
      panNumber,
    } = data;

    const fullName = name.split(" ");

    const [firstNameSplit, lastNameSplit] = fullName;

    const payload = {
      dateOfBirth: format(new Date(dateOfBirth), dateFnsFormat),
      joiningDate: joiningDate
        ? format(new Date(joiningDate), dateFnsFormat)
        : null,
      status: status,
      firstName: firstNameSplit,
      lastName: lastNameSplit,
      gender: gender,
      nationality: nationality,
      maritalStatus: maritalStatus,
      mobileNumber: mobileNumber,
      aadhaarNumber: aadhaarNumber,
      passportNumber: passportNumber,
      personalEmailId: personalEmailId,
      officialEmailId: officialEmailId,
      presentAddress: presentAddress,
      permanentAddress: permanentAddress,
      leaveStructure: leaveStructure,
      emergencyContactPerson: emergencyContactPerson,
      emergencyContactNumber: emergencyContactNumber,
      isAbsconded: isAbsconded,
      resignedDate: resignedDate
        ? format(new Date(resignedDate), dateFnsFormat)
        : null,
      relievedDate: relievedDate
        ? format(new Date(relievedDate), dateFnsFormat)
        : null,
      createdDateTime: createdDateTime,
      panNumber: panNumber,
    };

    try {
      await putPersonalInfo(employeeId, payload);
      const response = await getEmployee(
        employeeUserId,
        employeeIdd
      );
      dispatch(setPersonalInformationForm(response?.data));
      setIsRefresh(true);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.ErrorMessage);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard sx={{ padding: 2 }}>
      <EmployeeInformationCardGrid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h6">Personal Information</Typography>
        </Grid>
        <EmployeeInformationCardEditIconGrid item xs={6}>
          <EditControls
            editable={editable}
            onCancelClick={handleCancelClick}
            onSaveClick={handleSubmit(onsubmit)}
            onEditClick={handleEditClick}
            saveDisabled={!formChanged || isErrorField}
          />
        </EmployeeInformationCardEditIconGrid>
      </EmployeeInformationCardGrid>
      <PersonalInformationForm
        control={control}
        errors={errors}
        defaultValue={defaultValue}
        register={register}
        handleChange={handleChange}
        editable={editable}
      />
      <NotificationPopup />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Employee has created Successfully
        </Alert>
      </Snackbar>
    </ViewDtailsCard>
  );
};

export default PersonalInformation;
