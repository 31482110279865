export const getEmployeeListData = (employeeData) => {
  if (!employeeData || employeeData.length === 0) {
    return [];
  }
  return employeeData.map((item, index) => ({
    ...item,
    id: index,
    employeeId: item.employeeId,
    firstName: item.firstName,
    lastName: item.lastName,
    designation: item.designation,
    department: item.department,
    branchOffice: item.branchOffice,
    aadhaarNumber: item.aadhaarNumber,
    contactNumber: item.contactNumber,
    contactPerson: item.contactPerson,
    createdDateTime: item.createdDateTime,
    dateOfBirth: item.dateOfBirth,
    emergencyContactNumber: item.emergencyContactNumber,
    emergencyContactPerson: item.emergencyContactPerson,
    gender: item.gender,
    joiningDate: item.joiningDate,
    leaveStructure: item.leaveStructure,
    maritalStatus: item.maritalStatus,
    mobileNumber: item.mobileNumber,
    nationality: item.nationality,
    officialEmailId: item.officialEmailId,
    passportNumber: item.passportNumber,
    permanentAddress: item.permanentAddress,
    personalEmailId: item.personalEmailId,
    presentAddress: item.presentAddress,
    relievedDate: item.relievedDate,
    resignedDate: item.resignedDate,
    ssnNumber: item.ssnNumber,
    updatedDateTime: item.updatedDateTime,
  }));
};
