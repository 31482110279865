import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import { FormInputDate } from "../../Common/FormDatePicker";
import Alert from "@mui/material/Alert";
import { LeaveStyledGrid } from "../Leave/components/Leave.styled";
import FormDropDown from "../../Common/FormDropDown";
import { FormTextField } from "../../Common/FormTextField";
import {
  CreateButtonStyling,
  EmployeePortalActionDailog,
} from "../../Common/EmployeePortalDialog.styled";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  dropDownOptions,
  formatedZoneDate,
  useAuthentication,
  useNotification,
} from "../../Common/CommonUtils";
import {
  getFrameWorks,
  postAddMySkill,
  putApproveOrRejectSkill,
} from "./services/mySkillService";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import {
  dateFnsFormat,
  dateFnsYMDFormat,
  requiredField,
} from "../../Common/Constant";

const skillSchema = yup.object().shape({
  category: yup.string().required(requiredField),
  frameWork: yup.string().required(requiredField),
  technology: yup.string().required(requiredField),
  currentLevel: yup.string().required(requiredField),
  targetlevel: yup.string().required(requiredField),
  targetDate: yup.string().required(requiredField),
});

const MySkillForm = (props) => {
  const { snackbarOpen, onCloseSnackbar, onClose, fetchMySkills } = props;

  const employeeId = useSelector((state) => state.user?.data?.employeeId);
  const mySkillsForm = useSelector((state) => state?.mySkillsForm?.data);

  const {
    frameWork,
    currentLevel,
    targetLevel,
    comments,
    targetDate,
    skillId,
    fromDate,
    status,
  } = mySkillsForm || {};

  const [frameWorkOptions, setFrameWork] = useState(null);

  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const metaData = useSelector((state) => state?.metaData);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const isMySkillsForm = useSelector(
    (state) => state?.mySkillsForm?.isMySkillsForm
  );

  const [technologyValue, setTechnologyValue] = useState(
    mySkillsForm?.technology
  );

  const { category, skillLevel, technology } = metaData || {};

  const technologyName = technology?.find(
    (item) => item.name === technologyValue
  )?.name;

  console.log(
    "frameWork-->",
    technologyName,
    frameWorkOptions,
    technologyValue
  );

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.name,
      value: item.name,
    })) || [];

  const categoryOptionsList = dropDownOptions(category);
  const skillLevelOptionsList = dropDownOptions(skillLevel);
  const technologyOptionsList = dropDownOptions(technology);
  const frameworkOptionsList = dropDownOptions(frameWorkOptions);

  const defaultValue = {
    category: mySkillsForm?.category,
    technology: mySkillsForm?.technology,
    frameWork: frameWork,
    currentLevel: currentLevel,
    targetlevel: targetLevel,
    comments: comments,
    targetDate: targetDate ? new Date(formatedZoneDate(targetDate)) : null,
  };
  console.log("framework", mySkillsForm?.frameWork);
  const { control, register, formState, handleSubmit, resetField } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(skillSchema),
  });

  const { errors, isValid } = formState;

  useEffect(() => {
    if (technologyValue) {
      fetchFrameWork();
    } else {
      setFrameWork([]);
    }
  }, [technologyValue]);

  const fetchFrameWork = async () => {
    try {
      const frameData = await getFrameWorks(technologyName);
      setFrameWork(frameData);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const onSubmit = async (data) => {
    const {
      category,
      frameWork,
      currentLevel,
      targetlevel,
      comments,
      targetDate,
    } = data;
    if (currentLevel >= targetlevel) {
      setOpenNotification(true);
      setNotificationMessage(
        "Target Level must be greater than Current Level."
      );
      return;
    }
    const payload = {
      userId: Number(employeeId),
      employeeId: Number(employeeId),
      category: category,
      frameWork: frameWork,
      technology: technologyName,
      currentLevel: currentLevel,
      targetLevel: targetlevel,
      comments: comments ? comments : "",
      targetDate: format(new Date(targetDate), dateFnsFormat),
    };

    try {
      if (isMySkillsForm) {
        await postAddMySkill(payload);
      } else {
        await putApproveOrRejectSkill(
          loginUserId,
          skillId,
          payload
        );
      }
      fetchMySkills();
      onClose();
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  return (
    <>
      <LeaveStyledGrid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              name="category"
              label="Category"
              control={control}
              errors={errors?.category}
              helperText={errors?.category?.message}
              options={categoryOptionsList}
              readOnly={!isMySkillsForm && !(status.toLowerCase() === "draft")}
            />
            <FormDropDown
              name="frameWork"
              label="Framework"
              control={control}
              errors={errors?.frameWork}
              helperText={errors?.frameWork?.message}
              options={frameworkOptionsList}
              readOnly={!isMySkillsForm && !(status.toLowerCase() === "draft")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDropDown
              name="technology"
              label="Technology"
              control={control}
              errors={errors?.technology}
              helperText={errors?.technology?.message}
              options={technologyOptionsList}
              register={register("technology", {
                onChange: (e) => {
                  setTechnologyValue(e.target.value);
                  resetField("frameWork");
                },
              })}
              readOnly={!isMySkillsForm && !(status.toLowerCase() === "draft")}
            />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormDropDown
                  name="currentLevel"
                  label="Current Level"
                  control={control}
                  errors={errors?.currentLevel}
                  helperText={errors?.currentLevel?.message}
                  options={skillLevelOptionsList}
                  readOnly={
                    !isMySkillsForm && !(status.toLowerCase() === "draft")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDropDown
                  name="targetlevel"
                  label="Target Level"
                  control={control}
                  errors={errors?.targetlevel}
                  helperText={errors?.targetlevel?.message}
                  options={skillLevelOptionsList}
                  readOnly={
                    !isMySkillsForm && !(status.toLowerCase() === "draft")
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              label="Comments"
              name="comments"
              type="reason"
              rows={3}
              multiline
              control={control}
              errors={!!errors?.comments}
              helperText={errors?.comments?.message}
              readOnly={!isMySkillsForm && !(status.toLowerCase() === "draft")}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormInputDate
              name="targetDate"
              control={control}
              label="Target Date"
              helperText={errors?.targetDate?.message}
              errors={!!errors.targetDate}
              minDate={new Date()}
              readOnly={!isMySkillsForm && !(status.toLowerCase() === "draft")}
            />
          </Grid>
        </Grid>
      </LeaveStyledGrid>

      <EmployeePortalActionDailog>
        {!(
          status === "approved" ||
          status === "rejected" ||
          status === "pending" ||
          status === "request"
        ) && (
          <CreateButtonStyling
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </CreateButtonStyling>
        )}
      </EmployeePortalActionDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Leave Applied Successfully
        </Alert>
      </Snackbar>
      <NotificationPopup />
    </>
  );
};

export default MySkillForm;
