import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

export const EditIconCancelButton = styled(Button)`
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const EditIconPencil = styled(EditIcon)`
  width: 16px;
  margin-top: ${(props) => `-${props.theme.palette.spacing[1]}px`};
`;
