import React, { useState } from "react";
import TabPanel, { a11yProps } from "../../../Common/TabPanel/TabPanel";
import {
  StickyTab,
  TabContainer,
  ViewDetailsPageeducationTab,
  ViewDetailsPagepersonalInfoTab,
} from "../../Employees/ViewDetailspage/ViewDetailsPage.styled";
import MySkill from "../MySkill";
import HrManagerViewDataGrid from "./HrManagerViewDataGrid";
import { usePermissionCustomHook } from "../../../Common/CommonUtils";

const HrManagerSkillTabs = () => {
  const [value, setValue] = useState(0);
  const isManager = usePermissionCustomHook("Manager");
  const isHR = usePermissionCustomHook("HR");

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <TabContainer>
        <StickyTab
          value={value}
          onChange={handleTabChange}
          aria-label="studentProfileTabs"
        >
          {isManager && (
            <ViewDetailsPagepersonalInfoTab
              label="My Skills"
              {...a11yProps(0)}
            />
          )}
          <ViewDetailsPageeducationTab
            label={isHR ? "Employee Skills" : "Team Skills"}
            {...a11yProps(isManager ? 1 : 0)}
          />
        </StickyTab>
      </TabContainer>
      {isManager && (
        <TabPanel value={value} index={0}>
          <MySkill />
        </TabPanel>
      )}
      <TabPanel value={value} index={isManager ? 1 : 0}>
        <HrManagerViewDataGrid />
      </TabPanel>
    </>
  );
};

export default HrManagerSkillTabs;
