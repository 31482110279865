import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const useSnackbar = () => {
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [snackbarName, setSnackbarName] = useState('');
  const showSnackbar = (message) => {
    setSnackbarName(message);
    setIsSnackbar(true);
  };
  const closeSnackbar = () => {
    setIsSnackbar(false);
    setSnackbarName('');
  };
  const AlertSnackbar = () => (
    <Snackbar
      open={isSnackbar}
      autoHideDuration={2000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={closeSnackbar} severity="success">
        {snackbarName}
      </Alert>
    </Snackbar>
  );
  return {
    showSnackbar,
    AlertSnackbar,
  };
};
export default useSnackbar;
