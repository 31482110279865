import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, useFieldArray } from 'react-hook-form';
import SendEmailForm from './SendEmailForm';
import EmployeePortalDialog from '../../../Common/EmployeePortalDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import useSnackbar from '../../../Common/CustomHooks/useSnackbar';

const schema = yup.object().shape({
  fields: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .required('Customer email ID is required')
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Must be a valid email')
        .test(
          'lowercase-after-at',
          'Uppercase letters after @ are not allowed',
          (value) => {
            if (value) {
              const atIndex = value.indexOf('@');
              const afterAt = value.slice(atIndex + 1);
              return afterAt === afterAt.toLowerCase();
            }
            return true;
          }
        )
        .test(
          'no-numbers-after-at',
          'Numbers after @ are not allowed',
          (value) => {
            if (value) {
              const atIndex = value.indexOf('@');
              const afterAt = value.slice(atIndex + 1);
              return !/\d/.test(afterAt);
            }
            return true;
          }
        )
        .nullable(),
    })
  ),
});

const SendInvoiceDialog = (props) => {
  const { onClose, onOpen, setIsPdfDialogOpen, setIsDialogOpen } = props;

  const { showSnackbar, AlertSnackbar } = useSnackbar();

  const [pdfData, setPdfData] = useState(null);

  const defaultValue = {
    from: 'hr@hyniva.com',

    subject: '',
    message: '',
    fields: [{ value: '' }],
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, register, control, formState } = useFunction;
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'fields',
  });

  const handleCancel = () => {
    onClose();
    setIsPdfDialogOpen(true);
    reset(defaultValue);
  };

  const onSubmit = (data) => {
    console.log('data', data);
    const fieldValues = data.fields.map((field) => field.value);
    console.log({ fields: fieldValues });
    showSnackbar('Invoice Send Successfully');
    reset(defaultValue);
    onClose();
  };

  const handleCloseSendInvoiceDialog = async () => {
    setIsDialogOpen(false);
    reset(defaultValue);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={handleCloseSendInvoiceDialog}
        title={'Send Invoice Email'}
        customWidth={'450px'}
        dataTestId="invoice-send-email-dialog"
      >
        <SendEmailForm
          control={control}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
          onSubmit={onSubmit}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </EmployeePortalDialog>
      <AlertSnackbar />
    </>
  );
};

export default SendInvoiceDialog;
