import { Grid } from "@mui/material";
import { Box, styled } from "@mui/system";
import { FormInputSearch } from "../../Common/FormInputSearch";

export const getDynamicStyle = (color) => ({
  backgroundColor: color,
  height: "50px",
  minWidth: "70%",
  marginLeft: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
});

export const AddMySkillButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    margin-bottom: 20px;
  }
`;

export const SearchContainer = styled(FormInputSearch)`
width : 100%;
border : 2px solid blue;
`
