import Button from '@mui/material/Button';
import React from 'react';

const ClearFilterButton = (props) => {
  const { clearSelection } = props;

  const handleClear = () => {
    clearSelection();
  };

  return <Button onClick={handleClear}>Clear Filter</Button>;
};

export default ClearFilterButton;
