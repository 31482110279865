import React from 'react';

import PerformanceEditForm from './PerformanceEditForm';
import EmployeePortalDialog from '../../../../../Common/EmployeePortalDialog';

const PerformanceEditDialog = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen } = props;

  const handleCloseDialog = () => {
    onClose();
  };

  const handleUpdatePerformanceFormSubmit = async (data) => {
    const { selfRating, managerRating, suggestionByManager } = data;
    const payload = {
      selfRating,
      managerRating,
      suggestionByManager,
    };
    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, [1000]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Performance Form '}
        customWidth={'450px'}
        dataTestId="performanceForm"
      >
        <PerformanceEditForm
          onSubmit={handleUpdatePerformanceFormSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default PerformanceEditDialog;
