import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../../Common/FormTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { EmployeePortalActionDailog } from '../../../../../Common/EmployeePortalDialog.styled';
import FormDropDown from '../../../../../Common/FormDropDown';
import UploadDocument from './UploadDocument';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCourse,
  setDegree,
  setUniversityInstutute,
  setYearOfPassing,
} from './store/educationFormSlice/educationFormSlice';
import { useState } from 'react';
import { usePermissionCustomHook } from '../../../../../Common/CommonUtils';

const schema = yup.object().shape({
  degree: yup.string().required('Degree is required'),
  course: yup.string().required('Course is required'),
  universityinstutute: yup
    .string()
    .matches(
      /^[A-Za-z\s]*$/,
      'university/instutute Name should only contain letters'
    )
    .nullable()
    .required('University or Institute name is required'),
  yearOfPassing: yup
    .number()
    .required('Year of Passing is required')
    .integer('Year of Passing must be an integer')
    .max(new Date().getFullYear(), 'Year of Passing must not be a future year')
    .test(
      'len',
      'Year of Passing must be 4 digits',
      (val) => val.toString().length === 4
    ),
});

const EducationForm = (props) => {
  const { onSubmit, onCancel, snackbarOpen, onCloseSnackbar } = props;

  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isUploaded = selectedFiles.length !== 0 ? false : true;

  const educationForm = useSelector((state) => state?.educationForm);
  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');
  const isUploadDoc = isManager || isDeveloper;
  const isUploadedViaPermission = isUploadDoc && isUploaded;

  const { course, degree, yearOfPassing, serialNo, universityinstutute, id } =
    educationForm;

  const defaultValues = {
    id: id,
    serialNo: serialNo,
    course: course,
    degree: degree,
    universityinstutute: universityinstutute,
    yearOfPassing: yearOfPassing,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;
  const isOtpFieldDirty = dirtyFields.email;

  const degreeOptions = [
    { value: 'Masters', label: 'Masters' },
    { value: 'Degree', label: 'Degree' },
    { value: 'Diploma', label: 'Diploma' },
    { value: 'PUC', label: 'PUC' },
  ];

  const courseOptions = [
    { value: 'MBA', label: 'MBA' },
    { value: 'MCA', label: 'MCA' },
    { value: 'MTech', label: 'MTech' },
    { value: 'MSc', label: 'MSc' },
    { value: 'BBA', label: 'BBA' },
    { value: 'BCA', label: 'BCA' },
    { value: 'BSc', label: 'BSc' },
    { value: 'BTech', label: 'BTech' },
  ];

  return (
    <>
      <Grid container style={{ margin: '0px 16px', width: 'auto' }}>
        <FormDropDown
          label="Degree"
          name="degree"
          type="text"
          control={control}
          errors={!!errors?.degree}
          helperText={errors?.degree?.message}
          options={degreeOptions}
          register={register('degree', {
            onChange: (e) => dispatch(setDegree(e.target.value)),
          })}
        />
        <FormDropDown
          label="Course"
          name="course"
          type="text"
          control={control}
          errors={!!errors?.course}
          helperText={errors?.course?.message}
          options={courseOptions}
          register={register('course', {
            onChange: (e) => dispatch(setCourse(e.target.value)),
          })}
        />
        <FormTextField
          label="University/Institute"
          name="universityinstutute"
          type="text"
          control={control}
          errors={!!errors?.universityinstutute}
          helperText={errors?.universityinstutute?.message}
          register={register('universityinstutute', {
            onChange: (e) => dispatch(setUniversityInstutute(e.target.value)),
          })}
        />
        <FormTextField
          label="Graduation Passing Year"
          name="yearOfPassing"
          type="text"
          control={control}
          errors={!!errors?.yearOfPassing}
          helperText={errors?.yearOfPassing?.message}
          register={register('yearOfPassing', {
            onChange: (e) => dispatch(setYearOfPassing(e.target.value)),
          })}
        />

        {isUploadDoc && (
          <UploadDocument
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        )}
      </Grid>

      <EmployeePortalActionDailog
        style={{ marginTop: isUploadDoc ? '4px' : '0px' }}
      >
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid || isUploadedViaPermission}
        >
          Save
        </Button>
      </EmployeePortalActionDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Education Details added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};
export default EducationForm;
