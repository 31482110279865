import { formatToIST } from "../../../../Common/CommonUtils";
export const HrViewAttendanceDatagridUtils = (attendanceData) => {
  if (!Array.isArray(attendanceData) || attendanceData.length === 0) {
    return [];
  }

  return attendanceData.map((attendance) => {
    const checkInTime = attendance.checkInTime
      ? new Date(attendance.checkInTime)
      : null;
    const checkOutTime = attendance.checkOutTime
      ? new Date(attendance.checkOutTime)
      : null;
    const workedHours = calculateWorkingHours(checkInTime, checkOutTime);

    return {
      ...attendance,
      id: attendance.attendanceId,
      employeeName: attendance.employeeName,
      attendanceRequestId: attendance.attendanceRequestId,
      name: attendance.name,
      date: attendance.date,
      checkIn: checkInTime ? formatToIST(attendance.checkInTime, true) : "",
      checkOut: checkOutTime ? formatToIST(attendance.checkOutTime, true) : "",
      workingHours: workedHours,
      comment: attendance.comment,
      status: attendance.status,
      reason: attendance.reason,
      remarks: attendance.remarks,
    };
  });
};
const calculateWorkingHours = (checkInTime, checkOutTime) => {
  if (!checkInTime || !checkOutTime) return "0h:0m:0s";

  const diffInMilliseconds = checkOutTime - checkInTime;
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

  return `${hours}h:${minutes}m:${seconds}s`;
};
