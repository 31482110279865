import { createSlice } from '@reduxjs/toolkit';
import { _setUserInfo } from './userAction';

export const userSlice = createSlice({
  name: 'ep/user',
  initialState: null,
  reducers: {
    setUserInfo: _setUserInfo,
    resetUserInfo: () => null,
  },
});
export const { setUserInfo, resetUserInfo } = userSlice.actions;
export default userSlice.reducer;
