import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextField } from '../../../Common/FormTextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ResetPasswordGrid } from '../ResetPassword/ResetPassword.styled';

const schema = yup.object().shape({
  current_password: yup
    .string()
    .required('You must enter a password')
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must be at most 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
    new_password: yup
    .string()
    .required('You must enter a password')
    .min(8, 'Password must be at least 8 characters')
    .max(12, 'Password must be at most 12 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
    confirm_new_password: yup
    .string()
    .required('You must confirm your new password')
    .oneOf(
      [yup.ref('confirm_new_password'), null],
      'New Password and Confirm password must be the same'
    ),
});

const ChangePasswordForm = (props) => {
  const { onSubmit, onCancel } = props;
  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  return (
    <>
      <FormTextField
        name="current_password"
        label="Current Password"
        type={showPassword ? 'text' : 'password'}
        control={control}
        errors={!!errors?.current_password}
        helperText={errors?.current_password?.message}
        register={register('current_password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <FormTextField
        name="new_password"
        label="New Password"
        type={showPassword ? 'text' : 'password'}
        control={control}
        errors={!!errors?.new_password}
        helperText={errors?.new_password?.message}
        register={register('new_password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      <FormTextField
        name="confirm_new_password"
        label="Confirm Password"
        type={showNewPassword ? 'text' : 'password'}
        control={control}
        errors={!!errors.confirm_new_password}
        helperText={errors?.confirm_new_password?.message}
        register={register('confirm_new_password')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={toggleNewPasswordVisibility}>
              {showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      <ResetPasswordGrid container spacing={2}>
        <Grid item xs={6}>
          <Button variant="contained" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </ResetPasswordGrid>
    </>
  );
};

export default ChangePasswordForm;
