import { DesktopDatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import { FormHelperText } from '@mui/material';
import { disableFocusStyles } from './Common.styled';

export const DatePickeDesktop = styled(DesktopDatePicker)`
  .MuiInputLabel-root {
    ${(props) => (props.primary ? `color: #F44335` : '')};
  }
  .MuiFilledInput-root {
    ${(props) => (props.primary ? ` border-color:  #f04438;` : '')};
    ${(props) => (props.primary ? `box-shadow: #f04438 0 0 0 1px;` : '')};
  }
  && {
    & .MuiFilledInput-root.Mui-focused {
      ${(props) => props.disableFocus && disableFocusStyles};
    }
  }
`;

export const DateFormHelperText = styled(FormHelperText)`
  color: red;
  margin-left: 14px;
  margin-top: -11px;
`;
