import { useForm } from 'react-hook-form';
import { FormTextField } from '../../../../../Common/FormTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { EmployeePortalActionDailog } from '../../../../../Common/EmployeePortalDialog.styled';
import FormDropDown from '../../../../../Common/FormDropDown';
import { FormInputDate } from '../../../../../Common/FormDatePicker';
import UploadDocument from './UploadDocument';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCertificationAuthority,
  setCertificationId,
  setCertifiedDate,
  setTechnologyName,
  setValidlastDate,
} from './store/certificationFormSlice/certificationSlice';
import {
  formatDate,
  usePermissionCustomHook,
} from '../../../../../Common/CommonUtils';
import { useState } from 'react';

const schema = yup.object().shape({
  certificationAuthority: yup
    .string()
    .matches(
      /^[A-Za-z\s]*$/,
      ' certificationAuthority should only contain letters'
    )
    .nullable()
    .required('Certification authority is required'),
});

const AddCertificationForm = (props) => {
  const { onSubmit, onCancel, snackbarOpen, onCloseSnackbar } = props;

  const dispatch = useDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const isUploaded = selectedFiles.length !== 0 ? false : true;

  const certicationForm = useSelector((state) => state?.certicationForm);

  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');
  const isUploadDoc = isManager || isDeveloper;
  const isUploadedViaPermission = isUploadDoc && isUploaded;

  const {
    isNewCertificateForm,
    certificationAuthority,
    certificationId,
    certifiedDate,
    serialNo,
    technologyName,
    validlastDate,
    id,
  } = certicationForm;

  const formattedCertifiedDate = formatDate(certifiedDate);
  const formattedValidlastDate = formatDate(validlastDate);

  const defaultValue = {
    id: id,
    certificationAuthority: certificationAuthority,
    certificationId: certificationId,
    certifiedDate: isNewCertificateForm
      ? null
      : new Date(formattedCertifiedDate),
    serialNo: serialNo,
    technologyName: technologyName,
    validlastDate: isNewCertificateForm
      ? null
      : new Date(formattedValidlastDate),
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;
  const isOtpFieldDirty = dirtyFields.email;

  const technologyNameOptions = [
    { value: 'Java', label: 'Java' },
    { value: '.NET', label: '.NET' },
    { value: 'PHP', label: 'PHP' },
    { value: 'javaScript', label: 'javaScript' },
    { value: 'Python', label: 'Python' },
    { value: 'C/C++', label: 'C/C++' },
    { value: 'C#', label: 'C#' },
    { value: 'React', label: 'React' },
  ];

  return (
    <>
      <Grid container style={{ margin: '0px 16px', width: 'auto' }}>
        <FormDropDown
          label="Technology Name"
          name="technologyName"
          type="select"
          control={control}
          errors={!!errors?.technologyName}
          helperText={errors?.technologyName?.message}
          options={technologyNameOptions}
          register={register('technologyName', {
            onChange: (e) => dispatch(setTechnologyName(e.target.value)),
          })}
        />
        <FormTextField
          label="Certification Authority"
          name="certificationAuthority"
          type="text"
          control={control}
          errors={!!errors?.certificationAuthority}
          helperText={errors?.certificationAuthority?.message}
          register={register('certificationAuthority', {
            onChange: (e) =>
              dispatch(setCertificationAuthority(e.target.value)),
          })}
        />
        <FormInputDate
          label="Certified Date"
          name="certifiedDate"
          type="date"
          control={control}
          // errors={!!errors?.certifiedDate}
          // helperText={errors?.certifiedDate?.message}
          register={register('certifiedDate', {
            onChange: (e) => dispatch(setCertifiedDate(e.target.value)),
          })}
        />
        <FormInputDate
          label="Valid Last Date"
          name="validlastDate"
          control={control}
          type="date"
          // errors={!!errors?.validlastDate}
          // helperText={errors?.validlastDate?.message}
          register={register('validlastDate', {
            onChange: (e) => dispatch(setValidlastDate(e.target.value)),
          })}
        />
        {isUploadDoc && (
          <UploadDocument
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
          />
        )}
      </Grid>

      <EmployeePortalActionDailog
        style={{ marginTop: isUploadDoc ? '4px' : '0px' }}
      >
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid || isUploadedViaPermission}
        >
          Save
        </Button>
      </EmployeePortalActionDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Certification Details added Successfully
        </Alert>
      </Snackbar>
    </>
  );
};
export default AddCertificationForm;
