import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // or use another storage method if needed

import { rootReducer } from './reducer';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
