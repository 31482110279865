import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import FormDropDown from "../../../Common/FormDropDown";
import { FormTextField } from "../../../Common/FormTextField";
import FormTimePicker from "../../../Common/FormTimePicker ";
import {
  renderSaveCancelButtons,
  renderRejectApproveButtons,
  useNotification,
  formatTimeFromUTCToIST,
} from "../../../Common/CommonUtils";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@mui/material/Grid";
import { FormInputDate } from "../../../Common/FormDatePicker";
import { GridForm } from "../../../Common/Ui/styled";
import { dateFnsFormat, requiredField } from "../../../Common/Constant";
import { FormTypo, HeadTypo } from "./LogAttendance.styled";
import { format } from "date-fns";
import {
  postAttendance,
  getAttendance,
  postApproveReject,
  getAttendanceRequests,
} from "../services/Services";
import FormSnackBar from "../../../Common/SnackBar";
const leaveSchema = yup.object().shape({
  checkInDate: yup.string().required(requiredField),
  checkOutDate: yup.string().required(requiredField),
  checkInTime: yup.string().required(requiredField),
  checkOutTime: yup.string().required(requiredField),
  reason: yup.string().required(requiredField),
  remarks: yup.string().required(requiredField),
});

const LogAttendanceForm = (props) => {
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const { onCancel, attendanceData, attendanceRequestId, isHR, fetchedData } =
    props;

  const { attendanceId, status } = attendanceData;
  const [workedHours, setWorkedHours] = useState(
    attendanceData?.workingHours || "0.00"
  );
  const [categoryOptionsList, setCategoryOptionsList] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarLabel, setSnackBarLabel] = useState("");
  const [commentForApproveReject, setCommentForApproveReject] = useState(
    attendanceData.comment
  );
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const defaultValue = {
    attendance_id: attendanceData ? attendanceData.attendanceId : null,
    checkInDate: attendanceData ? new Date(attendanceData.date) : null,
    checkOutDate: attendanceData ? new Date(attendanceData.date) : null,
    checkInTime: attendanceData?.checkInTime
      ? formatTimeFromUTCToIST(attendanceData?.checkInTime)
      : null,
    checkInTime: attendanceData?.checkInTime
      ? formatTimeFromUTCToIST(attendanceData?.checkInTime)
      : null,
    checkOutTime: attendanceData?.checkOutTime
      ? formatTimeFromUTCToIST(attendanceData?.checkOutTime)
      : null,
    reason: attendanceData.reason,
    remarks:
      attendanceData.remarks === "string" ? null : attendanceData.remarks,
    comment: attendanceData?.comment || "",
  };

  const { control, formState, handleSubmit, watch, register } = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(leaveSchema),
  });

  const { errors, isValid } = formState;
  const checkInTime = watch("checkInTime");
  const checkOutTime = watch("checkOutTime");
  useEffect(() => {
    if (checkInTime && checkOutTime) {
      const [checkInHours, checkInMinutes] = checkInTime.split(":").map(Number);
      const [checkOutHours, checkOutMinutes] = checkOutTime
        .split(":")
        .map(Number);

      const checkInDateTime = new Date();
      const checkOutDateTime = new Date();

      checkInDateTime.setHours(checkInHours, checkInMinutes, 0, 0);
      checkOutDateTime.setHours(checkOutHours, checkOutMinutes, 0, 0);

      const diffInMilliseconds = checkOutDateTime - checkInDateTime;
      const diffInMinutes = Math.max(
        0,
        Math.floor(diffInMilliseconds / (1000 * 60))
      );
      const hours = Math.floor(diffInMinutes / 60);
      const minutes = diffInMinutes % 60;
      setWorkedHours(`${hours}h ${minutes}m`);
    }
  }, [checkInTime, checkOutTime]);
  const onSubmit = async (data) => {
    const {
      checkInDate,
      checkOutDate,
      checkInTime,
      checkOutTime,
      reason,
      remarks,
    } = data;
    const checkInDateTime = new Date(checkInDate);
    const [checkInHours, checkInMinutes] = checkInTime.split(":");
    checkInDateTime.setHours(checkInHours, checkInMinutes, 0, 0);
    const checkOutDateTime = new Date(checkOutDate);
    const [checkOutHours, checkOutMinutes] = checkOutTime.split(":");
    checkOutDateTime.setHours(checkOutHours, checkOutMinutes, 0, 0);
    if (checkOutDateTime <= checkInDateTime) {
      setOpenNotification(true);
      setNotificationMessage("Check-Out time must be after Check-In time.");
      return;
    }
    const diffInMilliseconds = checkOutDateTime - checkInDateTime;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    if (diffInHours > 12) {
      setOpenNotification(true);
      setNotificationMessage(
        "Working hours must not exceed 12 hours for a single day."
      );
      return;
    }
    const checkInISO = checkInDateTime.toISOString();
    const checkOutISO = checkOutDateTime.toISOString();

    const formattedDate = format(new Date(attendanceData?.date), "yyyy-MM-dd");
    const payload = {
      attendance_id: attendanceData.attendanceId,
      date: formattedDate,
      check_in_time: checkInISO,
      check_out_time: checkOutISO,
      reason: reason,
      remarks: remarks,
      workingHours: workedHours,
    };

    try {
      await postAttendance(loginUserId, payload);
      setSnackBarLabel("Attendance successfully updated.");
      setOpenSnackBar(true);
      setTimeout(() => {
        onCancel();
      }, 2000);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.detail ||
        error?.response?.data?.message ||
        "An error occurred while submitting attendance.";
      setOpenNotification(true);
      setNotificationMessage(errorMessage);
      setTimeout(() => {
        onCancel();
      }, 2000);
    }
  };
  const isReadOnly = isHR;
  const rejectApproveStatus =
    attendanceData?.status === "Approved" ||
    attendanceData?.status === "Rejected";

  const fetchAttendanceData = async () => {
    try {
      const attendanceData = await getAttendance();
      const formattedCategoryOptions = attendanceData.data
        .map((item) => ({
          value: item.name,
          label: item.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      setCategoryOptionsList(formattedCategoryOptions);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.detail ||
        error?.response?.data?.message ||
        "An error occurred while fetching attendance data.";

      setOpenNotification(true);
      setNotificationMessage(errorMessage);
    }
  };
  useEffect(() => {
    fetchAttendanceData();
  }, []);

  const handleAttendanceAction = async (action) => {
    const payload = {
      request_type: "Attendance",
      request_id: attendanceData?.attendanceRequestId,
      action: action,
      comment: commentForApproveReject,
    };

    try {
      await postApproveReject(loginUserId, payload);
      await getAttendanceRequests(loginUserId);
      onCancel();
      fetchedData();
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.detail);
    }
  };
  const handleApproveReject = () => handleAttendanceAction("Approved");
  const handleReject = () => {
    if (!commentForApproveReject) {
      setOpenNotification(true);
      setNotificationMessage(
        "Rejection comment is mandatory for leave requests."
      );
      return;
    }
    handleAttendanceAction("Rejected");
  };
  return (
    <>
      <GridForm container spacing={2}>
        <Grid item xs={12}>
          <FormDropDown
            name="reason"
            label="Select Attendance Type**"
            control={control}
            errors={!!errors?.reason}
            helperText={errors?.reason?.message}
            options={categoryOptionsList}
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDate
            name="checkInDate"
            control={control}
            label="Check In Date"
            helperText={errors?.checkInDate?.message}
            errors={!!errors.checkInDate}
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTimePicker
            name="checkInTime"
            control={control}
            label="Check In Time**"
            format="HH:mm"
            errors={!!errors.checkInTime}
            helperText={errors.checkInTime?.message}
            showClockView
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormInputDate
            name="checkOutDate"
            control={control}
            label="Check Out Date"
            minDate={
              new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            }
            helperText={errors?.checkOutDate?.message}
            errors={!!errors.checkOutDate}
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTimePicker
            name="checkOutTime"
            control={control}
            label="Check Out Time**"
            format="HH:mm"
            errors={!!errors.checkOutTime}
            helperText={errors.checkOutTime?.message}
            showClockView
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="remarks"
            label="Reasons**"
            rows={2}
            multiline
            control={control}
            errors={!!errors?.remarks}
            helperText={errors?.remarks?.message}
            readOnly={isReadOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTypo>Time Hours:-{workedHours}</FormTypo>
        </Grid>
        {isHR && (
          <Grid item xs={12}>
            <FormTextField
              name="comment"
              label="Comments**"
              rows={2}
              multiline
              control={control}
              errors={!!errors?.comment}
              helperText={errors?.comment?.message}
              readOnly={rejectApproveStatus}
              register={register("comment", {
                onChange: (e) => setCommentForApproveReject(e.target.value),
              })}
            />
          </Grid>
        )}
      </GridForm>

      {isHR
        ? !rejectApproveStatus &&
          renderRejectApproveButtons(handleReject, handleApproveReject)
        : renderSaveCancelButtons(onCancel, handleSubmit(onSubmit))}
      <NotificationPopup />
      <FormSnackBar
        snackBarLabel={snackBarLabel}
        setSnackBarLabel={setSnackBarLabel}
        openSnackBar={openSnackBar}
        setOpenSnackBar={setOpenSnackBar}
        variant="h4"
      />
    </>
  );
};

export default LogAttendanceForm;
