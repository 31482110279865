import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { FieldSelect } from "./Dropdown.styled";
import { InputLabelRed } from "./Common.styled";

const FormDropDown = ({
  label,
  name,
  control,
  errors,
  helperText,
  options,
  disabled,
  readOnly,
}) => {
  return (
    <FormControl fullWidth>
      {errors ? (
        <InputLabelRed htmlFor={name}>{label}</InputLabelRed>
      ) : (
        <InputLabel htmlFor={name}>{label}</InputLabel>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({ field: { onChange, value } }) => (
          <FieldSelect
            disableFocus={readOnly}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            disabled={disabled}
            error={!!errors}
            helperText={helperText}
            inputProps={{ readOnly: readOnly }}
            data-testId={name}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FieldSelect>
        )}
      />
      {errors && (
        <FormHelperText style={{ color: "red" }}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormDropDown;
