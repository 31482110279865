import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  calculateAverageRating,
  formatDateTime,
  usePermissionCustomHook,
} from '../../../../../Common/CommonUtils';

import { ViewDtailsCard } from '../../ViewDetailsPage.styled';
import PerformanceDetailContent from './PerformanceDetailContent';
import { PerformanceRoundIconEdit } from './PerformanceDetails.styled';
import PerformanceEditDialog from './PerformanceEditDialog';
import { setPerformanceEditForm } from './store/performanceSlice';
import { useDispatch } from 'react-redux';
import useSnackbar from '../../../../../Common/CustomHooks/useSnackbar';

const PerformanceDetails = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [employeeCertification, setEmployeeCertification] = useState([
    {
      id: 1,
      serialNo: '01',
      parameters: 'Quality of Work',
      selfRating: '3',
      managerRating: '3.5',
      achieved: (3.5 / 5) * 100,
      suggestionByManager: 'Need to work',
    },
    {
      id: 2,
      serialNo: '02',
      parameters: 'Communication',
      selfRating: '4.3',
      managerRating: '4.1',
      achieved: (4.1 / 5) * 100,
      suggestionByManager: 'Need to work',
    },
    {
      id: 3,
      serialNo: '03',
      parameters: 'Group Work',
      selfRating: '4',
      managerRating: '4.1',
      achieved: (4.1 / 5) * 100,
      suggestionByManager: 'Need to work',
    },
    {
      id: 'AVERAGE',
      label: 'Average',
      selfRating: '0',
      managerRating: '0',
    },
  ]);

  const dispatch = useDispatch();
  const { showSnackbar, AlertSnackbar } = useSnackbar();
  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');

  const averageSelfRating = calculateAverageRating(
    employeeCertification,
    'selfRating'
  ).toFixed(2);
  const averageManagerRating = calculateAverageRating(
    employeeCertification,
    'managerRating'
  ).toFixed(2);

  const defaultValues = {
    employeeSignature: '',
    reviewerSignature: '',
    goals: '',
    commentsAndApprovals: '',
  };

  const schemaProperties = yup.object().shape({
    employeeSignature: isDeveloper
      ? yup
          .string()
          .max(255)
          .required('Employee signature is required')
          .matches(
            /^(?=.*[A-Za-z])[A-Za-z\s]*$/,
            'Employee signature should only contain letters'
          )
      : yup.string().max(255),
    reviewerSignature: isManager
      ? yup
          .string()
          .max(255)
          .required('Reviewer signature is required')
          .matches(
            /^(?=.*[A-Za-z])[A-Za-z\s]*$/,
            'Reviewer signature should only contain letters'
          )
      : yup.string().max(255),
  });

  const { control, register, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schemaProperties),
  });

  const { errors, isValid } = formState;

  const handlePerformanceEditClick = (params) => {
    const { row } = params;
    setSnackbarOpen(false);
    setIsEditing(true);
    setIsDialogOpen(true);
    dispatch(setPerformanceEditForm(row));
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setEditedData([...employeeCertification]);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const columnsData = [
    {
      field: 'serialNo',
      headerName: 'Serial No.',
      width: 120,
      valueGetter: ({ value, row }) => {
        if (row.id === 'AVERAGE') {
          return row.label;
        }
        return value;
      },
    },
    {
      field: 'parameters',
      headerName: 'Parameters',
      width: 180,
    },
    {
      field: 'selfRating',
      headerName: 'Self Rating',
      width: 160,
      valueGetter: ({ row, value }) => {
        if (row.id === 'AVERAGE') {
          return averageSelfRating;
        }
        return value;
      },
    },
    {
      field: 'managerRating',
      headerName: 'Manager Rating',
      width: 160,
      valueGetter: ({ row, value }) => {
        if (row.id === 'AVERAGE') {
          return averageManagerRating;
        }
        return value;
      },
    },
    {
      field: 'achieved',
      headerName: 'Achieved %',
      width: 160,
    },
    {
      field: 'suggestionByManager',
      headerName: 'Suggestion by Manager',
      width: 180,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => {
        const isAverageRow = params.row.id === 'AVERAGE';
        return (
          <>
            {!isAverageRow && (
              <PerformanceRoundIconEdit
                variant="outlined"
                onClick={() => handlePerformanceEditClick(params)}
                color="primary"
              ></PerformanceRoundIconEdit>
            )}
          </>
        );
      },
    },
  ];

  const reviewDate = formatDateTime('03/12/2024');

  const onSubmit = (data) => {
    console.log('data', data);
    showSnackbar('Performance sheet is submitted Successfully');
    reset(defaultValues);
  };

  return (
    <>
      <ViewDtailsCard sx={{ padding: 2 }}>
        <PerformanceDetailContent
          reviewDate={reviewDate}
          columnsData={columnsData}
          control={control}
          errors={errors}
          register={register}
          handleSubmit={handleSubmit(onSubmit)}
          disablingButton={!isValid}
          employeeCertification={employeeCertification}
          setSnackbarOpen={setSnackbarOpen}
          snackbarOpen={snackbarOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </ViewDtailsCard>
      <PerformanceEditDialog
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
      <AlertSnackbar />
    </>
  );
};

export default PerformanceDetails;
