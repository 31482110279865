import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import LoginImage from "../../../src/Assets/Images/LoginImage.png";

export const LoginPageMainGrid = styled(Grid)`
  height: 100vh;
  background-image: url(${LoginImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LoginGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;

export const LoginFormStyling = styled("form")`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
`;

export const LoginPageBox = styled(Card)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
  height: 85vh;
  max-width: 330px;
  padding: 20px 24px 20px 24px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.7);
  border-radius: 0px;
  margin-right: ${(props) => `${props.theme.palette.spacing[1] * 15}px`};

  @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
    height: 75vh;
    max-width: 100vh;
    margin-left: ${(props) => `${props.theme.palette.spacing[1] * 15}px`};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.values.sm}px) {
    height: 100vh;
    max-width: 100vh;
    margin: 0px;
    margin-left: 0px;
  }
  
`;

export const LoginPageHeading = styled(Typography)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  // margin-left: ${(props) => `${props.theme.palette.spacing[1] * 10}px`};
`;

export const LoginPageSubHeading = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 25}px`};
  padding: 20px;
`;

export const HynivaLogoStyle = styled("img")`
  margin-top: -${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  height: 96px;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 0}px`};
`;

export const GoogleLogoStyle = styled("img")`
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 30}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const LoginPageButtonGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const ForgotPasswordButtonStyle = styled(Button)`
  &.MuiButton-root {
    margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  }
`;

export const ContactSupportButtonStyle = styled(Button)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 5}px`};
`;
