import React, { useState } from 'react';
import { ViewDtailsCard } from '../../ViewDetailsPage.styled';
import {
  PerformanceDetailsInnerContentGrid,
  PerformanceDetailsContentGrid,
  PerformanceDetailsValue,
  PerformanceDetailsLabel,
  PerformanceDetailsDataGrid,
  PerformanceDetailsButton,
  AddPerformanceDetailsGrid,
  QuaterlyHeading,
} from './PerformanceDetails.styled';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DataGridComponent from '../../../../../Common/DataGridComponent';
import { FormTextField } from '../../../../../Common/FormTextField';
import { useDispatch } from 'react-redux';
import PerformanceAddRowDialog from './PerformanceAddRowDialog';
import { resetPerformanceEditForm } from './store/performanceSlice';
import { usePermissionCustomHook } from '../../../../../Common/CommonUtils';
const PerformanceDetailContent = (props) => {
  const {
    reviewDate,
    columnsData,
    control,
    errors,
    register,
    handleSubmit,
    disablingButton,
    employeeCertification,
  } = props;
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isHR = usePermissionCustomHook('HR');
  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');
  const isReadOnly = isManager || isDeveloper;

  const handlePerformanceAddRow = (params) => {
    setSnackbarOpen(false);
    setIsDialogOpen(true);
    dispatch(resetPerformanceEditForm());
  };

  return (
    <>
      <ViewDtailsCard sx={{ padding: 2 }}>
        <PerformanceDetailsInnerContentGrid container>
          <QuaterlyHeading variant="h3">
            Quarterly Performance sheet
          </QuaterlyHeading>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid>
                <PerformanceDetailsContentGrid container>
                  <PerformanceDetailsLabel variant="labelText">
                    Employee Name:
                  </PerformanceDetailsLabel>
                  <PerformanceDetailsValue variant="labelValues">
                    {'Ramesh Ranjan'}
                  </PerformanceDetailsValue>
                </PerformanceDetailsContentGrid>
                <Grid container>
                  <PerformanceDetailsLabel variant="labelText">
                    Employee ID:
                  </PerformanceDetailsLabel>
                  <PerformanceDetailsValue variant="labelValues">
                    {'1131'}
                  </PerformanceDetailsValue>
                </Grid>
              </Grid>
              <Grid container>
                <PerformanceDetailsLabel variant="labelText">
                  Project (Assigned Project):
                </PerformanceDetailsLabel>
                <PerformanceDetailsValue variant="labelValues">
                  {'Hyper'}
                </PerformanceDetailsValue>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <PerformanceDetailsLabel variant="labelText">
                  Designation:
                </PerformanceDetailsLabel>
                <PerformanceDetailsValue variant="labelValues">
                  {'Software Engineer'}
                </PerformanceDetailsValue>
              </Grid>
              <Grid container>
                <PerformanceDetailsLabel variant="labelText">
                  Reviewer Name:
                </PerformanceDetailsLabel>
                <PerformanceDetailsValue variant="labelValues">
                  {'Juno R'}
                </PerformanceDetailsValue>
              </Grid>
              <Grid container>
                <PerformanceDetailsLabel variant="labelText">
                  Reviewed Date:
                </PerformanceDetailsLabel>
                <PerformanceDetailsValue variant="labelValues">
                  {reviewDate}
                </PerformanceDetailsValue>
              </Grid>
              <Grid container>
                <PerformanceDetailsLabel variant="labelText">
                  Quarter:
                </PerformanceDetailsLabel>
                <PerformanceDetailsValue variant="labelValues">
                  Jan-Mar 2024
                </PerformanceDetailsValue>
              </Grid>
            </Grid>
          </Grid>
        </PerformanceDetailsInnerContentGrid>
      </ViewDtailsCard>
      {isHR && (
        <AddPerformanceDetailsGrid container>
          <Button variant="contained" onClick={handlePerformanceAddRow}>
            Add Row
          </Button>
        </AddPerformanceDetailsGrid>
      )}

      <PerformanceDetailsDataGrid container spacing={2}>
        <DataGridComponent
          autoHeight
          columnsData={columnsData}
          rowData={employeeCertification}
          pageSizeValue={employeeCertification.length}
          height="390px"
          width="100%"
          hideFooter={true}
        />
      </PerformanceDetailsDataGrid>
      <ViewDtailsCard sx={{ padding: 2 }}>
        <PerformanceDetailsDataGrid container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={4.5}>
                <PerformanceDetailsLabel variant="labelText">
                  Goals for Next Review Period :
                </PerformanceDetailsLabel>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="goals"
                  type="text"
                  control={control}
                  readOnly={!isReadOnly}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4.5}>
                <PerformanceDetailsLabel variant="labelText">
                  Comments and Approval:
                </PerformanceDetailsLabel>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="commentsAndApprovals"
                  type="text"
                  control={control}
                  readOnly={!isManager}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4.5}>
                <PerformanceDetailsLabel variant="labelText">
                  Employee Signature<span style={{ color: 'red' }}>*</span> :
                </PerformanceDetailsLabel>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="employeeSignature"
                  type="employeeSignature"
                  control={control}
                  errors={!!errors?.employeeSignature}
                  helperText={errors?.employeeSignature?.message}
                  register={register('employeeSignature')}
                  readOnly={!isDeveloper}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={3.5}>
                <PerformanceDetailsLabel variant="labelText">
                  Reviewer Signature<span style={{ color: 'red' }}>*</span> :
                </PerformanceDetailsLabel>
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  name="reviewerSignature"
                  type="reviewerSignature"
                  control={control}
                  errors={!!errors?.reviewerSignature}
                  helperText={errors?.reviewerSignature?.message}
                  register={register('reviewerSignature')}
                  readOnly={!isManager}
                />
              </Grid>
            </Grid>
          </Grid>
        </PerformanceDetailsDataGrid>
      </ViewDtailsCard>
      <PerformanceDetailsButton container xs={12} spacing={2}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={disablingButton}
        >
          Submit
        </Button>
      </PerformanceDetailsButton>
      <PerformanceAddRowDialog
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
};
export default PerformanceDetailContent;
