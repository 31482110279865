const initialState = {
  branches: [],
  roles: [],
  managers: [],
  departments: [],
  coreTechnologies: [],
  designations: [],
  frameWorks: [],
  projects: [],
  leaveType: [],
  category: [],
  skillLevel: [],
  technology: [],
  managerEmployees: [],
};
export default initialState;
