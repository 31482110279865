import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DataGridComponent from "../../Common/DataGridComponent";
import {
  DataMainGrid,
  EmployeeSearchGrid,
  StyledContainerGrid,
  StyledGridContainer,
} from "../Employees/Employee.Styled";
import AllCustomersDropdown from "./AllCustomersDropdown";
import { InvoiceSearch } from "./InvoiceSearch";
import {
  TextTypography,
  StyledGridContent,
  StyledInvoiceCard,
  IconVisibile,
} from "./Invoice.styled";
import { useEffect, useState } from "react";
import AllStatusesDropdown from "./AllStatusesDropdown";
import ClearFilterInvoiceButton from "./ClearFilterInvoiceButton";
import { GridActionsCellItem } from "@mui/x-data-grid";
import InvoiceListDetails from "./InvoiceListDetails";
import { setInvoiceListForm } from "./store/invoiceSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetInvoiceInfoForm } from "./store/invoiceInfoFormSlice/invoiceInfoFormSlice";
import { getInvoices } from "./services/invoiceApi";
import { getInvoiceListData } from "./InvoiceDataGrid";
import { intialPageSize } from "../../Common/Constant";
import { useAuthentication, useSideNav } from "../../Common/CommonUtils";

const Invoice = () => {
  useSideNav()
  const [searchText, setSearchText] = useState("");
  const [customerSelection, setCustomerSelection] = useState([]);
  const [statusSelection, setStatusSelection] = useState([]);
  const [isFilterCreated, setIsFilterCreated] = useState(false);
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [invoices, setInvoices] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authorization = useAuthentication();

  useEffect(() => {
    setIsFilterSelected(
      customerSelection.length > 0 || statusSelection.length > 0
    );
  }, [customerSelection, statusSelection]);

  const handleInviteLead = (params) => {
    const { row } = params;
  };

  const handleSendRemainder = (params) => {
    const { row } = params;
  };

  const handleInvoiceEdit = (params) => {
    navigate("./createInvoice");
  };

  const handleRowInvoiceEdit = (params) => {
    const { row } = params;
    setSnackbarOpen(false);
    setIsDialogOpen(true);

    dispatch(setInvoiceListForm(row));
  };

  const handleClick = () => {
    navigate("./createInvoice");
  };

  const columnsData = [
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      width: 140,
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice Number",
      width: 160,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 160,
    },
    {
      field: "totalAmountPaid",
      headerName: "Total Amount Paid",
      width: 150,
    },
    {
      field: "paymentDueDate",
      headerName: "Payment Due Date",
      width: 160,
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      width: 160,
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      width: 80,
      getActions: (params) => {
        const { status } = params.row;
        const actions = [];
        actions.push(
          <GridActionsCellItem
            label="Record Payment"
            onClick={() => handleRowInvoiceEdit(params)}
            showInMenu
          />
        );
        actions.push(
          <GridActionsCellItem
            label="View Invoice"
            onClick={() => handleRowInvoiceEdit(params)}
            showInMenu
          />
        );
        if (status === "Draft") {
          actions.push(
            <GridActionsCellItem
              label="Edit Invoice"
              onClick={() => handleInvoiceEdit(params)}
              showInMenu
            />
          );
        }
        actions.push(
          <GridActionsCellItem
            label="Download Invoice"
            onClick={() => handleRowInvoiceEdit(params)}
            showInMenu
          />
        );
        actions.push(
          <GridActionsCellItem
            label="Send Reminder"
            onClick={() => handleSendRemainder(params)}
            showInMenu
          />
        );
        actions.push(
          <GridActionsCellItem
            label="Delete Invoice"
            onClick={() => handleInviteLead(params)}
            showInMenu
          />
        );

        return actions;
      },
    },
    {
      width: 80,
      renderCell: (params) => {
        const { status } = params.row;
        if (status !== "Draft") {
          return (
            <>
              <IconVisibile
                variant="outlined"
                onClick={() => handleInviteLead(params)}
                color="primary"
              >
                Certification Details
              </IconVisibile>
            </>
          );
        }
      },
    },
  ];

  const clearSelection = () => {
    setCustomerSelection([]);
    setStatusSelection([]);
    setIsFilterCreated(false);
  };

  const filteredInvoices = invoices?.rows?.filter(
    (invoice) =>
      invoice.customerName.toLowerCase().includes(searchText.toLowerCase()) ||
      invoice.totalAmountPaid
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      invoice.invoiceNumber.toLowerCase().includes(searchText.toLowerCase())
  );

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceInfoForm());
    };
  }, []);

  useEffect(() => {
    fetchInvoiceData();
  }, []);

  const fetchInvoiceData = async () => {
    try {
      setInvoices({ ...invoices, isLoading: true });

      const invoiceData = await getInvoices(authorization);
      console.log("invoiceData", invoiceData);

      const invoiceListData = getInvoiceListData(invoiceData?.data);
      setInvoices((prev) => ({
        ...prev,
        rows: invoiceListData,
        isLoading: false,
      }));
    } catch (error) {
      setInvoices({ ...invoices, isLoading: false });
    }
  };

  return (
    <>
      <StyledGridContainer
        container
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextTypography variant="h3">Invoice</TextTypography>
        <StyledContainerGrid item>
          <Button variant="contained" onClick={handleClick}>
            Create Invoice
          </Button>
        </StyledContainerGrid>
      </StyledGridContainer>
      <StyledInvoiceCard
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
        }}
      >
        <StyledGridContent container>
          {/* <Grid item xs={3}>
            <AllCustomersDropdown
              clearSelection={clearSelection}
              selection={customerSelection}
              setSelection={setCustomerSelection}
            />
          </Grid> */}
          {/* <Grid item xs={3}>
            <AllStatusesDropdown
              clearSelection={clearSelection}
              selection={statusSelection}
              setSelection={setStatusSelection}
            />
          </Grid> */}
          {/* <Grid item xs={3}>
            {isFilterSelected && (
              <ClearFilterInvoiceButton clearSelection={clearSelection} />
            )}
          </Grid> */}

          {/* <Grid item xs={3}>
            <InvoiceSearch setSearchText={setSearchText} />
          </Grid> */}
        </StyledGridContent>
      </StyledInvoiceCard>
      <DataMainGrid>
        <Card
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
          }}
        >
          <DataGridComponent
            autoHeight
            columnsData={columnsData}
            rowData={filteredInvoices}
            pageSizeValue={filteredInvoices.length}
            height="auto"
            width="auto"
            editable={true}
          />
        </Card>
      </DataMainGrid>
      <InvoiceListDetails
        onClose={() => setIsDialogOpen(false)}
        onOpen={isDialogOpen}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </>
  );
};

export default Invoice;
