import { createSlice } from '@reduxjs/toolkit';
import {
  _setCertificationForm,
  _setCertificationAuthority,
  _setCertificationId,
  _setCertifiedDate,
  _setIsNewCertificateForm,
  _setTechnologyName,
  _setValidlastDate,
} from './certificationAction';
import initialState from './certificationInitialState';

export const CertificationSlice = createSlice({
  name: 'ep/CerticationForm',
  initialState,
  reducers: {
    setCertificationForm: _setCertificationForm,
    setCertificationId: _setCertificationId,
    setTechnologyName: _setTechnologyName,
    setCertificationAuthority: _setCertificationAuthority,
    setCertifiedDate: _setCertifiedDate,
    setValidlastDate: _setValidlastDate,
    setIsNewCertificateForm: _setIsNewCertificateForm,
    resetCertificationForm: () => initialState,
  },
});

export const {
  setCertificationForm,
  setCertificationId,
  setTechnologyName,
  setCertificationAuthority,
  setCertifiedDate,
  setValidlastDate,
  setIsNewCertificateForm,
  resetCertificationForm,
} = CertificationSlice.actions;

export default CertificationSlice.reducer;
