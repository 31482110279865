import Grid from "@mui/material/Grid";
import React from "react";
import BankDetails from "./BankDetails";
import FullFinalSettlement from "./FullFinalSettlement";
import PayrollDetails from "./PayrollDetails";
import PFDetails from "./PFDetails";
import { SalaryAndBankDetailsGrid } from "./SalaryAndBankDetails.styled";

const SalaryAndBankDetails = () => {
  return (
    <>
      <SalaryAndBankDetailsGrid container spacing={2}>
        <Grid item md={6} sm={12}>
          <BankDetails />
        </Grid>
        {/* <Grid item md={6} sm={12}>
          <PFDetails />
        </Grid> */}
      </SalaryAndBankDetailsGrid>
      {/* <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <PayrollDetails />
        </Grid>
        <Grid item md={6} sm={12}>
          <FullFinalSettlement />
        </Grid>
      </Grid> */}
    </>
  );
};

export default SalaryAndBankDetails;
