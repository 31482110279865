import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import EditControls from "../../../../../Common/EditControl";
import {
  EmployeeInformationCardEditIconGrid,
  EmployeeInformationCardGrid,
} from "./EmployeeInformationStyled";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ViewDtailsCard } from "../../ViewDetailsPage.styled";
import AssignmentInformationForm from "./AssignmentInformationForm";
import {
  useAuthentication,
  useNotification,
} from "../../../../../Common/CommonUtils";
import {
  getEmployee,
  getFrameWork,
  putAssignmentInfo,
} from "../../../Services/service";
import { setPersonalInformationForm } from "../../../store/personalInformation/personalInformationSlice";

const AssignmentInformation = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const [editable, setEditable] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [frameWorkOptions, setFrameWorkOptions] = useState([]);

  const personalInformationForm = useSelector(
    (state) => state?.personalInformationForm
  );
  const employeeUserId = useSelector((state) => state.user?.data?.employeeId);

  const { employeeId, assignments } = personalInformationForm || {};

  const {
    assignmentId,
    department,
    billableResource,
    reportingManager,
    designation,
    framework,
    managerId,
    branchOffice,
    coreTechnology,
    assignedProjects,
  } = assignments || {};

  const [isOnSiteStatus, setIsOnSiteStatus] = useState(branchOffice);

  const isBillable = billableResource === "No" ? false : true;

  const metaData = useSelector((state) => state?.metaData);

  const { managers } = metaData || {};

  const defaultValue = {
    managerId: managerId,
    designation: designation,
    department: department,
    coreTechnology: coreTechnology,
    framework: framework === null ? [] : framework,
    branchOffice: branchOffice,
    assignedProjects: assignedProjects ? assignedProjects : [],
    reportingManager: reportingManager,
    onSiteStatus: isOnSiteStatus === "Bangalore, India" ? "No" : "Yes",
    billableResource: billableResource,
  };

  const [formData, setFormData] = useState(defaultValue);

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { handleSubmit, reset, control, register, formState, setValue } =
    useFunction;
  const { errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => {
        if (Array.isArray(value) && Array.isArray(formData[key])) {
          return value.toString() === formData[key].toString();
        } else {
          return value === formData[key];
        }
      }
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(defaultValue);
  }, []);

  useEffect(() => {
    setValue(
      "onSiteStatus",
      isOnSiteStatus === "Bangalore, INDIA" ? "No" : "Yes"
    );
  }, [isOnSiteStatus]);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(defaultValue);
    setFormChanged(false);
    setEditable(false);
  };

  const onsubmit = async (data) => {
    setEditable(false);
    const {
      billableResource,
      branchOffice,
      coreTechnology,
      department,
      designation,
      framework,
      onSiteStatus,
      reportingManager,
      managerId,
      assignedProjects,
    } = data;

    const payload = {
      assignmentId: assignmentId,
      userId: employeeUserId,
      managerId: managerId,
      billableResource: billableResource,
      branchOffice: branchOffice,
      coreTechnology: coreTechnology,
      framework: framework,
      department: department,
      designation: designation,
      onsite: onSiteStatus,
      assignedProjects: assignedProjects,
      reportingManager: reportingManager,
    };

    try {
      await putAssignmentInfo(
        employeeUserId,
        assignmentId,
        payload
      );
      const response = await getEmployee(
        employeeUserId,
        employeeId
      );
      dispatch(setPersonalInformationForm(response?.data));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.ErrorMessage);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "branchOffice") {
      setIsOnSiteStatus(value);
    }
    if (name === "managerId") {
      const selectedEmployee = managers?.find(
        (employee) => employee.employeeId === value
      );
      setValue("reportingManager", selectedEmployee?.name);
    }

    if (name === "coreTechnology") {
      try {
        const frameWorksByTechnology = await getFrameWork(value);
        setFrameWorkOptions(frameWorksByTechnology);
        setValue("framework", []);
      } catch (error) {
        setValue("framework", []);
        setFrameWorkOptions([]);
      }
    }
  };

  const isSaveEnable = !formChanged || isErrorField;

  return (
    <>
      <ViewDtailsCard sx={{ padding: 2 }}>
        <EmployeeInformationCardGrid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="h6">Assignment Information</Typography>
          </Grid>
          <EmployeeInformationCardEditIconGrid item xs={6}>
            <EditControls
              editable={editable}
              onCancelClick={handleCancelClick}
              onSaveClick={handleSubmit(onsubmit)}
              onEditClick={handleEditClick}
              saveDisabled={isSaveEnable}
            />
          </EmployeeInformationCardEditIconGrid>
        </EmployeeInformationCardGrid>
        <AssignmentInformationForm
          control={control}
          errors={errors}
          defaultValue={defaultValue}
          register={register}
          handleChange={handleChange}
          editable={editable}
          isBillable={isBillable}
          setFormChanged={setFormChanged}
          frameWorkOptions={frameWorkOptions}
        />
      </ViewDtailsCard>
      <NotificationPopup />
    </>
  );
};

export default AssignmentInformation;
