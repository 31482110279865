import { createSlice } from '@reduxjs/toolkit';
import initialState from './loginPageInitialState';
import {
  _setIsClicked,
  _setPasswordValue,
  _setUserNameValue,
} from './loginPageAction';

export const loginPageSlice = createSlice({
  name: 'LoginPage',
  initialState,
  reducers: {
    setIsClicked: _setIsClicked,
    setUserNameValue: _setUserNameValue,
    setPasswordValue: _setPasswordValue,
    resetLoginPage: () => initialState,
  },
});

export const { setIsClicked, setUserNameValue, setPasswordValue } =
  loginPageSlice.actions;

export default loginPageSlice.reducer;
