import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { FormTextField } from '../../Common/FormTextField';
import ForgotPasswordButton from './ForgotPasswordButton';
import {
  ForgotPasswordstylingForm,
  LoginTypography,
  TextForgotPasswordGrid,
} from './ForgotPassword.Styled';
import Typography from '@mui/material/Typography';

const ForgotPasswordFormField = (props) => {
  const { control, errors, register, handleClick, handleSubmit } = props;

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState(null);

  const handleForgotPasswordEmail = (e) => {
    setForgotPasswordEmail(e.target.value)
  }

  return (
    <ForgotPasswordstylingForm>
      <Grid>
        <FormTextField
          label="Email Address"
          name="email"
          type="email"
          control={control}
          errors={!!errors?.email}
          helperText={errors?.email?.message}
          register={register('email', {
            onChange: (e) => handleForgotPasswordEmail(e),
          })}
        />
      </Grid>
      <ForgotPasswordButton
        handleClick={handleClick}
        handleSubmit={handleSubmit}
        forgotPasswordEmail={forgotPasswordEmail}
      />
      <TextForgotPasswordGrid>
        <Typography>Already have an account ?</Typography>
        <LoginTypography onClick={handleClick}>Login</LoginTypography>
      </TextForgotPasswordGrid>
    </ForgotPasswordstylingForm>
  );
};

export default ForgotPasswordFormField;
