import { combineReducers } from "@reduxjs/toolkit";
import LoginPage from "../../src/Components/Login/Store/loginPageSlice";
import sideNav from "../Components/SideNavbar/Store/slideNavbarSlice";
import EmployeeDetails from "../Components/Employees/store/EmployeeSlice";
import certicationForm from "../Components/Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/store/certificationFormSlice/certificationSlice";
import educationForm from "../Components/Employees/ViewDetailspage/ViewDetailsPageTab/EducationAndCertification/store/educationFormSlice/educationFormSlice";
import invoiceListForm from "../Components/Invoice/store/invoiceSlice";
import personalInformationForm from "../Components/Employees/store/personalInformation/personalInformationSlice";
import assetInfoForm from "../Components/Employees/store/assetsInformationSlice/assetInfoSlice";
import loader from "../Common/Loader/store/loaderSlice";
import performanceListEditForm from "../Components/Employees/ViewDetailspage/ViewDetailsPageTab/Performance/store/performanceSlice";
import employeeInfo from "../Components/Employees/store/employeeIdSlice/employeeIdSlice";
import resetPasswordEmail from "../Components/ForgotPassword/ResetPassword/store/resetPasswordSlice";
import invoiceInfoForm from "../Components/Invoice/store/invoiceInfoFormSlice/invoiceInfoFormSlice";
import metaData from "../Common/store/metaDataSlice/metaDataSlice";
import authentication from "../Common/store/authenticatedSlice/authenticatedSlice";
import user from "../../src/Common/store/userSlice/userSlice";
import applyLeaveForm from "../Components/Leave/store/applyLeavesSlice/applyLeaveSlice";
import viewSkillsForm from '../Components/MySkill/store/viewSkillsSlice/viewSkillsSlice';
import mySkillsForm from '../Components/MySkill/store/mySkillsFormSlice/mySkillsFormSlice';
import attendanceSummary from "../Components/Attendance/store/attendanceSummarySlice";
import navigation from "../Components/SideNavbar/Store/navigationSlice/navigationSlice";
import timeout  from "../Common/store/timeoutSlice/timeoutSlice";

export const rootReducer = combineReducers({
  user,
  sideNav,
  EmployeeDetails,
  certicationForm,
  educationForm,
  invoiceListForm,
  personalInformationForm,
  loader,
  performanceListEditForm,
  employeeInfo,
  resetPasswordEmail,
  invoiceInfoForm,
  metaData,
  assetInfoForm,
  applyLeaveForm,
  viewSkillsForm,
  mySkillsForm,
  attendanceSummary,
  authentication,
  navigation,
  timeout,
});
