import React, { useEffect, useState } from "react";
import { Button, Typography, Grid, Box } from "@mui/material";
import { useTheme } from "@emotion/react";
import DataGridComponent from "../../Common/DataGridComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LensIcon from "@mui/icons-material/Lens";
import FormDropDown from "../../Common/FormDropDown";
import { StyledContainerGrid } from "../Employees/Employee.Styled";
import { useForm } from "react-hook-form";
import { AddMySkillButton, getDynamicStyle } from "../MySkill/MySkillStyled"; // Import the function
import { useDispatch, useSelector } from "react-redux";
import {
  dropDownOptions,
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
  useSideNav,
} from "../../Common/CommonUtils";
import { intialPageSize } from "../../Common/Constant";
import { getSkillDataGridUtils } from "./MySkillDataGridUtils";
import MySkillDialog from "./MySkillDialog";
import {
  getFrameWorks,
  getMySkills,
  getViewSkills,
  postEmployeeSkillsData,
  putUpdateMySkills,
} from "./services/mySkillService";
import {
  resetMySkillForm,
  setIsMySkillsForm,
  setMySkills,
} from "./store/mySkillsFormSlice/mySkillsFormSlice";
import useSnackbar from "../../Common/CustomHooks/useSnackbar";
import ClearFilterButton from "../Employees/ClearFilterButton";
import useDebounceEffect from "../../Common/CustomHooks/useDebounceEffect";

const MySkill = () => {
  useSideNav();

  const [mySkillOpen, setMySkillOpen] = useState(false);
  const [skillsData, setSkillsData] = useState(null);
  const [skillData, setSkillData] = useState({
    isLoading: false,
    rows: [],
    limit: intialPageSize,
    page: 1,
  });
  const isDeveloper = usePermissionCustomHook("Developer");
  const dispatch = useDispatch();
  const employeeId = useSelector((state) => state?.employeeInfo?.employeeId);
  const loginUserId = useSelector((state) => state.user?.data?.employeeId);
  const theme = useTheme();
  const { showSnackbar, AlertSnackbar } = useSnackbar();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const isManager = usePermissionCustomHook("Manager");
  const [isFilterSelected, setIsFilterSelected] = useState(false);
  const [technologyValue, setTechnologyValue] = useState(null);
  const [frameWorkOptions, setFrameWorkOptions] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [frameWorkPayload, setFrameWorkPayload] = useState(null);
  const SearchResult = technologyValue || categoryName || frameWorkPayload;

  const loginUserRole = useSelector((state) => state.user?.data?.role);

  const metaData = useSelector((state) => state?.metaData);

  const {category , technology} = metaData || {}

  const technologyName = metaData.technology?.find(
    (item) => item.name === technologyValue
  )?.name;

  const mapToOptionsList = (items) =>
    items?.map((item) => ({
      label: item.name,
      value: item.name,
    })) || [];

  const categoryOptionsList = dropDownOptions(category);
  const technologyOptionsList = mapToOptionsList(technology);
  const frameworkOptionsList = dropDownOptions(frameWorkOptions);

  const columnsData = [
    {
      field: "category",
      headerName: "Category",
      width: 120,
      renderCell: (params) => {
        const color = params.value === "process" ? "blue" : "black";
        return (
          <Typography sx={{ color: color, paddingLeft: "10px" }}>
            {params.value}
          </Typography>
        );
      },
    },
    { field: "technology", headerName: "Technology", width: 120, },
    { field: "frameWork", headerName: "Framework", width: 120, },
    {
      field: "currentLevel",
      headerName: "Current Level",
      width: 120,
      renderCell: (params) => (
        <Box sx={getDynamicStyle(theme?.palette.warning.darkBlue)}>
          {params.value}
        </Box>
      ),
    },
    {
      field: "targetLevel",
      headerName: "Target Level",
      width: 120,
      renderCell: (params) => (
        <Box sx={getDynamicStyle(theme.palette.success.lightGreen)}>
          {params.value}
        </Box>
      ),
    },
    { field: "comments", headerName: "Comments", width: 120, },
    { field: "targetDate", headerName: "Target Date", width: 130 },
    { field: "managerName", headerName: "Manager", width: 120, },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LensIcon
            sx={{
              color:
                params.value === "approved"
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
              fontSize: "10px",
            }}
          />
          <Typography
            sx={{
              marginLeft: "8px",
              color:
                params.value === "approved"
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    { field: "approvedOn", headerName: "Approved On", width: 120, },
    { field: "remarks", headerName: "Remarks", width: 120, },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        const { status } = params.row;
        const iconAction =
          status === "draft" ? (
            <ModeEditIcon onClick={() => handleEditSkills(params)} />
          ) : (
            <VisibilityIcon onClick={() => handleEditSkills(params)} />
          );

        return <Button variant="text">{iconAction}</Button>;
      },
    },
  ];

  const defaultValue = {};
  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
  });

  const { control, formState, reset, register, resetField } = useFunction;
  const { errors, isValid } = formState;

  const handleNewSkill = () => {
    setMySkillOpen(true);
    dispatch(resetMySkillForm());
    dispatch(setIsMySkillsForm(true));
  };

  const handleNewSkillCloseDialog = () => {
    setMySkillOpen(false);
  };

  const handleEditSkills = async (params) => {
    const { row } = params;
    try {
      const viewSkills = await getViewSkills(
        loginUserId,
        row.skillId
      );
      dispatch(setMySkills(viewSkills));
      setSkillsData(row);
      setMySkillOpen(true);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error.response.data.error);
    }
  };

  const handleUpdate = async () => {
    try {
      await putUpdateMySkills(loginUserId);
      showSnackbar("Skills has been submitted successfully");
      fetchMySkills();
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const clearSelection = () => {
    reset(defaultValue);
    setTechnologyValue("");
    setFrameWorkPayload("");
    setCategoryName("");
  };

  useDebounceEffect(
    () => {
      fetchMySkills();
      setIsFilterSelected(SearchResult);
    },
    500,
    [technologyValue, categoryName, frameWorkPayload]
  );

  useEffect(() => {
    if (technologyValue) {
      fetchFrameWork();
    } else {
      setFrameWorkOptions([]);
      resetField("frameWork");
    }
  }, [technologyValue]);

  const fetchFrameWork = async () => {
    try {
      const frameData = await getFrameWorks(technologyName);
      setFrameWorkOptions(frameData);
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.error);
    }
  };

  const fetchMySkills = async () => {
    try {
      setSkillData({ ...skillData, isLoading: true });

      const payload = {
        // ...(isManager ? { managerId: loginUserId } : {}),
        userId: loginUserId,
        // role: loginUserRole,
        ...(categoryName && { category:  categoryName }),
        ...(technologyValue && {
          technology: technologyValue,
        }),
        ...(frameWorkPayload && {
          frameWork: frameWorkPayload,
        }),
        page: skillData.page,
        limit: skillData.limit,
      };

      const mySkills = await getMySkills(payload);

      const skillListData = getSkillDataGridUtils(mySkills?.data);
      setSkillData((prev) => ({
        ...prev,
        rows: skillListData,
        isLoading: false,
      }));
    } catch (error) {
      setSkillData({ ...skillData, isLoading: false });
      setOpenNotification(true);
      setNotificationMessage(
        error.response.data.error || error.response.data.message
      );
    }
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12}>
          {isDeveloper && (
            <Typography variant="h3" component="h1">
              My Skills
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item xs={6} sm={2.5}>
              <FormDropDown
                name="category"
                label="Category"
                control={control}
                errors={errors?.category}
                helperText={errors?.category?.message}
                options={categoryOptionsList}
                register={register("category", {
                  onChange: (e) => {
                    setCategoryName(e.target.value);
                  },
                })}
              />
            </Grid>
            <Grid item xs={6} sm={2.5}>
              <FormDropDown
                label="Technology"
                name="technology"
                control={control}
                errors={errors?.technology}
                helperText={errors?.technology?.message}
                options={technologyOptionsList}
                register={register("technology", {
                  onChange: (e) => {
                    setTechnologyValue(e.target.value);
                    resetField("frameWork");
                  },
                })}
              />
            </Grid>
            <Grid item xs={6} sm={2.5}>
              <FormDropDown
                label="Framework"
                name="frameWork"
                control={control}
                errors={errors?.frameWork}
                helperText={errors?.frameWork?.message}
                options={frameworkOptionsList}
                register={register("frameWork", {
                  onChange: (e) => {
                    setFrameWorkPayload(e.target.value);
                  },
                })}
              />
            </Grid>
            <Grid item xs={6} sm={2}>
              {isFilterSelected && (
                <ClearFilterButton clearSelection={clearSelection} />
              )}
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <AddMySkillButton>
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleNewSkill}
                >
                  Add My Skills
                </Button>
              </AddMySkillButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DataGridComponent
        autoHeight
        columnsData={columnsData}
        rowData={skillData.rows}
        pageSizeValue={skillData.limit}
        editable={true}
      />
      <Box display="flex" justifyContent="flex-end" marginRight="15px">
        {skillData.rows.filter((each) => each.status === "draft").length >
          0 && (
          <StyledContainerGrid item>
            <Button variant="contained" onClick={handleUpdate}>
              Update
            </Button>
          </StyledContainerGrid>
        )}
      </Box>
      <MySkillDialog
        onClose={handleNewSkillCloseDialog}
        onOpen={mySkillOpen}
        fetchMySkills={fetchMySkills}
      />
      <NotificationPopup />
      <AlertSnackbar />
    </>
  );
};

export default MySkill;
