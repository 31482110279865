import { DialogActions } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

export const EmployeePortalDialogHeading = styled(DialogTitle)``;

export const EmployeePortalDialogHeadingGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.palette.background.default};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
`;

export const EmployeePortalDialogBox = styled(Dialog)`
  .MuiDialog-paper {
    width: ${(props) => `${props.customwidth}`};
    max-height: fit-content;
    max-width: unset;
  }
`;
export const EmployeePortalDialogLabelGrid = styled(Grid)`
  margin-left: ${(props) => `${props.theme.palette.spacing[0] * 5}px`};
`;

export const EmployeePortalDialogIcon = styled(CloseIcon)`
  position: absolute;
  right: ${(props) => `${props.theme.palette.spacing[1]}px`};
  top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-right: ${(props) => `${props.theme.palette.spacing[0] * 7}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 7}px`};
  cursor: pointer;
`;

export const EmployeePortalDialogSaveButton = styled(Button)`
  width: 85px;
`;

export const EmployeePortalActionDailog = styled(DialogActions)`
  justifycontent: end;
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * -3}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const EmployeePortalOtpDailog = styled(DialogActions)`
  justify-content: end;
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const EmployeePortalinvoiceDailog = styled(EmployeePortalActionDailog)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * -1}px`};
`;

export const CreateButtonStyling = styled(Button)`
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 2}px ${
      props.theme.palette.spacing[1] * 1
    }px ${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 0
    }px`};
  height: 40px;
  width: 80px;
`;

export const EmployeePortalDialogTextBoxGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[0] * 5
    }px ${props.theme.palette.spacing[0] * 0}px`};
`;

export const EmployeePortalDialogCard = styled(Card)`
  margin: ${(props) => `${props.theme.palette.spacing[1] * 3}px`};
  box-shadow: 0 1px 22px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
`;
