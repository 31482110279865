import { formatToIST } from "../../../Common/CommonUtils";
export const formatAttendanceData = (attendanceData) => {
  if (!Array.isArray(attendanceData) || attendanceData.length === 0) {
    return [];
  }
  return attendanceData.map((attendance) => {
    const checkInTime = attendance.checkInTime
      ? new Date(attendance.checkInTime)
      : null;
    const checkOutTime = attendance.checkOutTime
      ? new Date(attendance.checkOutTime)
      : null;
    const workedHours = calculateWorkingHours(checkInTime, checkOutTime);

    return {
      ...attendance,
      id: attendance.attendanceId,
      date: attendance.date,
      checkIn: checkInTime ? formatToIST(attendance.checkInTime, true) : "",
      checkOut: checkOutTime ? formatToIST(attendance.checkOutTime, true) : "",
      workingHours: workedHours,
      dayStatus: checkInTime
        ? capitalizeFirstLetter(attendance.dayStatus)
        : "Absent",
      status: attendance.attendanceRequestStatus,
      reason: attendance.reason,
      remarks: attendance.remarks,
    };
  });
};
const calculateWorkingHours = (checkInTime, checkOutTime) => {
  if (!checkInTime || !checkOutTime) return "0h:0m:0s";

  const diffInMilliseconds = checkOutTime - checkInTime;
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

  return `${hours}h:${minutes}m:${seconds}s`;
};

const capitalizeFirstLetter = (string) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
