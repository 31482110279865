import { createSlice } from '@reduxjs/toolkit';
import initialState from './EmployeeInitialState';
import { _setIsViewDetailsPage } from './EmployeeAction';

export const EmployeeDetailsSlice = createSlice({
  name: 'EmployeeDetails',
  initialState,
  reducers: {
    setIsViewDetailsPage: _setIsViewDetailsPage,
    resetEmployeeDetails: () => initialState,
  },
});
export const { resetSideNav, setIsViewDetailsPage } =
  EmployeeDetailsSlice.actions;
export default EmployeeDetailsSlice.reducer;
