import { createSlice } from "@reduxjs/toolkit";
import { _setIsMySkillsForm, _setMySkills } from "./mySkillsFormAction";
import initialState from "./mySkillsFormInitialState";

export const mySkillsFormSlice = createSlice({
  name: "mySkillsForm",
  initialState: initialState,
  reducers: {
    setIsMySkillsForm: _setIsMySkillsForm,
    setMySkills: _setMySkills,
    resetMySkillForm: () => initialState,
  },
});
export const { setIsMySkillsForm, setMySkills, resetMySkillForm } =
mySkillsFormSlice.actions;

export default mySkillsFormSlice.reducer;
