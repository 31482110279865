import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeePortalinvoiceDailog } from '../../../../../Common/EmployeePortalDialog.styled';
import { FormTextField } from '../../../../../Common/FormTextField';
import { GridForm } from './PerformanceDetails.styled';
import { usePermissionCustomHook } from '../../../../../Common/CommonUtils';

const schema = yup.object().shape({
  selfRating: yup
    .number()
    .typeError('Self Rating must be a number')
    .required('Self Rating is required')
    .min(1, 'Self Rating must be at least 1')
    .max(5, 'Self Rating cannot be more than 5')
    .test(
      'is-decimal',
      'Self Rating must have one decimal (0.1 to 0.9)',
      (value) => {
        if (isNaN(value)) return false;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || (decimalPart && decimalPart.length <= 1);
      }
    ),
  managerRating: yup
    .number()
    .typeError('Manager Rating must be a number')
    .required('Manager Rating is required')
    .min(1, 'Manager Rating must be at least 1')
    .max(5, 'Manager Rating cannot be more than 5')
    .test(
      'is-decimal',
      'Manager Rating must have one decimal (0.1 to 0.9)',
      (value) => {
        if (isNaN(value)) return false;
        const decimalPart = value.toString().split('.')[1];
        return !decimalPart || (decimalPart && decimalPart.length <= 1);
      }
    ),
  suggestionByManager: yup
    .string()
    .matches(/^[A-Za-z\s]+$/, 'Suggestion By Manager must contain only letters')
    .required('Suggestion By Manager is required'),
});

const PerformanceEditForm = (props) => {
  const { onSubmit, onCancel, snackbarOpen, onCloseSnackbar } = props;

  const dispatch = useDispatch();

  const performanceListForm = useSelector(
    (state) => state?.performanceListEditForm
  );

  const isManager = usePermissionCustomHook('Manager');
  const isDeveloper = usePermissionCustomHook('Developer');

  const { selfRating, managerRating, suggestionByManager, parameters } =
    performanceListForm;

  const defaultValues = {
    parameters: parameters,
    selfRating: selfRating,
    managerRating: managerRating,
    suggestionByManager: suggestionByManager,
  };

  const { control, register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors, dirtyFields } = formState;
  const isOtpFieldDirty = dirtyFields.email;

  const parameterOptions = [
    { value: 'Quality of Work', label: 'Quality of Work' },
    { value: 'Communication', label: 'Communication' },
    { value: 'Group Work', label: 'Group Work' },
  ];

  return (
    <>
      <GridForm container data-testid="performanceFormContainer">
        <FormTextField
          label="Parameters"
          name="parameters"
          type="text"
          control={control}
          errors={!!errors?.parameters}
          helperText={errors?.parameters?.message}
          disabled
        />
        <FormTextField
          label="Self Rating"
          name="selfRating"
          type="text"
          control={control}
          errors={!!errors?.selfRating}
          helperText={errors?.selfRating?.message}
          readOnly={!isDeveloper}
        />
        <FormTextField
          label=" Manager Rating"
          name="managerRating"
          type="text"
          control={control}
          errors={!!errors?.managerRating}
          helperText={errors?.managerRating?.message}
          readOnly={!isManager}
        />
        <FormTextField
          label="Suggestion By Manager"
          name="suggestionByManager"
          type="text"
          control={control}
          errors={!!errors?.suggestionByManager}
          helperText={errors?.suggestionByManager?.message}
          readOnly={!isManager}
        />
      </GridForm>

      <EmployeePortalinvoiceDailog>
        <Button variant="contained" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!formState.isValid}
        >
          Save
        </Button>
      </EmployeePortalinvoiceDailog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={onCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={onCloseSnackbar} severity="success">
          Performance Details Edited Successfully
        </Alert>
      </Snackbar>
    </>
  );
};
export default PerformanceEditForm;
