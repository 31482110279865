import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteIcon from '@mui/icons-material/Delete';

export const AddButtonGrid = styled(Grid)`
  justify-content: end;
  padding: ${(props) => `${props.theme.palette.spacing[0] * 1}px`};
  padding-right: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;
export const Accordionstyling = styled(Accordion)`
  box-shadow: 4px 1px 10px 1px rgba(0, 0, 0, 0.2);
  margin-top: ${(props) =>
    `${props.theme.palette.spacing[1] * 3}px`} !important;
  margin-right: ${(props) =>
    `${props.theme.palette.spacing[1] * 3}px`} !important;
`;

export const UploadDocumentLink = styled(Link)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const StyledLabel = styled('label')`
  display: inline-block;
`;

export const StyledInput = styled('input')`
  display: none;
`;

export const UploadButton = styled(Button)`
  && {
    text-decoration: none;
    border: 2px dashed gray;
    border-radius: 4px;
    padding: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
    width: 370px;
    margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
  }
`;

export const FileContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const UploadTypography = styled(Typography)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  cursor: pointer;
`;

export const ClosestyledIcon = styled(CloseIcon)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;

export const RoundedIconEdit = styled(EditRoundedIcon)`
  cursor: pointer;
  width: 1.3rem;
`;
export const IconDelete = styled(DeleteIcon)`
  cursor: pointer;
  width: 1.3rem;
`;
