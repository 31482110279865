export const getInvoiceListData = (invoiceData) => {
    if (!invoiceData || invoiceData.length === 0) {
      return [];
    }
    return invoiceData.map((item, index) => ({
      ...item,
      id: index,
      invoiceId: item.invoiceId,
      invoiceNumber: item.invoiceNumber,
      customerName: item.customerName,
      invoiceDate: item.invoiceDate,
      paymentDueDate: item.paymentDueDate,
      totalAmountPaid: item.totalAmountPaid,
      lastPaymentDate: item.lastPaymentDate,
      amountDue: item.amountDue,
      status: item.status,
    }));
  };
  