import React, { useState } from 'react';
import EmployeePortalDialog from '../../../../../Common/EmployeePortalDialog';
import EducationForm from './AddEducationForm';
import { useSelector } from 'react-redux';

const AddEducationDetails = (props) => {
  const { snackbarOpen, setSnackbarOpen, onClose, onOpen } = props;
  const handleCloseDialog = () => {
    onClose();
  };

  const educationForm = useSelector((state) => state?.educationForm);

  const { course, degree, yearOfPassing, universityinstutute, id } =
    educationForm;

  const handleEducationDetailsSubmit = async () => {
    const payload = {
      course,
      degree,
      yearOfPassing,
      universityinstutute,
      id,
    };
    setSnackbarOpen(true);
    setTimeout(() => {
      onClose();
    }, [1000]);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <EmployeePortalDialog
        open={onOpen}
        onClose={onClose}
        title={'Education '}
        customWidth={'450px'}
      >
        <EducationForm
          onSubmit={handleEducationDetailsSubmit}
          onCancel={handleCloseDialog}
          snackbarOpen={snackbarOpen}
          onCloseSnackbar={handleCloseSnackbar}
        />
      </EmployeePortalDialog>
    </>
  );
};

export default AddEducationDetails;
