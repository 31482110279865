export const _setApplyLeaveForm = (state, action) => action.payload;

export const _setIsNewApplyLeaveForm = (state, action) => {
  state.isNewApplyLeaveForm = action.payload;
};

export const _setIsMyLeave = (state, action) => {
  state.isMyLeave = action.payload;
};

export const _setIsMyTeamLeave = (state, action) => {
  state.isMyTeamLeave = action.payload;
};
