import { Grid, Typography } from '@mui/material';
import React from 'react';
import { HynivaLogoStyle } from '../../Login/Login.styled';
import HynivaLogo from '../../../Assets/Images/HynivaLogo.png';
import { hynivaAddressBangalore } from '../../../Common/CommonUtils';
import {
  GridCompanyDetails,
  GridEnd,
  GridInvoiceSection,
  InvoiceTypography,
} from './CreateInvoice.Styled';

const CreateInvoiceCardHeaderSection = () => {
  return (
    <GridInvoiceSection
      container
      spacing={2}
      data-testId="invoice-address-section"
    >
      <Grid item xs={12} sm={6} data-testId="invoice-logo">
        <HynivaLogoStyle src={HynivaLogo} />
      </Grid>
      <GridEnd item xs={12} sm={6} data-testId="invoice-address">
        <InvoiceTypography variant="h3">INVOICE</InvoiceTypography>
        <GridCompanyDetails container data-testId="invoice-address-details">
          <Typography>Hyniva Consulting Services Pvt Ltd</Typography>
          <Typography>{hynivaAddressBangalore()}</Typography>
        </GridCompanyDetails>
      </GridEnd>
    </GridInvoiceSection>
  );
};

export default CreateInvoiceCardHeaderSection;
