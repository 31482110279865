import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";
import {
  IconContainer,
  StyledButtonBase,
  TitleContainer,
} from "./SideNavbar.Styled";
import List from "@mui/material/List";
import { setIsViewDetailsPage } from "../Employees/store/EmployeeSlice";
import { useDispatch } from "react-redux";

export const SideNavbarItem = (props) => {
  const {
    active,
    disabled,
    icon,
    path,
    title,
    handleActivePath,
    setIsMobileNavbar,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSidebar = () => {
    navigate(path);
    dispatch(setIsViewDetailsPage(false));
    handleActivePath(path);
    setIsMobileNavbar(false);
  };

  return (
    <List>
      <StyledButtonBase
        sx={{
          ...(active && {
            // backgroundColor: 'rgba(255, 255, 255, 0.04)',
            backgroundColor: "#345196",
          }),
        }}
        onClick={handleSidebar}
      >
        {icon && (
          <IconContainer
            component="span"
            sx={{
              color: "neutral.400",
              // color: '#345196',
              ...(active && {
                color: "common.white",
              }),
            }}
          >
            {icon}
          </IconContainer>
        )}
        <TitleContainer
          component="span"
          sx={{
            color: "neutral.400",
            fontFamily: (theme) => theme.typography.fontFamily,
            ...(active && {
              color: "common.white",
            }),
            ...(disabled && {
              color: "neutral.500",
            }),
          }}
        >
          {title}
        </TitleContainer>
      </StyledButtonBase>
    </List>
  );
};

SideNavbarItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};
