import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  DoughnutChartContainer,
  DoughnutChartContent,
  DoughnutChartLeaveType,
  DoughnutContainer,
  DoughnutWrapper,
  LeaveTotal,
  Title,
} from "./Dashboard.styled";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const { label, value, total, bgColor , balanceLeave } = props;

  const generateChartData = (label, value, total, bgColor) => ({
    labels: [label, "Remaining"],
    datasets: [
      {
        data: [value, total - value],
        backgroundColor: [bgColor, "rgba(201, 203, 207, 0.3)"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(201, 203, 207, 1)"],
        borderWidth: 1,
        cutout: "85%",
      },
    ],
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <DoughnutChartContainer>
      <DoughnutWrapper>
        <Doughnut
          data={generateChartData(label, value, total, bgColor)}
          options={options}
        />
        <DoughnutContainer>
          <LeaveTotal variant="h2">{value}</LeaveTotal>
        </DoughnutContainer>
      </DoughnutWrapper>
      <DoughnutChartContent>
          <Title variant="h5">{label}</Title>
          <DoughnutChartLeaveType>Accumulated : {total}</DoughnutChartLeaveType>
          <DoughnutChartLeaveType>Availed : {value}</DoughnutChartLeaveType>
          <DoughnutChartLeaveType>Balance : {balanceLeave}</DoughnutChartLeaveType>
      </DoughnutChartContent>
    </DoughnutChartContainer>
  );
};

export default DoughnutChart;
