import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RoundedIconEdit } from '../EducationAndCertification/EducationAndCertification.Styled';
import { styled } from '@mui/material/styles';

export const PerformanceDetailsContentGrid = styled(Grid)`
  align-items: center;
`;
export const AddPerformanceDetailsGrid = styled(Grid)`
  padding: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * -4}px`};
  justify-content: end;
`;

export const PerformanceDetailsInnerContentGrid = styled(Grid)`
  padding: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 1
    }px`};
  flex-direction: column;
  width: 95%;
`;

export const QuaterlyHeading = styled(Typography)`
  margin-bottom: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
`;
export const PerformanceDetailsLabel = styled(Typography)`
  width: 220px;
  line-height: 40px;
`;

export const PerformanceRoundIconEdit = styled(RoundedIconEdit)`
  margin-left: ${(props) => `${props.theme.palette.spacing[1] * 2}px`};
`;

export const PerformanceDetailsDataGrid = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
`;

export const PerformanceDetailsValue = styled(Typography)`
  padding-right: ${(props) => `${props.theme.palette.spacing[1] * 1}px`};
  margin-top: ${(props) => `${props.theme.palette.spacing[0] * 1.5}px`};
`;
export const PerformanceDetailsButton = styled(Grid)`
  margin-top: ${(props) => `${props.theme.palette.spacing[1] * 4}px`};
  display: flex;
  justify-content: flex-end;
`;

export const GridForm = styled(Grid)`
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 2
    }px`};
  width: auto;
`;
