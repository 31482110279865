import { useState } from "react";
import PromptMessage from "./PromptMessage"; // Import the PromptMessage component

export const usePrompt = () => {
  const [openPrompt, setOpenPrompt] = useState(false);
  const [promptData, setPromptData] = useState({
    title: "",
    message: "",
    confirmLabel: "Yes",
    cancelLabel: "No",
    onConfirm: () => {},
    onClose: () => {},
  });

  const showPrompt = (title, message, onConfirm, onClose, confirmLabel = "Yes", cancelLabel = "No") => {
    setPromptData({
      title,
      message,
      confirmLabel,
      cancelLabel,
      onConfirm: () => {
        onConfirm();
        setOpenPrompt(false);
      },
      onClose: () => {
        onClose();
        setOpenPrompt(false);
      },
    });
    setOpenPrompt(true);
  };

  const PromptPopup = () => (
    <PromptMessage
      open={openPrompt}
      title={promptData.title}
      message={promptData.message}
      confirmLabel={promptData.confirmLabel}
      cancelLabel={promptData.cancelLabel}
      onConfirm={promptData.onConfirm}
      onClose={promptData.onClose}
    />
  );

  return {
    showPrompt,
    PromptPopup,
  };
};
