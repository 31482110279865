export const getEmployeeSkillsData = (skillsData) => {
    if (!skillsData || skillsData.length === 0) {
      return [];
    }
    return skillsData.map((item, index) => ({
      ...item,
      id: index,
      category: item.category,
      comments: item.comments,
      createdBy: item.createdBy,
      createdDateTime: item.createdDateTime,
      currentLevel: item.currentLevel,
      employeeId: item.employeeId,
      frameWork: item.frameWork,
      manager: item.manager,
      managerName: item.managerName,
      status: item.status,
      targetDate: item.targetDate,
      targetLevel: item.targetLevel,
      technology: item.technology,
      updatedBy: item.updatedBy,
      updatedDateTime: item.updatedDateTime,
      approvedOn: item.approvedOn,
      remarks: item.remarks,
      employeeName: item.employeeName,
    }));
  };