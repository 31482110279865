import axios from "axios";
import { BASE_URL } from "../../../Common/Constant";
import { api } from "../../../Common/AxoisConfig";

export const LoginApi = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}auth/login`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postLogout = async (authorization, user_id) => {
  await axios.post(
    `${BASE_URL}auth/logout?user_id=${user_id}`,
    {},
    authorization
  );
};

export const getCategory = async () => {
  const category = await api("NODE_API_URL").get(
    `metadata/type?type=Category&status=active`
  );
  return category?.data?.data;
};

export const getSkillLevel = async () => {
  const currentLevel = await api("NODE_API_URL").get(
    `metadata/type?type=Level&status=active`
  );
  return currentLevel?.data?.data;
};
export const getTechnology = async () => {
  const technology = await api("NODE_API_URL").get(
    `metadata/type?type=Core%20Technology&status=active`
  );
  return technology?.data?.data;
};

export const getManagerEmployees = async (employeeId) => {
  const managerEmployees = await api("NODE_API_URL").get(
    `employee/assign/manager?userId=${employeeId}`
  );
  return managerEmployees?.data?.data;
};

export const postChangePasswordApi = async (user_id, payload) => {
  await api("FAST_API_URL").post(`password/change?user_id=${user_id}`, payload);
};
