import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import EditControls from "../../../../../Common/EditControl";
import {
  EmployeeInformationCard,
  EmployeeInformationCardEditIconGrid,
  EmployeeInformationCardGrid,
} from "./EmployeeInformationStyled";
import { ViewDtailsCard } from "../../ViewDetailsPage.styled";
import AssetsInformationForm from "./AssetsInformationForm";
import {
  useAuthentication,
  useNotification,
  usePermissionCustomHook,
} from "../../../../../Common/CommonUtils";
import {
  getAsset,
  getEmployee,
  postAssetInfo,
  putAssetInfo,
} from "../../../Services/service";
import { setAssetInfoForm } from "../../../store/assetsInformationSlice/assetInfoSlice";

const schema = yup.object().shape({
  assetType: yup.string().required("Assets Type is required").nullable(),
  serialNumber: yup
    .string()
    .matches(
      /^[0-9]+$/,
      "Serial Number is Required and it must contain only numbers"
    )
    .nullable(),
});

const AssetsInformation = (props) => {
  const dispatch = useDispatch();
  const { setOpenNotification, setNotificationMessage, NotificationPopup } =
    useNotification();

  const employeeUserId = useSelector((state) => state.user?.data?.employeeId);

  const employeeId = useSelector((state) => state?.employeeInfo?.employeeId);

  const isAssetsInfo = useSelector((state) => state?.assetInfoForm);
  const assetsInfoForm = useSelector((state) => state?.assetInfoForm?.[0]);

  const isNewAssetInfo = isAssetsInfo?.length === 0 ? true : false;

  const defaultValue = {
    assetId: assetsInfoForm?.assetId,
    assignTo: assetsInfoForm?.assignTo,
    assetType: assetsInfoForm?.assetType,
    serialNumber: assetsInfoForm?.serialNumber,
    status: assetsInfoForm?.status,
  };

  const useFunction = useForm({
    mode: "onChange",
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control, register, formState } = useFunction;
  const { isValid, dirtyFields, errors } = formState;

  const isErrorField = Object.keys(errors).length === 0 ? false : true;

  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState(defaultValue);
  const [formChanged, setFormChanged] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState({});

  const isHR = usePermissionCustomHook("HR");

  useEffect(() => {
    const isFormDataChanged = !Object.entries(initialFormValues).every(
      ([key, value]) => formData[key] === value
    );
    setFormChanged(isFormDataChanged);
  }, [formData, initialFormValues]);

  useEffect(() => {
    setInitialFormValues(defaultValue);
  }, []);

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelClick = () => {
    reset(defaultValue);
    setFormChanged(false);
    setEditable(false);
  };

  const handleSaveClick = () => {
    setEditable(false);
    setInitialFormValues(formData);
  };

  const onsubmit = async (data) => {
    setEditable(false);
    setInitialFormValues(formData);
    const { assetType, serialNumber, status } = data;

    const payload = {
      ...(isNewAssetInfo
        ? { userId: employeeUserId, assignTo: employeeId }
        : {}),
      assetType: assetType,
      serialNumber: serialNumber,
      status: status,
    };

    try {
      if (isNewAssetInfo) {
        await postAssetInfo(payload);
      } else {
        await putAssetInfo(
          employeeUserId,
          assetsInfoForm?.assetId,
          payload
        );
      }
      const response = await getAsset(
        employeeUserId,
        employeeId
      );
      dispatch(setAssetInfoForm(response?.data));
    } catch (error) {
      setOpenNotification(true);
      setNotificationMessage(error?.response?.data?.message);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true);
  };

  return (
    <ViewDtailsCard sx={{ padding: 2 }}>
      <EmployeeInformationCardGrid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="h6">Assets Information</Typography>
        </Grid>
        <EmployeeInformationCardEditIconGrid item xs={6}>
          {isHR && (
            <EditControls
              editable={editable}
              onCancelClick={handleCancelClick}
              onSaveClick={handleSubmit(onsubmit)}
              onEditClick={handleEditClick}
              saveDisabled={!formChanged || isErrorField}
            />
          )}
        </EmployeeInformationCardEditIconGrid>
        <AssetsInformationForm
          control={control}
          errors={errors}
          defaultValue={defaultValue}
          register={register}
          handleChange={handleChange}
          editable={editable}
        />
      </EmployeeInformationCardGrid>
    </ViewDtailsCard>
  );
};

export default AssetsInformation;
