import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/system';
import { createTheme } from './theme';
import { Box } from '@mui/material';
import Login from './Components/Login/Login';
import { Provider } from 'react-redux';
import { store, persistor } from './Redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import EmployeePortalConfig from './Routes/EmployeePortalConfig';

function App() {
  const theme = createTheme();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <EmployeePortalConfig />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
