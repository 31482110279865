import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import {
  EmployeeCodeStyling,
  EmployeeNameMainGrid,
  EmployeeNameStyling,
} from './ViewDetailsPage.styled';

const EmployeeName = () => {
  const EmployeeDetails = useSelector(
    (state) => state?.EmployeeDetails?.employeeDetails
  );

  const employeeData = useSelector(
    (state) => state?.personalInformationForm
  );

  const { employeeId, firstName, lastName } = employeeData || {};

  const userId = employeeId;

  const name = `${firstName} ${lastName}`;

  return (
    <EmployeeNameMainGrid container display="flex" xs={12}>
      <Grid item xs={6}>
        <EmployeeNameStyling variant="h4">{name}</EmployeeNameStyling>
        <EmployeeCodeStyling variant="h6">
          Employee_Id: {userId}
        </EmployeeCodeStyling>
      </Grid>
    </EmployeeNameMainGrid>
  );
};

export default EmployeeName;
