import { createSlice } from '@reduxjs/toolkit';
import initialState from './attendanceIntialState';
import { _setCheckInOut } from './attendanceAction';

export const  attendanceSlice = createSlice({
  name: 'ep/attendance',
  initialState,
  reducers: {
    setCheckInOut : _setCheckInOut ,
    resetSetCheckInOut: () => initialState,
  },
});
export const { setCheckInOut} =
attendanceSlice.actions;
export default attendanceSlice.reducer;
